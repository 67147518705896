@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.agreementParent {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-direction: row;
    /* margin-top: 10%; */
    /* background-color: red; */
    height: 100vh;
}

.sideImageDiv {
    height: 100vh;
    width: 50%;
    background-image: url("../../../../assets/agreement.jpg");
    background-repeat: no-repeat;
    background-position: center center center center;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;

}

.holderDiv {
    width: 50%;
}

.formDiv {
    width: 100%;
    height: 100vh;
    background-color: #E0E3E2;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* overflow-y: hidden; */
}

.headpartFrom {
    /* background-color: rebeccapurple; */
    /* */
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    text-align: justify;
    padding: 15px 0 15px 90px;
    font-size: 2em;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;

}

.newheadpartform {
    margin-top: 10%;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    text-align: justify;
    padding: 15px 0 15px 90px;
    font-size: 2em;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    /* background-color: rebeccapurple; */
}

.agreePen {}

.penIcon {
    color: #2E2D4D;

}

.agreeDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    /* background-color: #999; */
}

.agreementCode {
    width: 50%;
    margin-right: 4px;
    height: 25px;
    border-radius: 5px;
    padding: 10px;
}

.agreementCode::placeholder {
    color: #999;

}

.error {
    /* background-color: aqua; */
    width: 83%;
    font-family: 'Poppins', sans-serif;
    font-size: 0.7em;
    text-align: justify;
    color: red;

}

.agreementCode2 {
    width: 50%;
    margin-right: 4px;
    height: 25px;
    border-radius: 5px;
    padding: 10px;
    border: 2px red solid;
    animation: vibrating 0.5s ease-in-out 5;
}

@keyframes vibrating {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }

    40% {
        transform: translateX(10px);
    }

    60% {
        transform: translateX(5px);
    }
}

.agreementButton {
    width: 30%;
    height: 48px;
    background-color: #2E2D4D;
    color: white;
    font-size: 1em;
    border-radius: 5px;
    /* padding: 5px; */
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.unlock {
    margin-right: 10px;
}

.mainAgreeDiv {
    /* background-color: #2E2D4D; */
    padding: 5px 0 10px 0;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.textFiles {
    background-color: aquamarine;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    color: #584646;
    padding: 5px 20px 5px 20px;
    width: 80%;
    /* height: 100%; */
    /* overflow-y: scroll; */
    margin-top: 5px;
    /* position: absolute */
}

ul li {
    margin-bottom: 4px;
}

.tick {
    font-size: 1.3em;
}

.textFiles h5 {
    font-size: 1.35em;
    width: 65%;
    font-weight: 600;
    color: black;
    margin-bottom: 0;
    margin-top: 0;
}

.downloadButton {
    /* background-color: #2E2D4D; */
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 20px 0 20px 0; */
}

.downloadButton button {
    padding: 12px 15px 12px 15px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #2E2D4D;
    color: white;
    font-size: 1em;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 35%;
    /* margin-right: 15px; */
}

.uploadFile {
    padding: 12px 15px 12px 15px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    /* background-color: #2E2D4D; */
    /* color: white; */
    font-size: 1em;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
}

.submitButton {
    margin-top: 2%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.submitButton button {
    padding: 8px 15px 8px 15px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #2E2D4D;
    color: white;
    font-size: 1em;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 25%;
}

@media screen and (max-width: 1600px) {
    .newheadpartform{
        margin-top: 15%;
    }
}

@media screen and (max-width: 1050px) {
    .newheadpartform, .headpartFrom {
        margin-top: 15%;
        font-size: 1.5em;
    }
}

@media screen and (max-width: 1000px) {

    .sideImageDiv {
       display: none;
    }
    

    .holderDiv{
        width: 100%;
    }

.headpartFrom, .newheadpartform{
    padding: 15px 0 15px 10px;
    /* background-color: #2E2D4D; */
}

.newheadpartform{
    margin-top: 23%;
}

.agreementCode, .agreementCode2 {
    width: 57%; 
}

.agreementButton {
    width: 35%;
    font-size: 0.7em;
    border-radius: 5px;
    /* padding: 5px; */
}
}
