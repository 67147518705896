@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentBoodkingTips {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    /* background-color: bisque; */
    /* height: 70vh; */
}

.bookingTipsHero {
    margin-top: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 85%;
    height: 100%;
}

.bookingtipstext {
    background-color: rgb(250, 250, 250);
    width: 50%;
    height: 80%;
    padding: 30px 30px 30px 25px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
    border-top-left-radius: 150px;
    border-bottom-right-radius: 150px;

}

.bookingtipsimage {
    width: 55%;
    object-fit: contain;
}

.bookingtipsimage img {
    width: 80%;

}

html {
    scroll-behavior: smooth;
}

.bookingtipstext p {
    font-size: 4em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    line-height: 90px;
    margin-bottom: 0;
}

.bookingtipstext ol {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    background-color: aliceblue;
    color: black;
    padding: 5px 50px;
    border-bottom-right-radius: 150px;
    border-top-right-radius: 150px;
}

.bookingtipstext ol li {
    padding: 3px;
    cursor: pointer;
}

.bookingtipstext ol li a {
    text-decoration: none;
    color: black;
}

.bookingtipstext p .firstLine {
    font-size: 1.3em;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

.bookingtipstext p .secondLine {
    font-size: 1.25em;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
}

.bookingtipstext p .thirdLine {
    font-size: 0.9em;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    /* color: #10A0FF; */
}

.parentmainbookingtips {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
}

.bookingtipsHolder {
    width: 100%;
}

.firstBookingTip {
    display: flex;

    align-items: center;
    justify-content: center;

    margin-bottom: 1.3%;
}

.firstBookingTiptext {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    padding: 40px;
    width: 40%;
}

.firstBookingTiptext h2 {
    font-size: 4em;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Poppins', sans-serif;
    background-color: #325482;
    color: white;
    padding: 10px;
}

.firstBookingTiptext .checkBuzz {
    font-size: 3.4em;
    width: 100%;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Poppins', sans-serif;
    background-color: #325482;
    color: white;
    padding: 10px;
}

.firstBookingTiptext p {
    font-family: 'Poppins', sans-serif;
    text-align: justify;

}

.considerLoc {
    font-size: 0.3em;
    font-weight: 400;
    margin-left: 2%;
}

.considerItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    padding-left: 20px;
    list-style: none;
}

/* .hover-card ul {
    list-style: none;

    font-weight: 600;
    padding-left: 0;
    
} */

.considerItem li::before {
    content: '✓';
    /* Checkmark symbol */
    font-size: 18px;
    /* Adjust size if needed */
    color: #325482;
    /* Set color for the checkmark */
    font-weight: 900;
    margin-right: 10px;
    /* Add some space between the checkmark and the text */
}

@media screen and (max-width: 1600px) {
    .bookingTipsHero {
        /* margin-top: 8%; */
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 85%;
        height: 100%;
    }

    .bookingtipstext {
        font-size: 0.8em;
    }

}

@media screen and (max-width: 850px) {
    .parentBoodkingTips {

        width: 100vw;
    }

    .bookingTipsHero {
        flex-direction: column;
        width: 100%;
        padding-top: 15%;
    }

    .bookingtipstext {
        width: 85%;
        /* line-height: 100px; */
    }

    .bookingtipsimage {
        width: 100%;
    }

    .bookingtipstext p {
        align-items: center;
        font-size: 2em;
        line-height: 50px;
    }

    .firstBookingTip {
        flex-direction: column;
        /* background-color: bisque; */
       
    }

    .firstBookingTiptext {
        width: 90%;
    }

   .firstBookingTipimage{
    width: 100%;
   }

   .firstBookingTipimage img{
    width: 100%;
   }

   .firstBookingTiptext h2 {
       font-size: 3em;
       padding: 0px;
   }
   .firstBookingTiptext .checkBuzz {
    font-size: 2.5em;
        padding: 0px;
   }
}