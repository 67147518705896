@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
.parent_room_button{
    

    /* margin-left: 23%; */
    /* margin-top: 10%; */
    height: 100%;
    width: 100% ;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* background-color: #e5eaf3; */
}

.btn_div .select_button{
    padding: 5px;
    width: 11em;
    outline: none;
    border: 1px solid #2E2D4D;
    border-radius: 7px;
    color: #2E2D4D;
    background-color: RGB(255,255,255);
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
    /* margin-left: 50%; */
}

.btn_div2 {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 5px;
    /* background-color: #2E2D4D; */
    
}

.removequantityDIv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    /* background-color: aliceblue; */
}

.seconddivremovdiv{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.includeBreakdiv{
    /* background-color: antiquewhite; */
    width: 100%;
}

.btn_div .select_button2{
    padding: 5px;
    width: 11em;
    outline: none;
    /* border: 1px solid #2E2D4D; */
    border-radius: 7px;
    /* color: #2E2D4D; */
    color: #fff;
    background-color: grey;
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
    /* margin-left: 50%; */
    cursor: not-allowed;
}

/* .checkBoxes{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.5em;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    margin-left: 10%;
    background-color: gray;

}
.selectBreak{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: red;
    width: 100%;
    background-color: aqua;
    font-size: 0.9em;

} */
/* .mainBox{
    background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

.hideBtn{
    display: none;
}
.disabled{
    background: gray;
}


.btn_div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background-color: yellowgreen; */
    
   
}

.removebtn{
    width: auto;
    border: none;
    
    width: 40%;
    outline: none;
    
    border-radius: 7px;
    color: #ebeef3;
    
    background-color: #2E2D4D;
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
}

.delbtn{
    font-size: 2em;
}
.removebtn:hover{
    cursor: pointer;
    background-color: RGB(255,255,255);
    color: #3464B1;
    
}

.parent_room_button .select_button:hover{
    cursor: pointer;
    background-color: #2E2D4D;
    color: #e5eaf3;
}

.parent_room_button .inputField{
    width: 60%;
    height: auto;
    border-radius: 7px;
    border: 1px solid #3464B1;
    padding: 5px;  
    margin-left: 0%;     
   
}   
.indicate{
    color: red;
    font-size: 0.7em;
    font-family: sans-serif;
}   


    /* .parent_room_button .select_button{
        width: 50%;
        
    } */
    @media screen and (max-width: 850px){
        .btn_div .select_button{
            margin-left: 30%;
            width: 13em;
    }
    .btn_div2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .btn_div{
        /* justify-content: space-evenly; */
        margin-right: -4em;
        /* background-color: red; */
        width: 100%;
        /* width: 17em; */
    }
    .parent_room_button .inputField{
        width: 100%;
        margin-left: 25%;
    }
    }
    @media screen and (max-width: 750px){
        .checkBoxes{
           
            width: 100%;
        }
        
        .parent_room_button{
 
            margin-left: -10%;
            width: 100%;
        }
        .btn_div .select_button{
            margin-left: 30%;
            width: 13em;
        }
        .btn_div{
            display: flex;
            justify-content: flex-start;
        }
        .removebtn{
            width: 30%;
            padding: 3px;
            font-size: 0.6rem;
    }
    .parent_room_button .inputField{
        width: 80%;
        margin-left: 20%;
    }
 }


@media screen and (max-width: 400px) {
    .btn_div .select_button {
        margin-right: 10em;
        width: 17em;
        /* background-color: red; */
    }

    .removebtn {
        width: 60%;
        padding: 3px;
        font-size: 0.6rem;
    }

    .btn_div2 {
        display: flex;
        flex-direction: row;
    }

    .btn_div {
        /* justify-content: space-evenly; */
        margin-right: -3em;
        /* background-color: red; */
        width: 100%;
        /* width: 11em; */
        display: flex;
        flex-direction: column;
        /* justify-content: flex-start; */
        /* align-items: flex-start; */
    }

    .parent_room_button .inputField {
        width: 80%;
        margin-left: 20%;
    }

    .checkBoxes {
        margin-top: 6%;
        /* margin-left: -2em; */
    }
    
}

@media screen and (max-width: 500px){
    .btn_div .select_button{
        margin-right: 10em;
        width: 17em;
        /* background-color: red; */
    }
    .removebtn{
        width: 60%;
        padding: 3px;
        font-size: 0.6rem;
}

    
    
        .btn_div2{
            display: flex;
            flex-direction: row;
            /* background-color: #2E2D4D; */
        }
        .btn_div{
            /* justify-content: space-evenly; */
            margin-right: -3em;
            /* background-color: red; */
            width: 100%;
            /* width: 11em; */
            display: flex;
            flex-direction: column;
        }
        .parent_room_button .inputField{
            width: 80%;
            margin-left: 20%;
        }
        .checkBoxes{
            margin-top: 6%;
            /* margin-left: -2em; */
        }
    
}

