.currencyButton{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    cursor: pointer;
    /* height: 70%; */
    /* background-color: aliceblue; */
}
.currencyIMG{
    width: 45%;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.currencyIMG img{
    width: 100%;
    height: 100%;
}

.currencyName{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: whitesmoke;
    font-size: 1em;
    font-weight: 600;
}