@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
.contact_form_container{
    display: flex;
    flex-direction: row;
    align-items: center;
justify-content: space-evenly;
background: linear-gradient(90deg, #dae2ec 0%, #ffffff 100%);
width: 70%;


margin-left: 15%;
margin-top: 8%;
margin-bottom: 8%;
box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.contact_tex{
    width: 50%;
    height: 25rem;
    background-image: url("../../assets/customerCare3.png");
    background-repeat: no-repeat;
  background-position: top right;
    background-size: cover;
    position: relative;
    
    
}
.ansQst{
    /* color: white; */
    font-family: sans-serif;
    font-size: 0.7em;
}
.contact_fone{
    /* color: rgb(150, 140, 2); */
    color: #FF7572;
}

.parent_contact{
    background-image: url("../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
    /* background-position: ; */
    
    background-size: cover;
    position: relative;
    /* background-color: rebeccapurple; */
}

.contact__us_head h2{
    font-size: 2.2em;
}
.contact__us_head hr{
    width: 45%;
    margin-left: 32%;

}
.hr_div{
    margin-top: -3%;
 
    width: 30%;
    margin-left: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hr_div span{
    color:#2E2D4D;
    font-size: 2em;
    font-weight: 700;
}
.contact_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1%;
   font-size: 1.2em;
    line-height: 1.7em;
    font-weight: 800;
    font-family: monospace;
    color: #161025;
    background-color: #DDE5EE;
    border-radius: 10px;
    /* background-color: rgb(15, 17, 17); */
    /* opacity: 0.6; */
    width: 80%;
    margin-left: 12%;
}
.contact_text p{
    width: 50%;
    text-align: justify;
}
.fone_Number{
    /* margin-left: -28%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    position: absolute;
    margin-top: 100%;
    padding: 0 5px 0px 5px;
    color: #e9e8fa;
    background-color: #2E2D4D;
    border-radius: 10px;
    opacity: 0.8;
}
.contact_fone{
    font-weight: 700;
}
.fone_Number2{
    background-color: #e9e8fa;
    padding: 10px;
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
    color: #2E2D4D;
 
}
.parent_fone_contact{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
    width: 12rem;
}
.parent_email_contact{
    display: flex;
    align-items: center;
    justify-content:flex-start;
    /* width: 12rem; */
    gap: 5px;
    /* background-color: #440fcc; */
   
    /* margin-left: 9%; */
}
.email_text{
    /* margin-left: 9%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    
}
.fone_con{
    /* margin-left: 30%; */
    background-color: #e9e8fa;
    padding: 10px;
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
    color: #2E2D4D;
}

.contact_form{
    border: 1px solid #fcfcfc;
    background:#fcfcfc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 27%;
    padding: 10px;
    border-radius: 14px;
    
   
}
.contact_form input{
    margin-bottom: 15px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid grey;
    width: 90%;
    padding-left: 10px;
}

.contact_form input::placeholder{
    color: #2E2D4D;
    font-family: 'Times New Roman', Times, serif;
}

.contact_form textarea{
    margin-bottom: 15px;
    padding-left: 10px;
    padding-top: 10px;
    border-radius: 5px;
    border: 1px solid grey;
    width: 90%;
}

.contact_form textarea::placeholder{
    color: #2E2D4D;
    font-family: 'Times New Roman', Times, serif;
}
.contact_form button {
    width: 90%;
    padding: 10px;
    color: white;
    background: #2E2D4D;
    border: none;
    border-radius: 5px;
}

.contact_form button:hover{
    cursor: pointer;
    background: #7471c0;
}

.contact_form .error{
    color: red;
    font-size: 0.7em;
    margin-left: -30%;
    margin-top: -4%;
  }

  @media  screen and (max-width: 1650px){
    .contact_form_container{
        width: 100%;
        margin-left: 0;
        box-shadow: none;
        /* margin-top: 10%; */
    }
  }

@media  screen and (max-width: 1000px){
    .contact_form_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0;
        margin-top: 20%;
      
    }
    .contact_text p{
        width: 100%;
      
    }
    .contact_form{
        width: 50%;
    }
}

@media  screen and (max-width: 750px){
    .contact_form_container{
        background: radial-gradient(circle at top, #dae2ec , #ffffff)
    }
    .contact_tex{
        /* background-color: rebeccapurple; */
        width: 100%;
    }
    .contact_text p{
        width: 90%;
        /* background-color: red; */
        padding: 10px;
        margin-top: -5%;
    }
    .contact_form{
        margin-top: 1%;
        width: 85%;
    }
    .fone_Number{
        margin-left: -4%;
        display: flex;
        flex-direction: column;
    }
    
    
}
