.termsOfUse{
    width: 53%;
    /* margin-left: 23%; */
    padding: 5%;
    color: white;
    background-color: #2E2D4D;
    opacity: 0.8;   
    /* margin-top: 7%; */
    padding-top: 5%;
    border-radius: 10px;
    padding-bottom: 20px;
    margin-bottom: 2%;
}

.termsOfUse h1{
    color: white;
    font-family: monospace;
    text-transform: uppercase;
    border-bottom: 2px solid white;
    padding-bottom: 20px;
    margin-bottom:5% ;
}
.termsOfUse h2{
    text-align: left;
    color: #CED82A;
}
.termsOfUse p{
    text-align: justify;
}
.termsOfUseDiv{
    margin-top: 5%;
    background-image: url("../../../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
     /* background-position: ; */
    background-size: cover;
    height: 100vh auto;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
}

@media screen and (max-width: 800px) {
    .termsOfUseDiv{
        margin-top: 10%;
        padding-top: 5%;
        }
        .termsOfUse{
            width: 75%;
            
        }
}