.check_parent{
    width: 35%;
    /* background-image: url("../../assets/Sprinkle.svg"); */
    background-color: #2E2D4D;
    opacity: 0.8;          
    margin-top: 7%;
    padding-top: 5%;
    border-radius: 10px;
    padding-bottom: 20px;
    /* top: 100%; */
}
.check_parent h1{
    /* color: rgb(37, 27, 27); */
    color: white;
    font-family: monospace;
    text-transform: uppercase;
    border-bottom: 2px solid white;
    padding-bottom: 20px;
    margin-bottom:5% ;
}

.check_parent form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.check_parent form input{      
    width: 40%;
    height: 50px;
    margin-bottom: 3% ;
    border: none;
    border-bottom: 1px solid grey;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-family: monospace;
}

.check_parent form input p{
    color: red;
    background-color: #0f0f0f;
}

.check_parent form input::placeholder{
    font-family: sans-serif;
    font-weight: 400;
    color: #0f0f0f;
}

.check_parent form button {
    width: auto ;
    border: none;
  background: transparent;
  border: 2px solid #FF7572 ;
  /* background: #CED82A; */
  /* background:#2055AB; */
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;   
    font-family: monospace;
    transition: transform 0.3s ease-in-out;
}

.check_parent form button:hover{
    transform: translateY(-10px);
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50%;
}
.tiptop{
    width: 50%;
    margin-top: 10%;
}

.tiptop img{
    width: 100%;
}

.sideImg2{
    width: 35vw;
    height: 52vh;
    margin-top: 7%;
    border-radius: 10px;
    /* z-index: -1; */
    /* background-image: url("../../assets/sideimage.jpg"); */
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1)
     0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset; */
     
}

.sideImg2 img{
    width: 100%;
    height: 100%;
}

.check_main{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;   
    background-image: url("../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
    /* background-position: ; */
    background-size: cover;
    height: 80vh;
}

.findicon{
    font-size: 2em;
}
@media  screen and (max-width: 1650px){
    .check_parent{
        width: 40vw;
    }
    .sideImg2{
        width: 42vw;
        height: 60vh;
    }
    .check_parent form button {
        padding: 5px;
    }
    .check_main{
        padding-top: 10px;
    }

}

@media screen and (max-width: 600px) {
    .check_parent{
        margin-top: 35%;
        background-color: red;
        background-image: url("../../assets/Sprinkle.svg");
        width: 90%;
        border-radius: 5px;
        /* margin-left: 5%; */
    }
    .sideImg2{
        display: none;
    }
    .check_parent form input{
        width: 60%;
        height: 40px;
        margin-bottom: 5% ;
    }
    .check_parent form{
        margin-top: 13%;
    }
}