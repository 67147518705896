.Adult_parent{
    background: RGB(236,240,243);
    position: absolute;
    width: 16% ;
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    margin-top: -1%;
    padding: 10px 30px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    z-index: 1;
}
.firstButton{
    display: flex;
    flex-direction: row;
    
}

.firstButton h5{
   padding-left: 2%;
    color: RGB(85,85,85);
}

.main_button{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid RGB(148,148,148);
    width: 40%;
    margin-left: 42%; 
    height: 3rem;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    font-size: 0.8rem;
    color: RGB(115,115,115);
}

.main_button button{
    height: 50%;
   width: 20%;
   border: none;
   background-color: RGB(236,240,243);
   color: RGB(63,148,209);
   font-size: 1.5rem;
   cursor: pointer;
  
}

.main_button2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid RGB(148,148,148);
    width: 40%;
    margin-left: 36.6%;
    margin-top: 2%;
    margin-bottom: 4.5%;
    height: 3rem;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    font-size: 0.8rem;
    color: RGB(115,115,115);
}
.main_button2 button{
    height: 50%;
   width: 20%;
   border: none;
   background-color: RGB(236,240,243);
   color: RGB(63,148,209);
   font-size: 1.5rem;
   cursor: pointer;
  
}

.done_button button{
    border: 1px solid RGB(0,113,194);
    color: RGB(0,113,194);
    width: 80%;
    padding: 10px 0;
}

.selectopt{
    margin-right: 90%;
    height: 40px;
    width: 40%;
    border: 1px solid RGB(204,0,0);
    padding: 5px;
}
.showAgediv p{
    text-align: justify;
    font-size: 0.9rem;
    color: RGB(115,115,115);
}


