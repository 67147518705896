@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.parentCreatBlog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: rgb(22, 125, 216); */
    width: 100%;
    height: 93vh;
    margin-top: 5%;
    font-family: 'Poppins', sans-serif;
    padding: 40px 0;
    background-image: url("../../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
    /* background-position: ; */
    background-size: cover;
}

.creatblogDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    /* margin-top: 10%; */
    background-color: antiquewhite;
    height: 100%;
}

.creatblogDiv .blogPostArea {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: aliceblue;
    overflow-y: scroll;
    padding: 30px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.creatblogDiv .blogPostArea h2 {
    text-align: left;
    /* background-color: blueviolet; */
    width: 100%;
    font-size: 3em;
    text-transform: capitalize;
    font-weight: 800;
}

.creatblogDiv .blogCategoryselection {
    /* background-color: cadetblue; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 1%;
}

.creatblogDiv .blogCategoryselection select {
    width: 98%;
    height: 35px;
    border-radius: 5px;
    margin-bottom: 2%;
}



.blogInputDiv {
    background-color: azure;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
    width: 100%;
}

.blogInputDiv label {
    width: 100%;
    text-align: left;
}

.blogInputDiv input {
    width: 96%;
    height: 35px;
    border-radius: 5px;
    margin-bottom: 2%;
    padding: 0px 10px;
}

.blogInputDiv .chooseimagefield {
    border: 2px solid gray;
    color: white;
}

.blogInputDiv textarea {
    width: 96%;
    height: 100px;
    border-radius: 5px;
    margin-bottom: 2%;
    padding: 10px 10px;
}

.richtextArea {
    /* background-color: antiquewhite; */
    width: 96%;
    border-radius: 5px;
    margin-bottom: 3%;
}

.summaryErrorDiv {
    margin-top: -5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.summaryErrorDiv p {
    width: 100%;
    /* background-color: #2E2D4D; */
    text-align: right;
}


.blogInputDiv .previewImageDiv {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* height: 100px; */
    border-radius: 5px;
    margin-bottom: 2%;
    /* object-fit: contain; */
}

.blogInputDiv .previewImageDiv img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.blogInputDiv .removeField {
    border: none;
    border-bottom: 2px solid red;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: transparent;
    color: red;
    cursor: pointer;
    border-radius: 10px;
    margin-top: -3%;
    margin-bottom: 2%;
    margin-left: 70%;
    white-space: nowrap;
}

.addmorefielddiv {
    width: 100%;
    background-color: azure;
}

.addmorefielddiv .blogInputDiv {
    width: 100%;
}

.addmoreinputbtndiv {
    background-color: aliceblue;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 100%;
    padding: 10px 0px 10px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.addField {
    border: none;
    border-bottom: 2px solid green;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin: 1%;
}

.addmoreinputbtndiv .addIcon {
    color: green;
}

.subContentiv {
    width: 100%;
}

.blogInputDivsubCont {
    width: 100%;
    background-color: azure;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
    margin-top: 2%;

}

.blogInputDivsubCont label {
    width: 100%;
    text-align: left;
}

.blogInputDivsubCont input {
    width: 96%;
    height: 35px;
    border-radius: 5px;
    margin-bottom: 2%;
    padding: 0px 10px;
}

.blogInputDivsubCont .chooseimagefield {
    border: 2px solid gray;
    color: white;
}

.blogInputDivsubCont textarea {
    width: 96%;
    height: 100px;
    border-radius: 5px;
    margin-bottom: 2%;
    padding: 0px 10px;
}

.blogInputDivsubCont .addSubParagraph {
    border: none;
    border-bottom: 2px solid green;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin: 1%;
}

.blogInputDivsubCont .addIcon {
    color: green;
}

.blogInputDivsubCont .removeField {
    border: none;
    border-bottom: 2px solid red;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: transparent;
    color: red;
    cursor: pointer;
    border-radius: 10px;
    margin-top: -3%;
    margin-bottom: 2%;
    margin-left: 70%;
    white-space: nowrap;
}

.blogInputDivsubCont .previewImageDiv {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* height: 100px; */
    border-radius: 5px;
    margin-bottom: 2%;
    /* object-fit: contain; */
}

.blogInputDivsubCont .previewImageDiv img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.blogPostBtn {
    background-color: azure;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    padding: 10px 15px 10px 15px;
    border-top: 2px solid gray;
}

.previewSection {
    background-color: blanchedalmond;
    height: 100%;
    /* width: 70%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: aliceblue;
    overflow-y: scroll;
    padding: 30px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.previewSection p {
    text-align: left;
}

.previewSection h3 {
    text-align: left;
    /* background-color: blueviolet; */
    width: 100%;
    font-size: 1.3em;
    text-transform: capitalize;
    font-weight: 800;
}

.blogPostBtn .previewBtn {
    background-color: green;
    border-radius: 5px;
    padding: 10px 25px;
    color: white;
    border: 1px solid green;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.blogPostBtn .submitpostBtn {
    background-color: #2E2D4D;
    border-radius: 5px;
    padding: 10px 25px;
    color: white;
    border: 1px solid #2E2D4D;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

@media screen and (max-width: 1000px) {
    .parentCreatBlog {
        width: 100%;
    }

    .creatblogDiv {
        width: 75%;
        margin-left: 5%;
    }

    .richtextArea {
        margin-bottom: 20%;
    }
}

@media screen and (max-width: 800px) {


    .parentCreatBlog {
        width: 90%;
    }

    .creatblogDiv {
        width: 75%;
        margin-left: 25%;
    }

    .creatblogDiv .blogPostArea {
        padding: 20px;
    }

    .creatblogDiv .blogCategoryselection {
        flex-direction: column;
    }

    .blogInputDiv {
        background-color: transparent;
    }

    .blogPostBtn .previewBtn {

        padding: 10px 25px;
        white-space: nowrap;
    }

    .blogPostBtn .submitpostBtn {

        padding: 10px 15px;
        white-space: nowrap;
    }

    .blogPostBtn {
        flex-direction: column;
    }
}

@media screen and (max-width: 500px) {
    .richtextArea {
        margin-bottom: 40%;
    }

    .blogInputDivsubCont .removeField {
        margin-left: 0%;
    }

    .blogInputDiv .removeField {
        margin-left: 0%;
    }
}