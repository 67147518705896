@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
.dropdown{
    width: 100% ;
    height: 40vh auto;
    background: rgb(255, 255, 255);
    position: absolute;
    text-align: left;
    padding: 10px;
    margin-left: 0%;
    margin-top: -1%;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  border-radius: 7px;
   z-index: 1000;
   
}
.dropList li{
    list-style: none;
    text-align: left;
    margin-bottom: 15px;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    cursor: pointer;
}
.dropicon{   
    margin-right: 5px;
    font-size: 1.2rem;
    font-weight: 600;
    
}

.dropdown h3{
    font-size: 0.9em;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    background-color: #dae2ec;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
}

.dropdown h4{
    font-size: 0.9em;
    font-family: 'Poppins', sans-serif;
    background-color: #dae2ec;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    /* margin-left: -8%; */
    font-weight: 600;
  
}

.dropList li p{
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 8%;
    margin-top: -1%;
    color: gray
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 45%;
}

@media  screen and (max-width: 750px){
    .dropdown{
        width: 43%;
        padding-left: 5px;
    }
    .dropdown h4{
        font-size: 0.5em;
    }
    .dropdown h3{
        font-size: 0.5em;
    }

}

@media  screen and (max-width: 600px){
    .dropdown{
        /* margin-left: -9%; */
        width: 85%;
    }
}