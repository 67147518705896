.otp {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 30%;
    align-items: center;
    
  }
  .verifyDiv {
    /* padding: 30px; */
    margin-top: 10%;
    margin-left: 25%;
    width: 50%;
    padding-top: 1%;
    background: #2E2D4D;
    height: 50vh auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2%;
  }
  .p {
    color: white;
    margin-bottom: 0px;
  }
  .inputStyle {
    width: 50% !important;
    height: 45px;
    border-radius: 7px;
    border: 0px;
    /* margin-left: 8px;
    margin-right: 8px; */
    background: #dddddd;
    font-size: 20px;
  }
  .p3 {
    font-size: 14px;
  }
  .resend {
    text-decoration: underline;    
    font-size: 14px;
  }
  .otpElements {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-left: 18px;
    justify-content: center;
  }
  
  .p1 {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    margin-top: 25px;
    font-size: 27px;
  }
  
  .p2 {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
  }
  .verifyBtn {
    color: rgba(0, 67, 44, 0.83);
    font-size: 20px;
    font-weight: 600;
    width: 190px;
    height: 53px;
    border: 0px;
    background: #dddddd;
    box-shadow: 0px -4px 36px 9px rgb(0 0 0 / 30%);
    border-radius: 32px;
    cursor: pointer;
  }
  