:root {
  --blue: #14d0f0e6;
  --pink: rgba(255, 131, 195, 0.4);
  --white: rgba(255, 255, 255, 0.9);
}


@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  font: 100% system-ui, sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 0;
}

.herofixed {
  position: fixed;
  top: 8.5%;
  width: 100vw;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.background {
  /* background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyODbbLuO6sdFIyPmy_7Mp-zCmm3ugU604LA&usqp=CAU); */
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: #252746; */
  background-position: center;
  width: 100vw;
  height: 60vh;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}


.left-content {
  /* width: 30vw auto; */
  /* height: 35%; */
  width: 60%;
  /* margin-bottom: 1%; */
  /* background-color:#252746; */
  /* margin-top: -8%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;

}



.background-texture {
  /* background: linear-gradient(to top, rgba(3, 2, 1, 0.3), rgba(255, 255, 255, 0.9)); */
  /* background: linear-gradient(to top, rgba(3, 2, 1, 0.3), #252746), 0.7; */
  background-size: cover;
  width: 100vw;
  height: 60vh;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.header {
  width: 100vw;
  /* background-color: red; */
  height: 67vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* position: relative; */
  /* top: 0%; */

}

.svglike1 {
  border-radius: 43% 57% 100% 0% / 99% 42% 58% 1%;
  height: 40vh;
  z-index: 0;
  width: 20%;
  background: #2E2D4D;
  /* background: linear-gradient(180deg, RGB(30,83,169) 0%, #f5f5f5 100%); */
  position: absolute;
  transform: rotate(135deg);
  top: -1%;
  left: 3%;
  opacity: 0.9;
}

.svglike2 {
  border-radius: 43% 57% 1% 99% / 61% 100% 0% 39%;
  height: 40vh;
  width: 20%;
  top: 40%;
  right: 0%;
  background: #2E2D4D;
  /* background: linear-gradient(0deg, RGB(30,83,169) 0%, #f5f5f5 100%); */
  position: absolute;
  opacity: 0.9;
}


.hero_img {
  height: 79.7%;

  width: 16%;
  border-radius: 50%;
  margin-top: 6%;
}

.hero_img img {
  height: 100%;
  width: 100%;
  border-radius: 10%;
}

.hero_search {
  width: 100vw;
  /* height: 200px; */
  /* background-color: #b8b5b5; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%; */
}





.stay-home {
  font-size: 3.5em;
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  letter-spacing: 0.3rem;
  margin-bottom: 1%;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  color: #fcfcfc;

}

.minutesspan {
  font-size: 0.8em;
  font-weight: 600;

  /* color: ; */
}

.left-content h1 {
  /* margin: 0 1 1rem 0; */
  /* margin-top: 11rem; */
  letter-spacing: 0.3rem;
  margin-left: -5rem;
  font-weight: 900;
  font-size: 5rem;
  text-transform: uppercase;
  /* color: #3b365e; */
  background: radial-gradient(circle at top, #ffffff 50%, #b8b5b5 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 3px solid #e7e6eb;
  /* color: white; */

  z-index: 4;

}

.para {
  color: #3b365ecb;
  width: 50%;
}

@media screen and (max-width: 103.125em) {

  /* 1650px */
  .stay-home {
    font-size: 2.5em;
    /* margin-top: -8%; */
    /* font-size: 1.6em;
    font-weight: 700; */
  }

  .header {
    margin-top: 3%;
    /* background: #2E2D4D; */
  }

  .hero_search {
    top: 50%;
  }
}

@media screen and (max-width: 75em) {

  /* 1200px */
  .hero_img {
    height: 79%;
    width: 27%;
    border-radius: 50%;
    margin-top: 6%;
    margin-left: 6%;
  }

  .svglike2 {
    opacity: 1;
    display: none;
  }
}

@media screen and (max-width: 62.5em) {

  /* 1000px */
  .header {
    background: #2E2D4D;
    width: 100vw;
    gap: 30px;
  }

  .left-content {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero_img {
    display: none;
  }

  .left-content h1 {
    font-size: 2.5rem;
    background: radial-gradient(circle at top, #ffffff 50%, #b8b5b5 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 8%;
  }

  .stay-home {
    font-size: 1.6em;
    width: 100%;
    z-index: 9;
    margin-left: 0.9%;
  }

  .svglike1 {
    width: 80%;
  }

  .svglike2 {
    display: none;
  }

  .hero_search {
    top: 75%;
  }
}

@media screen and (max-width: 50em) {

  /* 800px */
  .herofixed {
    top: 15%;
  }

  .stay-home {
    font-family: monospace;
    margin-top: -10%;
    width: 100%;
  }

  .hero_search {
    top: 75%;
    height: 130px;
  }
}

@media screen and (max-width: 46.875em) {

  /* 750px */
  .hero_search {
    width: 95vw;
    background-color: #2E2D4D;
    height: 100px;
    border: none;
  }

  .stay-home {
    font-family: monospace;
    margin-top: -10%;
    width: 100%;
  }
}

@media screen and (max-width: 40.625em) {

  /* 650px */
  .stay-home {
    font-family: monospace;
    text-align: center;
    margin-top: -10%;
    width: 100%;
  }

  .header {
    width: 95vw;
  }

  .heroparent {
    /* width: 95vw; */
  }

  #svg-background {
    display: none;
  }
}

@media screen and (max-width: 31.25em) {

  /* 500px */
  .header {
    width: 100vw;
  }
}

@media screen and (max-width: 23.125em) {

  /* 370px */
  .header {
    width: 100vw;
  }
}