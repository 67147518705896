.newsRoom{
margin: 0%;
background-image: url("../../../../assets/Moon\ \(2\).svg");
background-repeat: no-repeat;
 /* background-position: ; */
background-size: cover;
height: 100vh auto;
background-attachment: fixed;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.newsRoomMainDiv{
    width: 50%;
}

.newsRoomText{
    color: white;
    text-align: justify;
    padding: 20px;
}