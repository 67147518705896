@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.destination_parent2{
    height: 20vh auto;
    width: 100% ;
    position: relative;
    margin-bottom: 1%;
    /* z-index: -1; */
    display: flex;
    flex-direction: column;
    align-items: center;
   
   
}

.destination_list{
    /* background-color: rebeccapurple; */
   
    width: 100% ;
 
}
/* 
.destination_parent .list_div333{
    background-color: #0e0a94; 
    width: 100em;
    height: 100vh;
    position: absolute;
} */
.destination_parent2 h4{
    text-transform: capitalize;
    font-size: 1.2em;
    margin-bottom: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    /* opacity: 0.7; */
    background-color: #ECF1FF;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    padding: 1%;
    /* background: radial-gradient(circle at top, #b2bedd 50%, #585252 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    border-bottom: 3px solid #e7e6eb;
    padding-left: 10px;
    padding-right: 10px;
    width: 30%;
    border-radius: 5px;
    
}
.destination_parent2 p{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.7em;
    width: 50%;
}
.destination_parent2 .destina_hr{
    width: 100px;
    
}
.destination_parent2 .destination_list {
 display: grid;
 grid-template-columns: repeat(4, 1fr);
 /* width: 100% ; */
 grid-gap: 25px;
 list-style: none;
 margin-top: 10px;
 cursor: pointer;
 /* width: 70% ; */
 
}

.destination_parent2 ul h5{
    color: #d5d4df;
    cursor: pointer;
    font-size: 1.8em;
font-family: monospace;
font-weight: 800;
margin-bottom: 0%;

}
.stateName{
margin-top: 0%;
color: #D0D62C;
}
.destination_parent2 ul li{
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px; */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    /* background-color: red; */
    /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, 
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
    border-radius: 15px;
    padding-top: 0%;
    /* border: 2px solid #2E2D4D; */
    width: 12em;
    height: 13em;
    font-size: 1.3em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-transform: capitalize;
    /* background-image: url("https://images.unsplash.com/photo-1619605531178-7742f7586324?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGNpdHklMjBiYWNrZ3JvdW5kfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"); */
}

.li_horizontal{
    margin-top: -10%;
    width: 5em;
    /* background-color: #0e0a94; */
    border: 3px solid #2E2D4D;
    border-radius: 20px;
}

.destination_parent2 .stateCount{
 color:#2E2D4D;
 cursor: pointer;
 font-size: 1.2em;
 font-weight: 600;
 opacity: 0.7;
 font-family: m;
 background-color: #ECF1FF;
 border-radius: 10px;
 padding: 8px;
 margin-top: 0%;
}

.loadMoreBtn{
    margin-left: 80%;
    padding: 10px 20px 10px 20px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #2E2D4D;
    cursor: pointer;
    font-size: 1em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    white-space: nowrap;
}

.loadMoreBtn:hover{
    background-color: #2E2D4D;
    color: white;
    font-size: 0.8em;
    font-weight: 300;
}

@media  screen and (max-width: 1650px){
    .destination_parent2 ul li{
        width: 9em;
       height: 11em;
      }
      .destination_parent2 .stateCount{
        font-size: 1.8em;
        padding: 5px; 
      }
}
@media  screen and (max-width: 1200px) {
    .destination_parent2 .destination_list{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media  screen and (max-width: 1000px) {
    .destination_parent2{
        /* width: 70%; */
        margin-top: 20%;
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin-left: 0 */
    }
    .destination_parent2 .destination_list{
      max-width: 70%;
      min-height: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      /* width: 100% ; */
      grid-gap: 25px;
      list-style: none;
      margin-top: 40px;
      cursor: pointer;
        
    }
    .destination_parent2 h4{
        font-size: 1.1em;
        width: 80%;
    }
   
}
@media  screen and (max-width: 750px) {
    .destination_parent2 .destination_list{
        max-width: 70%;
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* width: 100% ; */
        grid-gap: 25px;
        list-style: none;
        margin-top: 40px;
        cursor: pointer;
          
      }
      .destination_parent2 ul li{
        width: 10em;
       height: 12em;
      }
}
@media  screen and (max-width: 850px){
    .destination_parent2 .destination_list{
        margin-left: -4%;
    }
    .destination_parent2 ul li{
        width: 10em;
       height: 12em;
      }
      .destination_parent2{
        margin-top: 5%;
    }

    .loadMoreBtn{
margin-left: 40%;
    }
}

@media  screen and (max-width: 600px){
    .destination_parent2{
        /* background-color: rebeccapurple; */
        width: 95vw;
    }
    .destination_parent2 h4{
        font-size: 1.1em;
        width: 100%;
    }
    .destination_parent2{
        margin-top: 6%;
    }
    .stateCount{
        font-size: 1em;
    }
}
@media  screen and (max-width: 500px) {
    .destination_parent2 .destination_list{
        /* max-width: 70%;
        min-height: 100%; */
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* width: 100% ; */
        grid-gap: 25px;
        list-style: none;
        margin-top: 40px;
        cursor: pointer;
          margin-left: -5%;
      }
      .destination_parent2 ul li{
        width: 8em;
       height: 11em;
      }
      .destination_parent2 ul h5{
        color: #2E2D4D;
        cursor: pointer;
        font-size: 1em;
    font-family: monospace;
    font-weight: 800;
    }
}
@media  screen and (max-width: 550px){
    .destination_parent2 .destination_list{
        margin-left: -10%;
    }
    .destination_parent2 ul li{
        width: 8em;
       height: 11em;
      }
      .destination_parent2 ul h5{
        color: #2E2D4D;
        cursor: pointer;
        font-size: 1em;
    font-family: monospace;
    font-weight: 800;
    }
    .destination_parent2 h4{
        font-size: 1em;
      }
}
@media  screen and (max-width: 450px){
    .destination_parent2 .destination_list{
       
        margin-left: -4%;
    }
    .destination_parent2 h4{
        font-size: 1em;
      }
}
@media  screen and (max-width: 400px){
    .destination_parent2 .destination_list{
       
          margin-left: -3%;
      }
      .destination_parent2 h4{
        font-size: 1em;
      }
     
      .destination_parent2 ul li{
        width: 6em;
        height: 10em;
      }
}

