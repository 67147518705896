@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
.pagination{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    font-size: 1.2rem;
    gap: 3px;
   
    
    
  }
  
  .pagination .page-num{
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8em;
    font-weight: 400;
    color: #a8a7b3;
    border: 1px solid #2E2D4D;
  }
  
  
  .pagination .page-num:hover{
    background-color: #2E2D4D;
    color: #fff;
  }

   .break-lable{ 
    padding: 2px 10px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 400;
    color: #060a0f;
    background-color: white;
    border: 1px solid #2E2D4D;
  }

  .breakLink{
    color: #060a0f;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8em;
  }
  
  .active2{
    background-color: #272641;
   
    border-radius: 5px;
    padding: 6px 3px;
  }
  .showActive{
    color: whitesmoke;
  }
@media screen and (max-width: 1000px) {
  .pagination{
    overflow-x: scroll;
  }
}
  