.patnerShip{
margin-top: 5%;
background-image: url("../../../../assets/Moon\ \(2\).svg");
background-repeat: no-repeat;
 /* background-position: ; */
background-size: cover;
height: 100vh auto;
background-attachment: fixed;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-top: 2%;
}

.patnerShipMainDiv{
    width: 53%;
    padding: 25px;
  
    /* margin-left: 23%; */
    padding: 5%;
    color: white;
    background-color: #2E2D4D;
    opacity: 0.8;   
    /* margin-top: 7%; */
    padding-top: 5%;
    border-radius: 10px;
    padding-bottom: 20px;
    margin-bottom: 2%;
}
.patnerShipMainDiv h1{
    color: white;
    font-family: monospace;
    text-transform: uppercase;
    border-bottom: 2px solid white;
    padding-bottom: 20px;
    margin-bottom:5% ;
}

.patnerShipMainDiv h2{
    text-align: left;
    color: #CED82A;
    font-size: 1.2em;
}

.patnerShipMainDiv p{
    color: white;
    text-align: justify;
}

.contactInfo{
    color: #CED82A;
    font-size: 1.2em;
}

@media screen and (max-width: 800px) {
    .patnerShip{
        margin-top: 10%;
        padding-top: 5%;
        }
        .patnerShipMainDiv{
            width: 75%;
            
        }
}