.parentCurrency{
    position: absolute;
    background-color: aliceblue;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 14%;
    left: 50%;
    top: 73%;
    padding: 10px;
    border-radius: 5px;
}

.parentCurrency::before {
    content: '';
    position: absolute;
    top: -10.5%;
    left: 90%;
    transform: rotate(360deg);
    margin-left: -10px;
    border-width: 16px;
    border-style: solid;
    border-color: transparent transparent aliceblue transparent;
}

.currIntro{
    /* background-color: aqua; */
    text-align: justify;
    width: 93%;
}

.currencyList{
    /* background-color: antiquewhite; */
    width: 100%;
    text-align: justify;
}

.currencyItem{
    background-color: #ABABB7;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
}

.currencyItem:hover{
    background-color: #3E3E5B;
    color: white;
}

.currencyItem.active {
    background-color: black;
    color: white;
    /* Example background color for the active item */
}