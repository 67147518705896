@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');


.parent_loginDiv {
    background-color: transparent;
    height: 90vh;
    width: 100%;
    /* margin-left: 2%; */
    position: relative;
    margin-top: 5%;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    padding-bottom: 20px;
    /* background-image: url("../../assets/Moon\ \(2\).svg"); */
    background-repeat: no-repeat;

    background-size: cover;

}

.parent__log {
    background-image: url("../../../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
    /* background-color: #2055AB; */
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh auto;
}
       
.parent_loginDiv h2 {
    /* background-color: aquamarine; */
    font-family: Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    margin-left: 1%;
    /* font-weight: 100; */
    /* padding-left: 50%; */
}

.parent_Logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}


.parentCard {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30%;
    /* margin-left:3% ; */
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    /* background-color: rebeccapurple; */
    /* padding-left:1%; */
}

.dashboard__header {
    text-align: justify;
}

.profil_pic {
    border: 2px solid #2E2D4D;
    width: 70px;
    height: 70px;
    border-radius: 50px;
}

.profil_pic img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.divInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: justify;
    /* background-color: rebeccapurple; */
    /* width: 100%; */
}

.upperSession {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2E2D4D;
    width: 100%;
    height: 100%;
    color: white;
}

.upperSession2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #684276;
    width: 100%;
    height: 100%;
    color: white;
}

.upperSession3 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #BC8080;
    width: 100%;
    height: 100%;
    color: white
}

.functionDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 20%;
    height: 12em;
    /* padding-top: 5px; */
    background-color: white;
    padding-bottom: 20px;
    margin-left: 2%;
}

.functionDiv:hover {
    background: #2E2D4D;
    cursor: pointer;
    color: white;
}

.functionDiv2:hover {
    background: #684276;
    cursor: pointer;
    color: white;
}

.functionDiv3:hover {
    background: #BC8080;
    cursor: pointer;
    color: white;
}



.functionDiv span {
    font-size: 1em;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    ;
    /* margin-top: 10%; */
}

.functionDiv .historyicon {
    font-size: 2em;
    font-weight: 800;
    margin-bottom: 10px;
    margin-right: 3px;
    color: #fff;
}

.history {
    font-size: 1.3em;
    margin-left: 2.2%;
}

.functionDiv .historyicon:hover {
    color: white;
}

.hrblow {
    width: 100%;
}

.today_search2 {
    background-color: white;
    width: 50%;
    margin-left: 25%;
    margin-top: 7%;
    margin-bottom: 7%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

.today_search2 .searchField {
    height: 40px;
    width: 76%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-right: none;
    border: 1px solid rgb(150, 133, 133);
    padding-left: 2%;
    padding-right: 2%;
    color: rgb(31, 22, 22);
}

.today_search2 .searchFieldBtn {
    height: 45px;
    width: 20%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-left: none;
    background: #2055AB;
    border: 1px solid #2055AB;

}

@media screen and (max-width: 1650px) {
    .parent_Logout {
        /* margin-top: 8%; */
        padding-top: 3%;
        /* background-color: rebeccapurple; */
    }

    .parentCard {
        margin-top: 7%;
    }
}

@media screen and (max-width: 1300px) {
    .parent_Logout {
        /* margin-top: 10%; */

    }

}

@media screen and (max-width: 1000px) {
    /* .side_menu {
        background-color: #2E2D4D;
        width: 27%;
        height: 100%;
    } */
}

@media screen and (max-width: 850px) {
    .parent__log {
        width: 90%;
        margin-left: 20%;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .dashboard__header {
        background-color: aliceblue;
        padding: 10px 15px 10px 15px;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        opacity: 0.9;

    }

    .parent_Logout {
        width: 100%;
    }

    .parentCard {
        width: 100%;
    }
}


@media screen and (max-width: 600px) {
    .parent_Logout {

        margin-top: -15%;
        justify-content: flex-start;
        width: 100%;
    }

    .parent_loginDiv {
        width: 100%;
        margin-left: 0%;

        margin-top: 21%;
        /* background-color: rebeccapurple; */
        height: auto;

    }

    .parent_loginDiv h2 {
        font-size: 1em;
        font-family: sans-serif;
    }

    .functionDiv {
        width: 89%;
        height: 80%;
        /* margin-left: 9%; */
        border-radius: 5px;
        padding-bottom: 10px;
    }

    .parentCard {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-auto-rows: 150px;
        grid-column-gap: -10px;
        width: 100%;
        /* padding-left: 25.5%; */
        margin-top: 3%;
        /* background-color: rebeccapurple; */
    }

    .side_menu_list {
        /* margin-left: -35%; */
        /* background-color: red; */
    }

    /* .side_menu {
        background-color: #2E2D4D;
        width: 27%;
        height: 100%;
    } */

    .profile {
        /* margin-left: -3%; */
    }

    .history {
        margin-left: -5%;
    }

    .profil_pic {
        /* margin-left: 7%; */
    }
}

@media screen and (min-width: 412px) {}