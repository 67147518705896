@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Source+Sans+Pro:wght@200&display=swap');
.parent_amen{
    
    height: 100vh auto;
    width: 95%;
   
}
.hotel_amen_header{
    text-align: justify;
    width: 100%;
    text-transform: capitalize;
    font-size: 0.9rem;
    color: #2E2D4D;
    
}

.hotel_amen_header h2{
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.amen_list ul{
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-row-gap: 20%;
grid-column-gap:10px ;
border-top: 1px solid grey;
border-bottom: 1px solid gray;

padding-top: 30px;
padding-bottom: 50px;
padding-left: 0;
}
.amen_list ul li{
    list-style: none;
    /* border: 2px solid #2E2D4D; */
    /* margin-left: 2px; */
    padding: 8px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 0.8em;
    
}

.icon{
    color: #2E2D4D;
}
.amen_list ul li:hover{
    background-color: #2E2D4D;
    color: aliceblue;
    cursor:all-scroll;
}

.tableIcon{
    color: burlywood;
}

.poolIcon{
    color: aqua;
}

.cableIcon{
    color: green;
}

.wifiIcon{
    color: red;
}

.fridgeIcon{
    /* color: #EF8D4B; */
    color: cadetblue;
}

.genIcon{
    color: yellowgreen;
}

@media screen and (max-width: 1000px) {
    .parent_amen{
        margin-top: 10%;
        overflow-x: hidden;
    }
    .hotel_amen_header h2{
        /* background-color: rebeccapurple; */
        width: 95%;
        font-size: 1.3rem;
        font-family: monospace;
        padding: 8px;
        
    }
    .amen_list ul{
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .amen_list ul li{
        font-size: 0.6em;
    }
}