@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
:root {
    --surface-color: #09111f;
    --curve: 40;
  }
  
  .deals {
    box-sizing: border-box;
  }
  .parent__Deals{
    /* background-color: #EDF2F8; */
    background: linear-gradient(180deg, #FFFFFF 0%, #dae2ec 100%);
    padding-bottom: 2%;
    /* height: auto; */
    height: 10;
    padding: 5%;
    position: relative;
  }
  
  .deals {
    /* font-family: 'Noto Sans JP', sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-size: 0.8em;
    width: 70%;
/* position: absolute; */
    margin-left: 15%;
    /* background-color: #3d5a7e; */
 
    /* height: 100vh; */
  }
  .deals h4{
    text-transform: capitalize;
    font-size: 2em;
    margin-bottom: 25px;
    font-family: 'Poppins', sans-serif;
   font-weight: 600;
    background-color: #ECF1FF;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    padding: 1%;
    /* background: radial-gradient(circle at top, #b2bedd 50%, #585252 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    border-bottom: 3px solid #e7e6eb;
    width: 50%;
    margin-left: 23%;
  }
  .deals hr{
    width: 100px;   
  }

  .offLine2{
    background-color: rgb(243, 65, 65);
    color: white;
    width: 50%;
    margin-top: 2%;
    margin-left: 23%;
    font-family: 'Poppins', sans-serif;
    font-size: 0.7em;
    opacity: 0.9;
  }
  
  .cards2 {
    display: grid;
    grid-template-columns:1fr 1fr 1fr;
    grid-auto-rows: 350px;
    /* gap: 2rem; */
    /* grid-gap: 2%; */
    grid-column-gap: 4%;
    grid-row-gap: 2%;
    margin: 2rem 5vw;
    padding: 0;
    list-style-type: none;
    position: relative;
    /* background-color: #EDF2F8; */
    /* background-color: #3d5a7e; */
 
    /* padding-left: 5%; */
  }
  
  .card {
    position: relative;
    display: block;
    height: 100%;  
    border-radius: calc(var(--curve) * 1px);
    overflow-y: hidden;
    text-decoration: none;
    
  }
  
  .card__image {      
    width: 100%;
    height: 100%;
  }
  
  .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;      
    border-radius: calc(var(--curve) * 1px);    
    background-color: var(--surface-color);      
    transform: translateY(100%);
    transition: .2s ease-in-out;
    opacity: 0.7;
  }
  
  .card:hover .card__overlay {
    transform: translateY(0);
  }
  
  .card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;    
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .2s ease-in-out;
  }
  
  .card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;      
    z-index: 1;
  }
  
  .card__arc path {
    fill: var(--surface-color);
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
  }       
  
  .card:hover .card__header {
    transform: translateY(0);
  }
  
  .card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;      
    border-radius: 50%;      
  }
  
  .card__title {
    font-size: 1em;
    margin: 0 0 .3em;
    color: white;
  }
  
  .card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";  
    font-size: .8em; 
    color: white;  
  }
  
  .card__status {
    font-size: .8em;
    color: white;
  }
  
  .card__description {
    padding: 0 2em 2em;
    margin: 0;
    color: white;
    font-family: "MockFlowFont";   
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }  
  
  
  /* css ribbon */
  .badge{
    position: relative;
    z-index: 0;
    /* background-color: red; */
  }

  .badge i {
    position: absolute;
    top: 2%;
    left: -15px;
    width: 280px;
    height: 40px;
    background:#2E2D4D;    
    border-radius: 30px;
    border-bottom-left-radius: 0;
    z-index: 1;
    opacity: 0.9;
    color: #CED82A;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   
  }
  .badge i::before{
    content: " ";
    position: absolute;
    top: 40px;
    left: 0px;
    width: 15px;
    height: 30px;
    background: #5b5994;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    z-index: 2;
  }

  .badge i::after{
    content: " ";
    position: absolute;
    left: 0px;
    top: 40px;
    width: 15px;
    height: 15px;
    background: #2E2D4D;
  }

  .badge i .percentageAmt{
    padding: 0 3px 0 3px;
    font-size: 1.4em;
    font-weight: 600;
    /* background-color: whitesmoke; */
  }

  .today_search{
    background-color: white; 
    width: 50%;
    margin-left: 25%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2%;
  }

  .today_search .searchField{
    height: 40px;
    width: 76%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-right: none;
    border: 1px solid rgb(150, 133, 133);
    padding-left: 2%;
    padding-right: 2%;
    color: rgb(31, 22, 22);
  }

  .today_search .searchFieldBtn{
    height: 45px;
    width: 20%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-left: none;
    background:#2E2D4D;
    border: 1px solid #2E2D4D;
    
  }

  .searchicons99{
    color: white;
    font-size: 2em;
   
  }

  @media  screen and (max-width: 1650px){
    .deals{
      width: 100%;
      margin: 0;
      /* background-color: red; */
    }
    .card{
      /* display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 80%;
      width: 80%; */
      
   
    grid-auto-rows: 350px;
    grid-gap: 0.1%;
    
    padding: 0;
    list-style-type: none;
    /* background-color: #EDF2F8; */
    
    /* padding-left: 12%; */
    }
  }

  @media  screen and (max-width: 1000px) {
   .deals{
    margin-left: 0;
    margin-right: 0;
   
    width: 100%;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   }
   .cards2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 350px;
    grid-column-gap: 4%;
    grid-row-gap: 2%;
    margin: 2rem 5vw;
    padding: 0;
    list-style-type: none;
    /* background-color: #EDF2F8; */
    
    padding-left: 12%;
  }
  .deals h4{
    width: 100%;
    margin-left: 0;
  }
 
  }

  

  @media  screen and (max-width: 600px){
    .cards2 {
      /* margin: 2rem 5vw; */
      padding-right: 28%;
      /* grid-gap: 0%; */
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      grid-auto-rows: 350px;
      grid-column-gap: 4%;
    grid-row-gap: 1%;
      /* background-color: rebeccapurple; */
      overflow-y: scroll;
      overflow-x: hidden;
      width: 75vw;
    }
    
    .deals h4{
      width: 100%;
      /* margin-left: 0; */
      font-size: 2em;
      
    }
    .parent__Deals{
      /* background-color: red; */
      width: 85vw;
    }
    .badge{
      width: 85vw;
    }
  }

  @media  screen and (max-width: 500px){
    .parent__Deals{
      
      width: 90vw;
      /* margin-left: -5%; */
      }
      .deals{
        width: 100vw;    
      }

      .deals h4{
        width: 77%;
        margin-left: 0%;
        font-size: 1em;
        font-weight: 600;
       
      }

      .deals p{
        width: 83%;
    
      }
  }

