@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap');

// *, *:after, *:before {
// 	box-sizing: border-box;
// }

.newcardFav {
	font-family: "Lexend", sans-serif;
	line-height: 1.5;
	min-height: 100vh;
	display: flex;
	// flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #393232;
	// background-color: #000;
	
}
.card-image{
	border-radius: 10px;
	overflow: hidden;
	// background-color: #000;
	height: 70.3%;
	width: 100%;
}

.card-image img {
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 10px;
	// margin-left: -15%;

}

.card-list {
	width: 100%;
	max-width: 80%;
	
	// margin-top: 20%;
	// display: flex;
	// flex-direction: row;
	//
	overflow-y: scroll;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 60% 60%;
	grid-auto-rows: 60%;
	// grid-gap: 25px;
	grid-row-gap: 46px;
	grid-column-gap: 35px;
	// align-items: center;
	// justify-content: center;
	// align-content: space-between;
	padding-top: 8%;
	padding-left: 10px;
	padding-right: 10px;
	// height: 100%;
	max-height: 55em;

}

.card3 {
	
	background-color: #FFF;
	box-shadow: 0 0 0 1px rgba(#000, .05), 0 20px 50px 0 rgba(#000, .1);
	border-radius: 15px;
	overflow: hidden;
	// padding: 1.25rem;
	padding: 1.25em 1.25em 0.8em ;
	position: relative;
	transition: .15s ease-in;
	// margin-left: 2%;
	cursor: pointer;
	&:hover, &:focus-within {
		box-shadow: 0 0 0 2px #2E2D4D, 0 10px 60px 0 rgba(#000, .1);
			transform: translatey(-5px);
	}
}


.card-header {
	margin-top: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	a {
		font-weight: 600;
		font-size: 1em;
		line-height: 1.25;
		padding-right: 1rem;
		text-decoration: none;
		color: inherit;
		will-change: transform;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}
	}
	
	
}

.icon-button {
	border: 0;
	background-color: #fff;
	border-radius: 50%;
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	font-size: 1.25rem;
	transition: .25s ease;
	box-shadow: 0 0 0 1px rgba(#000, .05), 0 3px 8px 0 rgba(#000, .15);
	z-index: 1;
	cursor: pointer;
	color: #565656;
	
	svg {
		width: 1em;
		height: 1em;
	}
	&:hover, &:focus {
		background-color: #EC4646;
		color: #FFF;
	}
}

.card-footer {
	margin-top: 1.25rem;
	border-top: 1px solid #ddd;
	padding-top: 1.25rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.card-meta {	
	display: flex;
	align-items: center;
	color: #787878;
	&:first-child:after {
		display: block;
		content: "";
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background-color: currentcolor;
		margin-left: .75rem;
		margin-right: .75rem;
	}
	svg {
		flex-shrink: 0;
		width: 1em;
		height: 1em;
		margin-right: .25em;
	}
}

@media screen and (max-width: 1000px) {
	.card-list {
		width: 100%;
		max-width: 100%;
		
		// margin-top: 20%;
		// display: flex;
		// flex-direction: row;
		//
		overflow-y: scroll;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		// grid-template-rows: 30%;
		grid-auto-rows: 60%;
		// grid-gap: 25px;
		grid-row-gap: 10px;
		grid-column-gap: 15px;
		// align-items: center;
		// justify-content: center;
		// align-content: space-between;
		padding-top: 5%;
		padding-left: 10px;
		padding-right: 10px;
		// height: 100%;
		max-height: 45em;
	
	}
	
}

@media screen and (max-width: 950px) {
	.card-list {
		width: 100%;
		max-width: 100%;
		
		// margin-top: 20%;
		// display: flex;
		// flex-direction: row;
		//
		overflow-y: scroll;
		display: grid;
		grid-template-columns: 1fr 1fr;
		// grid-template-rows: 30%;
		grid-auto-rows: 60%;
		// grid-gap: 25px;
		grid-row-gap: 10px;
		grid-column-gap: 35px;
		// align-items: center;
		// justify-content: center;
		// align-content: space-between;
		padding-top: 5%;
		padding-left: 10px;
		padding-right: 10px;
		// height: 100%;
		max-height: 45em;
	
	}

}

@media screen and (max-width: 700px) {
	.card-list {
		width: 100%;
		max-width: 100%;
		
		// margin-top: 20%;
		// display: flex;
		// flex-direction: row;
		//
		overflow-y: scroll;
		display: grid;
		grid-template-columns: 1fr 1fr;
		// grid-template-rows: 30%;
		grid-auto-rows: 60%;
		// grid-gap: 25px;
		grid-row-gap: 10px;
		grid-column-gap: 25px;
		// align-items: center;
		// justify-content: center;
		// align-content: space-between;
		padding-top: 5%;
		padding-left: 10px;
		padding-right: 10px;
		// height: 100%;
		max-height: 45em;
	
	}
}
@media screen and (max-width: 600px) {
	
	.card-list {
		width: 80%;
		max-width: 100%;
		
		// margin-top: 20%;
		// display: flex;
		// flex-direction: row;
		//
		overflow-y: scroll;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 60%, 60%;
		grid-auto-rows: 60%;
		// grid-gap: 25px;
		grid-row-gap: 10px;
		grid-column-gap: 50px;
		// align-items: center;
		// justify-content: center;
		// align-content: space-between;
		padding-top: 5%;
		// padding-left: 10px;
		// padding-right: 10px;
		padding-bottom: 10px;
		// height: 100%;
		max-height: 45em;
		margin-left: 10%;
		margin-top: 5%;
	
	}

	.card2{
		margin-top: 8%;
	}
  }