@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Source+Sans+Pro:wght@200&display=swap');
.jobBanner{
    background-image: url("https://static.vecteezy.com/system/resources/previews/001/213/015/non_2x/we-re-hiring-with-office-chair-and-vacant-sign-vector.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; /* Center the background image */
    color: white;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
    font-size: 1.7em;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
   
}

.jobIntroDiv{
    background-color: #0090BD;
    color: white;
    padding: 15px; 
    border-radius: 8px; 
}
.jobHero{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2%;
    background-color: #EDF2F8;
   
}
.jobHerotext{
    margin-top: 5%;
    width: 50%;
    font-family: 'Poppins';
    opacity: 1;
    border: 1px solid gray;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    margin-bottom: 3%;
    background-color: aliceblue;
}

.jobDescribeDetails{
    /* background-color: antiquewhite; */
}

.jobDescribeDetails2{
    /* background-color: #EBECF1; */
    text-align: justify;
    font-size: 1em;
    opacity: 0.9;
}

.jobDescribe{
    text-align: justify;
    padding: 10px;
    padding: 15px;
    /* font-size: 0.6em; */
}

.jobCategory{
    background-color: white;
    
    color: grey;
    font-size: 0.9em;
    padding: 15px; 
    border-radius: 8px; 
    list-style-type: none;
}

.customerSer{
    font-size: 1.6em;
    font-weight: 600;
    text-transform: capitalize;
    background-color: #FBCA15;
    /* opacity: 0.8; */
    color: black;
    margin-top: 2%;
    padding: 10px 30px 10px 15px;
    border-top-right-radius: 50px;
}

.customerRespon{
    font-size: 1em;
    font-weight: 600;
    width: 50%;
    text-transform: capitalize;
    background-color: aliceblue;
    /* opacity: 0.8; */
    color: black;
    margin-top: 2%;
    padding: 10px 30px 10px 15px;
    border-top-right-radius: 50px;
}

.customerRequire{
    font-size: 1em;
    font-weight: 600;
    width: 60%;
    text-transform: capitalize;
    background-color: aliceblue;
    /* opacity: 0.8; */
    color: black;
    margin-top: 2%;
    padding: 10px 30px 10px 15px;
    border-top-right-radius: 50px;
}

.customerCV{
    font-size: 1em;
    font-weight: 600;
    /* width: 60%; */
    /* text-transform: capitalize; */
    background-color: aliceblue;
    text-align: center;
    /* opacity: 0.8; */
    color: black;
    margin-top: 2%;
    padding: 10px 30px 10px 15px;
    /* border-top-right-radius: 50px; */
}

.salesRep{
    background-color: white;
    
    color: grey;
    font-size: 0.9em;
    padding: 15px; 
    border-radius: 8px; 
    list-style-type: none;
}

.salesRequ{
    font-size: 1em;
    font-weight: 600;
    width: 20%;
    text-transform: capitalize;
    background-color: aliceblue;
    /* opacity: 0.8; */
    color: black;
    margin-top: 2%;
    padding: 10px 30px 10px 15px;
    border-top-right-radius: 50px;
}

.salesLook{
    font-size: 1em;
    font-weight: 600;
    width: 30%;
    text-transform: capitalize;
    background-color: aliceblue;
    /* opacity: 0.8; */
    color: black;
    margin-top: 2%;
    padding: 10px 30px 10px 15px;
    border-top-right-radius: 50px;
}

.jobType{
    background-color: aliceblue;
    color: black;
    font-weight: 600;
    font-size: 1em;
    width: 50%;
    padding: 10px 30px 10px 15px;
    text-align: center;
    border-top-left-radius: 50px;
}

.listTick{
    font-size: 1.6em;
    padding-right: 8px;
    color: #0090BD;
    font-weight: 600;
}

.applySect{
    background-color: #0090BD;
    color: white;
    border-radius: 8px;
    padding: 15px;  
}

.jobPosition{
    font-size: 1.6em;
    font-weight: 600;
    text-transform: capitalize;
    background-color: #FBCA15;
    opacity: 0.8;
    margin-top: 2%;
    padding: 10px 30px 10px 15px;
    border-top-right-radius: 50px;
}

@media screen and (max-width: 1000px) {
    .jobHerotext{
        margin-top: 15%;
        width: 95%;
    }
    .jobHero{
        padding-top: 0%;
    }

}

@media screen and (max-width: 750px) {
    .jobHerotext{
        margin-top: 15%;
        width: 95%;
    }

    .jobHero{
        padding-top: 15%;
    }

    .jobPosition{
        font-size: 1em;
        
    }

    .jobDescribeDetails{
      font-size: 0.9em;
    }
    .jobDescribeDetails2{
        font-size: 0.8em;
    }
    .customerSer{
        font-size: 0.9em;
    }
    .customerRespon, .customerRequire{
        width: 83%;
        font-size: 0.8em;
        border-top-right-radius: 40px;
    }
    .salesRequ{
        width: 50%;
    }
    .salesLook{
        width: 60%;
    }
}
