@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.parent_book {
  width: 80%;
  height: 100vh auto;
  margin-top: 7%;
  margin-left: 10%;
  margin-right: 15%;
  padding: 1%;
  background-color: RGB(242, 242, 242);
}

.top_instruction {
  color: #2e2d4d;
  text-transform: capitalize;
  width: 70%;
  margin-left: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book_form_details {
  background: RGB(255, 255, 255);
  width: 40%;
  height: 50%;
  padding: 10px;
}

.parent_book_form {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2%;
}

.book_form_room_list {
  background: RGB(255, 255, 255);
  width: 35%;
  margin-left: 3%;
  padding: 1.5%;
  position: relative;
}

.book_form_input::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #0f0f0f;
  padding: 10px;
}

.book_form_room_list::after {
  content: " ";
  position: absolute;
  top: 0%;
  right: 0%;

  left: 0%;

  width: 100%;
  height: 35px;
  transform: rotate(180deg);
  background: linear-gradient(-45deg,
      transparent 33.33%,
      #f5f5f5 33.333%,
      #f5f5f5 66.667%,
      transparent 66.667%),
    linear-gradient(45deg,
      transparent 33.33%,
      #f5f5f5 33.333%,
      #f5f5f5 66.667%,
      transparent 66.667%);

  background-size: 30px 60px;
}

.checkincheckoutDate {
  background-color: aliceblue;
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

.checkinspan {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.checkinspan label {
  font-weight: 800;
}

.ckeckoutspan label {
  font-weight: 800;
}

.ckeckoutspan {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.inputField {
  width: 60%;
  height: auto;
  border-radius: 7px;
  border: 1px solid #3464B1;
  padding: 5px;
  margin-left: 0%;
}

.qty .removebtn {
  width: auto;
  border: none;

  width: 20%;
  outline: none;

  border-radius: 7px;
  color: #ebeef3;

  background-color: #2E2D4D;
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 600;
  font-family: 'Times New Roman', Times, serif;
}

.warning {
  background-color: #2e2d4d;
  border-radius: 5px;
  width: 80%;
  font-size: 0.8rem;
  padding: 10px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  text-transform: lowercase;
  /* margin-bottom: 100%; */
}

.book_hotel_img {
  width: 35%;
  height: 20%;
}

.book_hotel_img img {
  width: 100%;
  height: 100%;
}

.book_hotel__details {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4%;
  padding-bottom: 20px;
  border-bottom: 1px dashed gray;
}

.book_hotel__details h3 {
  text-align: justify;
  margin-left: 3%;
  margin-right: 3%;

  color: #2e2d4d;
}

.book_hotel__details p {
  margin-top: 7px;
  color: black;
  font-size: 1rem;
  font-weight: 300;
  font-family: "Times New Roman", Times, serif;
}

.book__form_ {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 10px;

  padding: 10px;
}

.book__form_ input {
  height: 2.5rem;
  width: 95%;
  border-radius: 5px;
  border: 1px solid RGB(189, 189, 189);
  padding-left: 10px;
  /* margin-left: -13%; */
}

.book__form_ label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: "Poppins", sans-serif;
  font-size: 0.7em;
}

.address label {
  display: flex;
  width: 80%;
  margin-left: 5%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* background-color: rebeccapurple; */
  font-family: "Poppins", sans-serif;
  font-size: 0.7em;
}

.required-field::after {
  content: "*";
  color: red;
  margin-left: 5px;
}

.book__form_ .first_row {
  width: 100%;
  height: 2.67rem;
  border-radius: 5px;
  border: 1px solid RGB(189, 189, 189);
  padding-left: 10px;
  font-family: "Poppins", sans-serif;
}

.checkbox {
  display: flex;
  align-items: center;
}

.text__area {
  width: 80%;
  margin-left: 0%;
  margin-bottom: 5%;
  border: 1px solid RGB(189, 189, 189);
  padding: 5%;
  border-radius: 5px;
}

.discount {
  text-transform: capitalize;
  text-decoration: none;
  color: red;
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
  margin-right: 60%;
  border: none;
  height: 40px;
  background: none;
  cursor: pointer;
}

.discount:hover {
  text-decoration: underline;
}

.discount_hiden_div {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 93%;
  background: RGB(240, 245, 249);
  padding: 14px;
  border-radius: 5px;
}

.discount_hiden_div .discountMessage {
  font-family: "Poppins", sans-serif;
  font-size: 0.8em;
}

.discount_hiden_div input {
  height: 40px;
  width: 80%;
  border-radius: 5px;
  border: none;
  margin-bottom: 4%;
}

.discount_hiden_div button {
  width: 80%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background: #2e2d4d;
  color: white;
  cursor: pointer;
}

.discount_hiden_div .disableBtn2 {
  background-color: gray;
  cursor: not-allowed;
}

/* .discount_hiden_div .notDisableBtn{
    background-color: gray;
    cursor: not-allowed;
} */

.pay_hotel_button {
  padding: 10px;
  width: 40%;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #2e2d4d;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 7%;
}

.pay_hotel_button2 {
  margin-left: 5px;
  cursor: pointer;
  background-color: #2e2d4d;
  color: white;
  width: 40%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pay_hotel_button3 {
  background-color: #57539b;

  margin-left: 5px;
  color: white;
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 10px;

  border-radius: 5px;

  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;

  /* margin-top: 7%; */
}

.pay_hotel_button3:hover {
  cursor: not-allowed;
}

.bookingIcon {
  position: absolute;
  margin-left: 20%;
  color: white;
  margin-top: 2.5%;
  font-size: 1.3rem;
}

.pay_hotel_button2:hover {
  background: #494970;
  cursor: pointer;
  color: #f5f5f5;
  border: none;
}

.pay_hotel_button:hover {
  background: #494970;
  cursor: pointer;
  color: #f5f5f5;
  border: none;
}

.payOption {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0 2% 0;
}

.privacy_text {
  margin-left: 10px;
}

.privacy_text p {
  background-color: RGB(229, 247, 255);
  border-radius: 5px;
  width: 90%;
  font-size: 0.8rem;
  padding: 10px;
  text-align: justify;
  color: RGB(73, 200, 254);
}

.book_hotel__details ul {
  display: flex;
  align-items: center;
}

.book_hotel__details ul li {
  list-style: none;
  margin-left: 20px;
  margin-right: 40px;
}

.book__hotel_info {
  width: 100%;
}

.book__hotel_info h4 {
  display: flex;
  flex-direction: row;
  text-align: justify;
  /* background-color: RGB(20,172,242); */
  width: 100%;
  height: 50px;
  font-size: 0.98rem;
  font-family: "Times New Roman", Times, serif;
  margin-bottom: 4px;
  color: #0f0f0f;
}

.book__hotel_info h4 p {
  margin-top: 1px;
  margin-left: 30%;
  font-size: 1rem;
}

.book__hotel_info h4 p i {
  font-size: 0.8rem;
  font-weight: 100;
  color: RGB(159, 167, 173);
}

.book__hotel_info h4 a {
  width: 50%;
}

.book__hotel_info_button {
  margin-left: 70%;
}

.book__hotel_info_button button {
  background-color: white;
  border: 1px solid RGB(0, 192, 255);
  color: RGB(0, 192, 255);
  border-radius: 5px;
  padding: 10px;
}

.book_hotel__details input {
  font-family: "Poppins", sans-serif;
  padding: 5px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid grey;
}

.payAtHotelWarning {
  font-family: "Poppins", sans-serif;
  font-size: 0.6em;
  text-align: justify;
  background-color: #2e2d4d;
  color: #ced82a;
  padding: 18px;
  margin-left: 10px;
  border-radius: 5px;
  width: 90%;
}

.important {
  color: red;
  font-size: 1.4em;
}

.second_field_book {
  width: 80%;
}

.book_date_field {
  width: 100%;
}

.field_lab {
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
}

.lab {
  width: 50%;
}

.book_hotel__details2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-bottom: 1px dashed gray;
}

.book_view_more {
  margin-top: 7%;
  width: 95%;
  padding: 10px;
  background-color: #e5f7ff;
  border: none;
  color: #2e2d4d;
  font-family: "Poppins", sans-serif;
  font-size: 0.6em;
}

.book_view_more .spanDis {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.book_total_det {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2e2d4d;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-top: 4%; */
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.qty {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qty i {
  margin-right: 10%;
}

.days {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.days i {
  margin-right: 10%;
}

.parent_gender_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -5%;
}

.parent_gender_div2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 3%;
}

.gender_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
}

.show2 {
  display: none;
}

.pay_hotel_button3 {
  width: auto;
  border: none;
  background: #2e2d4d;
  /* background: RGB(20,172,242); */
  color: white;
  font-size: 1em;
}

.pay_hotel_button4 {
  width: auto;
  border: none;
  background: RGB(20, 172, 242);
  color: white;
  margin-top: 4%;
}

.notAllow {
  cursor: not-allowed;
  padding: 10px;
  width: 40%;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #2e2d4d;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;

  margin-top: 7%;
}

.pay_hotel_button1 {
  width: auto;
  border: none;
  background: #2e2d4d;
  color: white;
  margin-top: 4%;
  font-size: 1em;
  cursor: pointer;
}

.tax-container {
  background-color: #f8f8f8;
  padding: 3% 3% 3% 3%;
}

.tax-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7em;
  font-family: "Poppins", sans-serif;
  color: #353535;
  /* background-color: yellowgreen; */
}

.taxAmount {
  /* background-color: #CED82A; */
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}

.taxindiviAmount {
  /* background-color: red; */
  width: 100%;
  /* float: left; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.book_view_more_with_tax {
  /* margin-top: 4%; */
  /* width: 95%; */
  padding: 2px 5px 2px 5px;
  background-color: #e5f7ff;
  border: none;
  color: #2e2d4d;
  font-family: "Poppins", sans-serif;
  font-size: 0.8em;
}

.subTotal {
  margin-top: 2%;
}

.book_view_more_with_tax .spanDis {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.choseOpt {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.discountmass {
  color: red;
  font-size: 0.6em;
  font-family: "Poppins", sans-serif;
  text-align: justify;
}

.checkRoomAvailableDiv {
  /* background-color: #E5F7FF; */
  width: 100%;
  margin-top: 5%;
  margin-bottom: 8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logintoBook {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #2e2d4d;
  color: white;
  border: 2px solid #2e2d4d;
  cursor: pointer;
  font-family: "Poppins", sans-serif;

}

.continueSelection {
  padding: 7px;
  border-radius: 5px;
  background-color: #E5F7FF;
  color: black;
  border: 2px solid #E5F7FF;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.checkRoomAvailableDiv button {
  padding: 7px;
  border-radius: 5px;
  background-color: rgb(8, 155, 106);
  color: white;
  border: 1px solid rgb(8, 155, 106);
  cursor: pointer;
}

.noQtyDiv {
  background-color: #e5f7ff;
}

.noQtyDiv p {
  padding: 5%;
  color: brown;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.checkBoxes {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  font-size: 1em;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  /* margin-left: 10%; */
  background-color: #F0F8FF;

}

.selectBreak {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: red;
  width: 100%;
  cursor: pointer;
  /* background-color: aqua; */
  /* font-size: 0.9em; */

}

.mainBox {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 25px;
  width: 25px;
}

@media screen and (max-width: 1400px) {
  .bookingIcon {
    display: none;
  }

  .payAtHotelWarning {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) {
  .pay_at_hotel {
    font-size: 1.2em;
  }

  .pay_hotel_button2 {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 1100px) {
  .pay_at_hotel {
    font-size: 1.2em;
  }

  .pay_hotel_button2 {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 1000px) {
  .parent_book {
    width: 93vw;
    margin-left: 0;
    /* background-color: rebeccapurple; */
  }

  .top_instruction {
    margin-top: 19%;
  }

  .parent_book_form {
    display: flex;
    flex-direction: column;
  }

  .book_form_room_list {
    width: 90%;
  }

  .book_form_details {
    width: 89%;
    margin-right: 0;
    margin-left: 3%;
  }

  /* .payAtHotelWarning{
        width: 80%;
    } */
}

@media screen and (max-width: 850px) {
  .bookingIcon {
    position: absolute;
    margin-left: 15%;
    color: white;
    margin-top: 5.5%;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 750px) {
  .top_instruction {
    width: 90%;
    font-family: monospace;
    font-size: 1.1em;
    /* background-color: red; */
    text-align: justify;
    margin-left: 0;
    padding: 10px;
    margin-top: 22%;
  }

  .pay_at_hotel {
    font-size: 1.2em;
  }

  .pay_hotel_button2 {
    font-size: 1.2em;
  }

  .discount {
    /* background-color: rebeccapurple; */
    width: 50%;
  }

  .book_total_det h2 {
    /* background-color: #E5F7FF; */
    font-size: 1em;
  }

  .checkBoxes {

    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 500px) {
  .bookingIcon {
    display: none;
  }

  .warning {
    width: 80%;
    font-size: 0.6em;
    padding: 10px;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    text-transform: lowercase;
    /* margin-bottom: 100%; */
  }

  .pay_hotel_button2 {
    font-size: 1em;
  }

  .pay_at_hotel {
    font-size: 1em;
  }
}

@media screen and (max-width: 400px) {
  .warning {
    font-size: 0.5em;
  }

  .pay_hotel_button2 {
    font-size: 1em;
  }

  .pay_at_hotel {
    font-size: 1em;
  }
}

@media screen and (max-width: 350px) {
  .pay_at_hotel {
    font-size: 1em;
  }

  .pay_hotel_button2 {
    font-size: 1em;
  }
}