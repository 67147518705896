/* Import font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

/* Custom properties */
:root {
  --light-color: #fff;
  --light-color-alt: #afb6cd;
  --primary-background-color: #131417;
  --secondary-background-color: #252830;
  --hover-light-color: var(--light-color);
  --hover-dark-color: var(--primary-background-color);
  --gradient-color: linear-gradient(
    115deg,
    #4fcf70,
    #fad648,
    #a767e5,
    #12bcfe,
    #44ce7b
  );
  --transparent-light-color: rgba(255, 255, 255, 0.05);
  --transparent-dark-color: rgba(0, 0, 0, 0.75);
  --font-family: "Poppins", sans-serif;
  --font-size-xsm: 1.2rem;
  --font-size-sm: 1.6rem;
  --font-size-md: 2.4rem;
  --font-size-lg: 3rem;
  --font-size-xl: 4rem;
  --gap: 2rem;
  --margin-sm: 2rem;
  --margin-md: 3rem;
  --item-min-height-sm: 20rem;
  --item-min-height-md: 30rem;
}

.mainblogPostSection {
  margin-top: 7%;
  margin-bottom: 7%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.parentBlogsect {
  width: 90%;
  /* height: 80%; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  /* background-color: gray; */
  font-family: roboto;
}

.secondGridSection {
  width: 35%;
  background-color: #f0f8ff;
  padding: 15px;
  /* height: 900px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.parentCardPost {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 65%;
  background-color: #ededed;
  padding: 40px 10px 40px 10px;
  /* gap: 5px;
     */
}

.firstGridSection {
  width: 340px;
  min-height: 565px;
  max-height: 565px;
  /* margin: auto; */
  border-radius: 4px;
  background: white;
  box-shadow: 0 5px 15px #0004;
  position: relative;
}

.firstSectimg_1 img {
  width: 100%;
  height: 235px;
  border-radius: 4px 4px 0 0;
}

.contentContainer {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.contentContainer h1 {
  font-size: 24px;
  text-transform: capitalize;
  align-items: start;
  /* background-color: #12bcfe; */
}

.contentContainer h2 {
  display: inline-block;
  background-color: #3f3e5c;
  color: white;
  font-size: 10px;
  padding: 5px 16px;
  margin: 0;
  align-items: start;
  letter-spacing: 0.08rem;
}

.contentContainer p {
  color: #333;
  font-size: 12px;
  display: -webkit-box;
  line-height: 1.3rem;
  letter-spacing: 0.02rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-align: start;
}

.blogpostfooter {
  position: relative;
  display: block;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  /* margin-bottom: 100%; */
}

.blogpostfooter a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.blogpostfooter .blogreadMore {
  position: absolute;
  /* margin-bottom: 100px; */
  /* top: 670px; */
  background-color: #3f3e5c;
  /* left: 10px; */
  color: white;
  padding: 6px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.blogpostfooter .blogreadMore:active {
  box-shadow: 0 2px 6px #717ad9;
}

@media screen and (max-width: 750px) {
  .parentBlogsect {
    flex-direction: column;
  }
  .parentCardPost {
    width: 100%;
  }
  .secondGridSection {
    width: 100%;
  }
  .mainblogPostSection{
    margin-top: 20%;
  }
}
