@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.textMainForm {
    background-color: #FFFFFF;
    text-align: justify;
    border-radius: 15px;
    width: 95%;
    overflow-y: scroll;
    padding: 10px 20px 20px 20px;
}

.textMainForm .firstpara {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
    line-height: 27px;
}

.textMainForm .tiptopIndicate {
    font-size: 1.2em;
    font-weight: 600;
}

.textMainForm .agremmentNameSec {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
    line-height: 30px;
}

.agremmentNameSec .inputName {
    width: 30%;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.textMainForm .thirdPara {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
    line-height: 27px;
}

.listPara {
    /* background-color: aquamarine; */
    /* text-align: justify; */
}

.listPara ol {
    /* background-color: rebeccapurple;
    text-align: justify; */
    margin: 0;
    padding-left: 25px;
}

.listPara ol li {
    font-family: 'Poppins', sans-serif;
    margin-top: 5px;
    font-size: 0.9em;
}

.listPara ol .thirdItem {
    /* line-height: 34px; */
}

.checkOpt {
    margin-top: 5px;
}

.thirdItem .paymentDetails {
    width: 75%;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.thirdItem .paymentDetails2 {
    width: 75%;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: red 2px solid;
}

.thirdItem .paymentDetails3 {
    width: 75%;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: red 2px solid;
}

.thirdItem .paymentDetails4 {
    width: 75%;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: red 2px solid;
}

.spanInputpayment {
    /* background-color: aquamarine; */
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.listPara .lastParaList {
    margin-top: 15px;
}

.textMainForm .readAgree {
    margin-top: 10px;
}

.textMainForm .saveBtnDiv {
    /* background-color: rebeccapurple; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.buttonDivSave {
    width: 50%;
    /* background-color: #2E2D4D; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.readMore a {
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    color: black;
    
}

.textMainForm .saveBtnDiv button {
    padding: 5px;
    width: 25%;
    background-color: #2E2D4D;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.textMainForm .saveBtnDiv .cancelAgreement {
    padding: 5px;
    width: 25%;
    background-color: #E0E3E2;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 7px;
}

.textMainForm .saveBtnDiv button .saveIcon {
    /* margin-right: 5px; */
    font-size: 2em;
}

.readAgree .spanSign {
    /* background-color: #2E2D4D; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.readAgree .spanSign .signPara {
    /* background-color: aqua; */

    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.readAgree .spanSign .signPara .dateInput {
    width: 80%;

    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.readAgree .spanSign .signPara .dateInput2 {
    width: 80%;

    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: red 2px solid;
}

.contactInfoDivagree {
    /* background-color: #2E2D4D; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* align-items: center; */
    /* justify-content: space-between; */
}

.firstcontactInfopart {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    /* background-color: red; */
    width: 50%;
}

.secondcontactInfopart {
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    width: 48%;
}

.contactInputDiv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    /* background-color: aqua; */
    width: 100%;
}

.contactInputDiv label {
    /* background-color: red; */
    width: 39%;
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.contactInputDivSecon {
    /* background-color: rebeccapurple; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    /* background-color: aqua; */
    width: 100%;
}

.contactInputDivSecon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    /* background-color: aqua; */
    width: 100%;
}

.contactInfoField {
    width: 56%;
    margin-top: 5px;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.contactInfoField12 {
    width: 56%;
    margin-top: 5px;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid red;
}

.contactInfoField3 {
    width: 56%;
    margin-top: 5px;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid red;
}

.contactInfoField4 {
    width: 56%;
    margin-top: 5px;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid red;
}

.contactInfoField2 {
    width: 56%;
    margin-top: 5px;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;

}

.contactInfoField21 {
    width: 56%;
    margin-top: 5px;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid red;
}

.contactInfoField22 {
    width: 56%;
    margin-top: 5px;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid red;
}

.contactInfoField23 {
    width: 56%;
    margin-top: 5px;
    padding: 5px 5px 5px 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid red;
}

.checkBoxde {
    border: none
}

.checkReadInput {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: center; */
}

.readAgree input[type=checkbox] {
    height: 20px;
    width: 20px;
    border-radius: 5px;
    border: 2px solid red;
}

.error-message {
    color: red;
    font-size: 0.8em;
}

@media screen and (max-width: 750px) {
    .textMainForm {
        padding: 10px 20px 20px 8px;
    }

    .spanInputpayment {
        /* background-color: aquamarine; */
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
    }

    .thirdItem .paymentDetails,
    .thirdItem .paymentDetails2,
    .thirdItem .paymentDetails3,
    .thirdItem .paymentDetails4 {
        width: 96%;
    }

    .contactInfoDivagree {
        /* background-color: #2E2D4D; */
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; */
        /* align-items: center; */
        /* justify-content: space-between; */
    }

    .firstcontactInfopart {
        /* background-color: aquamarine; */
        width: 96%;
    }

    .contactInputDiv {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;

    }

    .contactInputDiv label {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .contactInfoField {
        width: 96%;
    }

    .secondcontactInfopart {
        width: 96%;
    }

    .contactInfoField2 {
        width: 96%;
    }

    .contactInfoField12,
    .contactInfoField22,
    .contactInfoField23,
    .contactInfoField24,
    .contactInfoField3,
    .contactInfoField4,
    .contactInfoField21 {
        width: 96%;
    }

    .readAgree {
        /* background-color: #2E2D4D; */
    }

    .readAgree .spanSign {
        /* background-color: #2E2D4D; */
        display: flex;
        flex-direction: column;
        /* flex-direction: row;
            align-items: center;
            justify-content: space-evenly; */
    }

    .readAgree .spanSign .signPara {
        display: flex;
        flex-direction: column;
        /* background-color: aquamarine; */
        width: 100%;
        align-items: flex-start;
    }

    .checkReadInput {
        /* background-color: aquamarine; */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        font-size: 0.8em;
    }

    .saveBtnDiv {
        /* background-color: #5d5c70; */
    }

    .textMainForm .saveBtnDiv button {
        padding: 5px;
        width: 30%;
        font-size: 0.9em;
    }
}