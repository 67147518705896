.hiringBtn {
    position: fixed;
    top: 3%;
    left: 30%;
    z-index: 999;
    background: linear-gradient(#CED82A, #0090BD);

    color: white;
    /* border-radius: 50px; */
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    animation: blink 2s infinite alternate, bounce 3s infinite alternate;
    /* Combined animations */
}

.hiringBtn::before {
    content: '';
    position: absolute;
    top: 90%;
    left: 37%;
    transform: rotate(180deg);
    margin-left: -10px;
    border-width: 30px;
    border-style: solid;
    border-color: transparent transparent #0090BD transparent;

}

@keyframes blink {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

@keyframes bounce {

    0%,
    50% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-25px);
    }
}

.hiringTex {
    color: black;
    font-size: 1.5em;
    font-weight: 600;
    text-decoration: underline;
}

.mainText {
    text-align: justify;
}

@media screen and (max-width: 850px) {
    .hiringBtn {
        position: absolute;
        top: 17%;
        left: 43%;
        height: 35px;
        z-index: 9;
        width: 100px;
        padding: 0 5px 0 5px;
    }
}

.hiringTex {
    color: black;
    font-size: 1.2em;
    font-weight: 600;
    text-decoration: underline;
}
.hiringBtn::before {
    content: '';
    position: absolute;
    top: 90%;
    left: 37%;
    transform: rotate(180deg);
    margin-left: -10px;
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent #0090BD transparent;

}
@media screen and (max-width: 750px) {
    .hiringBtn {
        position: absolute;
        top: 17%;
        left: 43%;
        height: 35px;
        z-index: 9;
        width: 100px;
        padding: 0 5px 0 5px;
    }

    .hiringTex {
        color: black;
        font-size: 1.1em;
        font-weight: 600;
        text-decoration: underline;
    }
}