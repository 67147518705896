@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
/* *{
    font-family: 'Poppins', sans-serif;
} */
.main__parent_profil{
    background-image: url("../../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
  
    background-size: cover;
   
    width: 100%;
}
.profile__side{
    position: relative;
    /* background-color: red; */
    height: 100vh;   
    margin-top: -5%;
    /* padding-top: 5%; */
}
.parent__profile{
    padding-top: 7%;
    margin-top: 10%;
    margin-bottom: 5%;
    width: 80%;
    margin-left: 22%;
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.profileImg{
    background-color: #F6F6FD;
    width: 30%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
}
.profileImg img{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}
.user__det{
    /* background-color: red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;


}

.userDetails{
    border-bottom: 2px solid #F6F6FD;
    /* padding-bottom: 3px; */
    width: 60%;
    margin-left: 5%;
    text-align: justify;
color:#040404;
/* background-color: red; */
}

.edit__btn{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 5%;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #9E9DE7;
    background-color: white;
    color: #4644D1;
    font-family: sans-serif;
    width: 15%;
    /* margin-top: 3%; */
    margin-bottom: 5px;
}

.edit__btn:hover{
    background-color: #2E2D4D;
    color: whitesmoke;
    cursor: pointer;
    border: none;
}

.edit__info{
    /* background-color: rebeccapurple; */
    height: 1.9em;
    /* padding-top: 1%; */
    /* padding-bottom: 1%; */
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5%;
    /* margin-top: 2%; */
    /* justify-content: space-evenly; */
}
.edit__info input {
    margin-left: 5%;
    width: 40%;
    height: 100%;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    border: 1px solid #9E9DE7;
    background-color: #F6F6FD;
}
.edit__info .label2{
    
    margin-left: 8%;
}
.edit__info .label3{
    margin-left: 8.5%;
}
.edit__info .label4{
    margin-left: 6.7%;
    
}
.edit__info .label5{
    margin-left: 6.7%;
    
}
.update__btn{
    display: flex;
    /* justify-content: space-evenly; */
    width: 100%;
}
.label{
    text-align: justify;
}
.lab{
    font-size: 0.7em;
    font-family: sans-serif;
    color: #9E9DE7;
    /* margin-bottom: 2%; */
}
.user__det .error{
    color: red;
    font-family: sans-serif;
    font-size: 0.7em;
}
@media screen and (max-width: 1650px) {
    .user__det{}
    .profileImg{
        width: 46%;
    }
    .edit__btn{
        width: 30%;
    }
    .update__btn{
        display: flex;
        /* justify-content: space-evenly; */
        width: 62%;
        /* background-color: #040404; */
    }
}


@media screen and (max-width: 1000px){
    
    
    .parent__profile{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
        /* background-color: red; */
        margin-left: 15%;
        width: 90%;
        /* margin-left: 0%; */
        background-color: #F6F6FD;
       box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    }
    .user__det{
        margin-top: 2%;
        /* background-color: rebeccapurple; */
        width: 100%;
       
        /* background-color: red; */
    }
    .edit__info{
        /* background-color: red; */
        margin-top: 6%;
        width: 100%;
        /* margin-left: 17%; */
    }
    .userDetails{
        width:90%
        /* margin-left: 17%; */
    }
    .edit__info input{
        /* background-color: #040404; */
        width: 100%;
    }
    .profileImg{
        border-radius: 100px;
    }
    .profileImg img{
        border-radius: 100px;

    }
    .edit__btn{
        margin-top: 7%;
        width: 40%;
    }
    .edit__btn2{
        width: 20%;
        /* margin-left: 23%; */
    }
    .edit__info .label2{
    
        margin-left: 7%;
    }
    .edit__info .label3{
        margin-left: 8.4%;
    }
    .edit__info .label4{
        margin-left: 2%;
    }
    .edit__info .label5{
        margin-left: 2%;
        
    }
    .update__btn{
        /* background-color: royalblue; */
        width: 80%;
        /* margin-left: 5%; */
        margin-top: 5%;
    }
}

@media screen and (max-width: 800px) {
    .main__parent_profil{
        /* background-color: #2E2D4D; */
        /* width: 90%; */
    }
}



@media screen and (max-width: 600px){
    .edit__info{
        /* background-color: red; */
        width: 100%;
        /* margin-left: 26%; */
    }
    .edit__info input{
        /* background-color: #040404; */
        width: 65%;
    }
    .parent__profile{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 15%;
        /* background-color: red; */
        width: 83%;
        /* margin-left: 0%; */
    }
    .user__det{
        margin-top: 5%;
        /* background-color: rebeccapurple; */
        width: 100%;
        /* padding-left: 15%; */
    }
    .profileImg{
        /* margin-left: 0%; */
        border-radius: 100px;
    }
    .profileImg img{
        border-radius: 100px;

    }
    .edit__btn{
        width: 40%;
       
    }
    .edit__btn2{
        /* margin-left: 23%; */
    }
    .update__btn{
        /* background-color: royalblue; */
        /* z-index: 2; */
        width: 100%;
        margin-left: 0%;

        /* padding-left: 40px; */
        /* margin-left: 10%; */
    }
    .userDetails{
        /* margin-left: 22%; */
    }
}
@media screen and (max-width: 700px){
    .update__btn{
        /* background-color: royalblue; */
        width: 100%;
        /* margin-left: 15%; */
    }
    .edit__btn{
        width: 40%;
        font-size: 0.65em;
    }
    .edit__btn2{
        /* margin-left: 23%; */
    }
}
@media screen and (max-width: 400px){
    .update__btn{
        /* background-color: royalblue; */
        width: 100%;
        /* margin-left: 15%; */
        /* padding-left: 4%; */
    }
    .edit__btn{
        width: 45%;
        font-size: 0.65em;
    }
    .edit__info{
        /* background-color: red; */
        width: 100%;
        /* margin-left: 21%; */
    }
   
    .userDetails{
        width: 90%;
        /* margin-left: 22%; */
    }
    .edit__btn2{
        /* margin-left: 23%; */
    }
}
