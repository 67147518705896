.ptext {
    width: 50%;
    margin-left: 23%;
    padding: 5%;
    color: white;
}
.ptext p{
    text-align: justify;
}

.aboutTiptopgo{
      
    background-image: url("../../../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
    /* background-position: ; */
    background-size: cover;
    height: 100vh auto;
    background-attachment: fixed;
}