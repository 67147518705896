@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.parent_hotels{
    /* margin-left: 15%;
    margin-right: 15%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   margin-top: 30px;
    width: 100%;
    /* background-color: rebeccapurple; */
    
    height: auto 100vh  ;
}

/* .hotelListdiv__{
    background-color: aquamarine;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */

.secondparent_hotels{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   margin-top: 6.5%;
    width: 100%;
    /* background-color: rebeccapurple; */
    
    height: auto 100vh  ;
}

.displayNone{
    display: none;
}

.mobileFiterOpt{
    display: none;
}
.mobileFiterOpt button{
    border: none;
    padding: 10px 20px 10px 20px;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-family: 'Poppins', sans-serif;
}
.fixed {
    position: fixed;
    top: -0.9%;
    /* background: whitesmoke; */
    height: 23vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
   margin-top: 80px;
   /* border-bottom: 1px solid gray; */
  }

.parent_individual_hotel{
    display: flex;
    flex-direction: row;
    border-top: 10px solid #2E2D4D;
    /* align-items: center; */
    justify-content: space-evenly;
    padding-top: 10px;
    width: 70%;
}

.individual_main_div{
   
    width: 100%;
}
.hotels_category{
    display: flex;
    flex-direction: row;
    width: 98%; 
    padding: 1% 1% 1% 1%; 
    /* background-color: #CED82A;  */
}

.mobileCategory{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 98%; 
    padding: 1% 1% 1% 1%; 
    /* background-color: #2E2D4D; */
   
}
.mobileCategory li{
    width: 100%;
}

.mobileCategory .filtCat{
    /* background-color: #CED82A; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25px;
    padding-bottom: 25px;
}

.mobileCategory .activeCategory{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25px;
    padding-bottom: 25px;
    color: whitesmoke;
}


.hotels_category li{
    width: 100%;
}
.no_hotel{
font-size: 2em;
    width: 65%;
    text-align: justify;
    text-transform: capitalize;
    color: #2E2D4D;
    

}
.action{
    width: 65%;
    text-align: justify;
    text-transform: capitalize;
    
}
.hotels_category li{
    list-style: none;
    border: 1px solid gray;
    padding: 10px;
    cursor: pointer;
    /* color: #2E2D4D; */
}

.mobileCategory li{
    list-style: none;
    color: #2E2D4D;
    border-bottom: 1px solid rgb(175, 164, 164);
    padding: 10px;
    cursor: pointer;
   
}
.activeCategory{
    background-color:#2E2D4D;
    color: white;
    border: none;
    display: flex;
    align-items: center;  
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 400;
}

.filtCat{
    display: flex;
    align-items: center;  
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 400;
}

.upperDiv{
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    /* background-color: #CED82A; */
    width: 70%;
}
.hotels_list_header{
    /* background-color: #2E2D4D; */
    width: 70%;
}

.subscribeDiv{
    background-color: red;
    opacity: 0.6;
    /* height: 50%; */
    width: 20% auto;
    padding: 1%;
    margin-bottom: 1%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.subscribeDiv p{
    color: whitesmoke;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-size: 0.9em;
    font-weight: 500;
}

.subscribeDiv .inputDiv .subEmailInput{
    height: 32px;
    /* border-radius: 5px; */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: none;
}
.inputDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.subscribeDiv .inputDiv .subBtn{
    /* height: 32px; */
    width: 30%;
    padding: 8px;
    /* border-radius: 5px; */
    border: 1px solid red;
    background-color: rgb(66, 4, 4);
    color: whitesmoke;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8em;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
   
}

.subscribeDiv .error{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8em;
}

.subscribeDiv .inputDiv .subnoticeMSG{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 0.65em;
    text-align: center;
}

.hotels_text_header{
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    width: 70%;
}
.indiv_hotel2{
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-auto-rows: 100% auto;
    /* border: 1px solid grey; */
    border-radius: 5px;
   margin-bottom: 3%;
   background-color: whitesmoke;
    width: 100%;
    height: 20% auto ;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    transition: transform 0.3s ease-in-out; 
}

.indiv_hotel2:hover{
    /* border: 1px solid grey; */
    transform: translateY(-5px);
}
.indiv_hotel_img{
    max-height: 290px;
    min-height: 280px;
    width: 260px;
    height: 100%;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    
    
}
.indiv_hotel_img img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.hotel_name{
    margin-left: 0;
    padding: 0 0 0 2px;
    margin-top: 0%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.2em;
    text-transform: capitalize;
    color: #2E2D4D;
    font-size: 1.3em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    /* background-color: #CED82A;  */
}
.nameDiv{
    background-color: aliceblue;
    /* text-align: justify; */
    font-size: 0.8em;
    text-transform: capitalize;
}
.hotel_name .proptypeindication{
    font-family: 'Poppins', sans-serif;
    font-size: 0.4em;
    font-weight: 400;
    background-color: rebeccapurple;
    color: whitesmoke;
    padding: 3px 10px 3px 10px;
    margin-bottom: 1%;
    border-radius: 2px;
}
.priceDiv .tagPrice{
    font-family: 'Poppins', sans-serif;
}

@keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  
  .priceDiv .tagPrice {
    animation: bounce 2s infinite;
  }

  .priceDiv .tagPrice2{
    font-family: 'Poppins', sans-serif;
  }

.hotel_amout{
    width: 32%;
    /* background-color: #626180; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* align-items: center; */
}
.favAvg{
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
   
    padding: 0 20%;
}
.hotel_amout .avv-nig{
    color: rgb(119, 107, 107);
    font-size: 0.4em;
    margin-bottom: 3%;
    margin-top: 3%;
    font-family: 'Poppins', sans-serif;
    
}
.hotel_amout .avv-nig2{
    color: rgb(196, 17, 17);
    font-size: 0.45em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50px;
    margin-bottom: 3%;
    margin-top: 3%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     transition: transform 0.3s ease-in-out; 
}

.hotel_amout .avv-nig2:hover{
    background-color: #2E2D4D;
    color: #CED82A;
     transform: translateY(-5px);
}

.hotel_address{
    display: flex;
    justify-content: flex-start;
    align-content: center;
    /* margin-top: -5%; */
    /* margin-left: 1%; */
    
    width: 95%;
    text-align: justify;
    /* padding: 2px 0 2px 0; */
    margin: 0.5% 0 1% 1%;
    /* background-color: antiquewhite; */
}

.hotel_address .locationDiv__{
    color: RGB(0,174,255);
    font-family: 'Poppins', sans-serif;
    display: flex;
    /* justify-content: flex-start; */
    /* align-items: center; */
    text-align: center;
    font-size: 0.7rem;
    /* background-color: #2E2D4D; */
    padding: 0 2px 0 0;
    width: 17%;
    border-right: 2px solid #2E2D4D;
}
.hotel_address .addressDiv__{
    color: black;
    font-size: 0.6em;
    padding-top: 2px;
    margin-left: 3px;
    font-family: 'Poppins', sans-serif;
    /* background-color: #2E2D4D; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    
}

.addressDiv__ .iconDivHolder{
    background-color: wheat;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.locationIcon{
    color: red;   
}

.locationDivaddress{
    margin-left: 7px;
}
.review{
    display: flex;
    flex-direction: row;
    /* margin-top: -0.6%; */
    width: 100%;
    height: 10% auto;
   
    /* background-color: antiquewhite; */
    
}
.review i{

    max-height: 5% ;
    overflow: hidden;
    display:block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 600px;
    font-size: .7em;
   background-color: #2E2D4D;
   color: white;
   padding-right: 10px;
   padding-left: 10px;
   padding-top: 5px;
   padding-bottom: 5px;
   text-transform: capitalize;
   border-radius: 5px;
    /* margin-top: 5%; */
    text-align: justify;
}

.newReview i{
    background-color: aquamarine;
}
.review span{
    width: 25% ;
   height: 100% auto;
    color: white;
 
    margin-top: 2%
    
}
.review span div{
    background: #2E2D4D;
    width: 70%;     
   text-align: center;
   margin-left: 15%;
    font-size: 0.9em;
    font-weight: 700;
}

.review span p{
    color: black;
    font-size: 0.7em;
    margin-top: 1.5%;
}

.indiv_hotel_text ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-around; */
    /* padding: 0.7%; */
    width: 100% auto;
    margin-top: 2.2%;
    /* background-color: rebeccapurple; */
}

.indiv_hotel_text ul li{
    list-style: none;
    /* margin-left: 20px; */
    /* margin-right: 8px; */
    font-size: 0.7em;
    text-transform:capitalize ;
    
    
}

.book{
    /* margin-left: 60%; */
}

.book button{
    border: none;
    padding: 10px;
    width: 105px;
    color: white;
    background: #2E2D4D;
    border-radius: 4px;
    cursor: pointer;
}
.icon2{
   margin-left: 10px;
   font-size: 20px;
}
.Hotel_amenityicon{
    /* width: 100%; */
    /* background-color: rgb(94, 44, 44);  */
    color: #2E2D4D;
    font-size: 0.8em;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    padding: 0 7px 0 4px;
    /* margin-left: -1%; */
    /* background-color: #CED82A;
}
.

.amenity{
    margin-right: 5px;
    border-right: #2E2D4D solid 2px;
    padding-right: 5px;
    font-size: 1em;
    font-weight: 600;
    font-family: sans-serif;
    /* background-color: #CED82A; */
}

.amenity-icon{
    font-size: 1.5em;
}

.schBar{
    background: whitesmoke;
    height: 23vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 10px;
   margin-top: 80px;
   border-bottom: 1px solid gray;
}

.filter_section{
  margin-left: -25%;
  /* background-color: red; */
}

.parent_hotels_comp{
  
    width: 70%;
    /* background-color: red; */
}
.fav{
    font-size: 3em;
    cursor: pointer;
}

.showHotel{
    font-family: 'Poppins', sans-serif;
    color: #2E2D4D;
}

.parent_individual_hotel .showHotelSecond{
    font-family: 'Poppins', sans-serif;
    height: 10%;
    color: brown;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    /* height: auto; */
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
}

.showHotelSecondIcon{
    font-size: 3em;
    position: relative;
}

.showHotelSecond .showHotelSecondIcon .mainIcon{
    position: absolute;
    animation: moveIcon 4s linear infinite;
}

@keyframes moveIcon {
    0% {
      left: 0;
    }
    50% {
      left: calc(100% - 50px);
    }
    100% {
      left: 0;
    }
  }

/* Star colors */
.star1 { color: green; }
.star2 { color: green; }
.star3 { color: green; }
.star4 { color: green; }
.star5 { color: rgb(138, 128, 128); }

/* Style the stars using Unicode characters */
.star {
  display: inline-block; /* Display stars in a row */
  font-size: 15px;
  /* margin-right: 4px; */
}

.starDivName{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    /* background-color: rebeccapurple; */
}

.starDivName .guestReview{ 
    font-family: 'Poppins', sans-serif;
    font-size: 0.6em;
    font-weight: 400;
    /* background-color: aqua; */
    margin-top: 5px;
}

.breakfastBookdiv{
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right:10px ;
    margin-bottom: 2%;
}

.breakfastBookdiv .breakfastLab{
    background-color: burlywood;
    opacity: 0.8;
    color: whitesmoke;
    font-family: 'Poppins', sans-serif;
    font-size: 0.7em;
    padding: 2px 10px 2px 10px;
}

@media screen and (max-width: 1920px){
    .individual_main_div{
        /* background-color: rebeccapurple; */
    }
    
}

@media screen and (max-width: 850px) {
    .secondparent_hotels{
        margin-top: 15%;
        /* background-color: rebeccapurple; */
    }
}




@media screen and (max-width: 1345px){
    .parent_hotels{
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
           margin-top: 30px;
        width: 100%;
        /* padding: 10px; */
    }
    .secondparent_hotels{
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        margin-top: 6.5%;
        width: 100%;
        /* padding: 10px; */
    }
    .filter_section{
        /* margin-left: 3%; */
    }
    
    .parent_individual_hotel{
        /* background-color: rebeccapurple; */
        /* padding-left: 10%; */
        /* width: 95%; */
    }
    .individual_main_div{
        /* width: 75%; */
    }
    .review{
       
        width: 100%;
        font-size: 0.8em;
        /* margin-top: -10px; */
        margin-left: 3%;
    }
}




@media screen and (max-width:1630px) {
    .individual_main_div{
        /* background-color: red; */
        width: 100%;
    }

    .parent_individual_hotel{
        width: 70%;
        /* background-color: red; */
    }

    .indiv_hotel2{
        width: 100%;
        /* background-color: red; */
    }
    .hotel_name{
        width: 100%;
    }
    .hotel_amout{
        width: 35%;
    }
    .review i{
        
        width: 500px;
        /* background-color: red; */
    }
    
}


@media screen and (max-width:1600px) {
    .individual_main_div{
        /* background-color: gray; */
        width: 100%;
    }
    .parent_individual_hotel{
        width: 70%;
        /* background-color: red; */
    }

    .indiv_hotel2{
        width: 100%;
        /* background-color: red; */
    }
    .hotel_name{
        width: 100%;
    }
    .hotel_amout{
        width: 35%;
    }
    .review i{
        
        width: 400px;
        /* background-color: red; */
    }
    .indiv_hotel_img{
        width: 225px;
    }
    
}





@media screen and (max-width: 1170px){
    .review i{
        
        width: 350px;
        /* background-color: red; */
    }
    .hotel_amout{
        width: 38%;
    }
}


@media screen and (max-width: 1300px){
   
    .filter_section{
        margin-right: 4%;
    }
    .review{
       
        width: 100%;
        font-size: 0.8em;
        /* margin-top: -10px; */
        margin-left: 3%;
    }
    .parent_individual_hotel{
        width: 93.8%;
        /* background-color: red; */
    }
}


@media screen and (max-width: 1450px){
   .upperDiv{
    width: 93.6%;
   }
    .filter_section{
        margin-right: 4%;
    }
    .review{
       
        width: 100%;
        font-size: 0.8em;
        /* margin-top: -10px; */
        margin-left: 3%;
    }
    .parent_individual_hotel{
        width: 93.8%;
        /* background-color: red; */
    }

}

@media screen and (max-width: 1520px){
    .review i{
        /* margin-top: 2px; */
        margin-bottom: 8px;
        width: 450px;
        /* background-color: red; */
    }
}

@media screen and (max-width: 1100px){
    .upperDiv{
        width: 97%;
    }
    .parent_individual_hotel{
        width: 97%;
        /* background-color: rgb(27, 6, 6); */
    }
    .review i{
       
        width: 400px;
        /* background-color: red; */
    }
    .secondparent_hotels{
        margin-top: 10%;
    }
}

@media screen and (max-width: 1000px){
    .subscribeDiv{
        display: none;
    }
    .hotels_category{
        width: 100% ;
        display: flex;
        flex-direction: row;
        /* margin-left: -3%; */
        padding: 0;
    }
    .filter_section{
        margin-top: 5%;
        margin-left: 1%;
    }
    .hotel_name{
        font-size: 1em;
  
        width: 98%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-left: 5px;
        overflow: hidden;
    }
    .hotel_address{
       
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 90%;
        /* justify-content: center; */
        font-size: 1em;
        padding: 5px;
        font-family: 'Poppins', sans-serif;
        margin: 0.5% 0 1% 1%;
    }
    .review{
       
        width: 100%;
        font-size: 0.8em;
        /* margin-top: -10px; */
        margin-left: 3%;
    }
    .review i{
        /* margin-top: 2px; */
        margin-bottom: 8px;
        width: 400px;
    }
    .review span p{
        display: none;
    }
    .indiv_hotel_text ul{
       
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0;
        align-items: center;
        margin-top: -4px;
        font-family: 'Poppins', sans-serif;
    }
    .indiv_hotel_text ul li{
       
        font-size: 0.7em;
        
    }
    .indiv_hotel_text ul .book{
        width: 30%;
        margin-left: 0 ;
        
    }
    .indiv_hotel_text ul .book button{
        width: 100%;
        font-size: 0.5em;
        padding: 5px;
    }
    .parent_individual_hotel{
        /* background-color: red; */
        /* display: flex;
        justify-content: center; */
        margin-bottom: 5%;
    }
    .parent_hotels_comp{
        /* background-color: rebeccapurple; */
        width: 100%;
    }
    .filter_section{
        margin-right: 7%;
    }
    .individual_main_div{
        /* background-color: red; */
        width: 100%;
    }
    .hotels_list_header{
        /* background-color: #2E2D4D; */
        width: 100%;
    }

    .showHotelSecond{
        font-family: 'Poppins', sans-serif;
        color: brown;
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
        height: auto;
        padding: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
}


@media screen and (max-width: 850px) {
    .secondparent_hotels{
        margin-top: 15%;
        /* background-color: rebeccapurple; */
    }
}


@media screen and (max-width: 750px){
    .filter_section{
        display: none;
    }
    .mobileFiterOpt{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: aliceblue;
    }

   .mobileFiterOpt .mobileSearchFilter{
    background-color: #2E2D4D;
    color: white;
    border-radius: 2px;
   }
    .individual_main_div{
        /* width: 100%; */
        /* margin-left: -12px; */
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .schBar{
        margin-top: 13%; 
        padding-top: 20%;
        height:  auto;
    }
    .fixed {
      
       margin-top: 61px;
       z-index: 100;
       /* border-bottom: 1px solid gray; */
      }
    .indiv_hotel2{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin: 0%; */
        width: 100%;
        padding-bottom: 15px;
        /* background-color: #2E2D4D; */
    }
    .hotels_list_header{
        /* background: red; */
        width: 96%;
        padding-left: 1%;
        padding-right: 1%;
        
    }
    .indiv_hotel_img{
        /* max-height: 290px; */
        min-height: 350px;
        width: 100%;
        /* height: 100%; */
        background-color: #2E2D4D;
    }
    .indiv_hotel_img img{
        width: 100%;
        min-height: 350px;
        max-height: 290px;
        /* height: 100%; */
    }

    .action{
        width: 77vw;        
    }
    .no_hotel{
        width: 95vw ;
        /* background-color: rebeccapurple; */
       
    }
    .hotels_text_header{
        font-family: 'Poppins', sans-serif;
       font-size: 0.8em;
        width: 100%;
        color: #2E2D4D;
        opacity: 0.8;
       /* background-color: #CED82A; */

    }
    .hotels_category{
        width: 100% ;
        display: flex;
        flex-direction: row;
        margin-left: 0;
        /* background-color: rebeccapurple; */
       padding: 0;
    }
    .hotels_category li{
        
    }
    .hotel_name{
        font-size: 0.8em;
        /* display: flex; */
        /* flex-direction: column; */
        /* justify-content: flex-start; */
        /* background-color: #CED82A; */
        margin-top: 2%;
        padding: 3px 1px 0 1px;
    }
    .hotel_address span{
        font-size: 0.7em;
        /* background-color: red; */
        /* width: 90%; */
    }
    .hotel_address{
        font-size: 1em;
        /* background-color: red; */
        /* width: 40px; */
        /* margin-top: -2%; */
    }
    .review{
        /* margin-top: -20px; */
        font-size: 0.9em;
        /* margin-left: 1%; */
        display: flex;
        flex-direction: row;
       /* margin-bottom: 10px;  */
       /* background-color: #CED82A; */
       width: auto;
    }
    
    .review i{
        /* margin-top: 10px; */
        padding-left: 5px;
        font-size: 0.7em;
        width: 610px;
    }
    .indiv_hotel_text{
        /* background-color: #2E2D4D; */
        width: 98%;
    }
    .indiv_hotel_text ul{
        margin-top: 1.5%;
        /* background-color: red; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .book{
     
    }
    .parent_individual_hotel{
        /* background-color: red; */
        width: 93%;
        
    }
    .addressDiv__{
        /* background-color: #CED82A; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


@media screen and (max-width: 600px) {
    .secondparent_hotels{
        margin-top: 20%;
    }
}



@media screen and (max-width: 500px){
    .schBar{
        margin-top: 15%; 
        padding-top: 20%;
        height:  auto;
    }
    .hotels_category{
        display: none;
    }
    .parent_individual_hotel{
        /* background-color: red; */
        width: 98%;
        
    }
    .individual_main_div{
    /* background-color: rgb(17, 11, 11); */
        width: 100%;
    }
    
    .hotel_name{
        font-size: 0.8em;
        /* display: flex; */
        flex-direction: row;
        /* justify-content: flex-start; */
        /* background-color: #CED82A; */
        padding: 8px 0 0 0;
    }

    .hotel_amout{
        
    }
    
    }

    @media screen and (max-width: 450px){
        .parent_hotels{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
           margin-top: 30px;
            width: 100%;
            /* padding: 10px; */
        }

        .secondparent_hotels{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 20%;
            width: 100%;
            /* padding: 10px; */
        }
        .parent_hotels_comp{
            padding-left: 2%;
            padding-right: 2%;
        }
        .review{
           
            width: 100%;
            font-size: 0.8em;
            /* margin-top: -10px; */
            /* margin-left: 3%; */
        }
        .review i{
            /* margin-top: 2px; */
            margin-bottom: 8px;
            width: 350px;
            /* background-color: red; */
        }
        .priceDiv{
            flex-direction: row;
        }
        .indiv_hotel2{
            /* background-color: #2E2D4D; */
            /* width: 376.7px; */
        }
    }

@media screen and (max-width: 360px){
    
    .fixed {
      
        margin-top: 97.5px;
        z-index: 100;
        /* border-bottom: 1px solid gray; */
       }
       .secondparent_hotels{
        margin-top: 20%;
    }
    .review{
           
        width: 100%;
        font-size: 0.8em;
        /* margin-top: -10px; */
        margin-left: 0%;
    }
}
