.Spinner{
    background:"#E7ECF3";
    width:"100vw";
    height:"150vh";
    position: fixed;
    opacity:"0.5";
    display:"flex";
    align-items:"center";
    justify-content: "center";
    color:"#fff"

}