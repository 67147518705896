
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
.parent_search{
    background: white;
    width: 22%;
    height: 100vh auto;
    /* margin-top: 5%; */
    margin-left: 0%;
    /* margin-right: 20%; */
    /* margin-left: 78%;
    position: absolute; */  
}
.fixedFilter {
    position: fixed;
    top: 5%;
    background: whitesmoke;
    height: 100vh auto;
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
   /* margin-top: 10%; */
   /* border-bottom: 1px solid gray; */
  }

.filterTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.searchFilter{
    border-radius:5px ;
    border: 1px solid gray;
    
    padding: 10px;
}
.filter_item1{
    border-bottom: 1px solid gray;
    padding-bottom: 20px;
}

.filter_item1 p{
    font-family: 'Poppins', sans-serif; 
    font-size: 1em;
}
.filter_item1 h2{
    border-bottom: 1px solid gray;
    padding: 10px;
    font-family: 'Times New Roman', Times, serif;
    color: #2E2D4D;
}
.range{
    margin-bottom: 20px;
}

.field{
    /* position: relative; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    height: 100%;
    background-color: #7a78b9;
    padding: 10px;
    opacity: 0.8;
    background-image: url('../../assets/propBack.png'); 
    background-size: 55%;
    background-repeat: no-repeat;
    background-position: bottom right;
}

.field .hotelProps2{
    margin-bottom: 8px;
    
}
.field .hotelProps {
    cursor: pointer;
}

.field .hotelProps span{
    font-size: 0.8em;
    font-family: 'Poppins', sans-serif; 
    color: whitesmoke;
}

.field input{
    /* -webkit-appearance: none; */
    height: 30px;
    width: 70%;
    background: #ddd;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}
.field .value{
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    color: #2E2D4D;
    height: 10px auto;
    
    margin-top: -20px;
}
/* .field .value.left{
    left: 1px;
} */

.field .value.right{
    right: 0px;
}
.filter_item1 button{
    outline: none;
    border: none;
    width: 200px;
    background: #2E2D4D;
    color: white;
    border-radius: 5px;
    padding: 5px;
    font-size: 1em;
    cursor: pointer;
    font-family: 'Poppins', sans-serif; 
    
}

.filter_items2{
    border-radius:5px ;
    border: 1px solid gray;
    margin-top: 30px;
    height:  auto;
   
}

.filter_items2 h2{
    border-bottom: 1px solid gray;
    padding-bottom: 20px;
}
.filter_items2 ul li{
    list-style: none;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  
}
.area_hotel{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -25px;
    margin-bottom: 20px;
}

.area_hotel_text2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.area_hotel_text2 span{
    font-size: 0.75em;
    margin-left: 6%;
}

.filter_item1 ul li{
    list-style: none;
    margin-left: 10px;
}
.search_amenities{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    margin-left: -30px;
    margin-bottom: 20px;
    font-size: 0.9em;
    font-family: 'Poppins', sans-serif; 
}

.search_amenities li{
    font-family: 'Poppins', sans-serif;
}

.search_amenities input[type=checkbox] {
    height: 15px;
    width: 15px;
}

.hotelProps{
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    /* background-color: #ddd; */
    width: 100%;
}

.hotelProps input[type=checkbox] {
    height: 15px;
    width: 15px;
} 
.search_by_amout{
    margin-left: -50px;
    margin-bottom: 20px;

}

.filter_items2 input[type=checkbox] {
    height: 25px;
    width: 25px;
}

.filter_items2 li{
    font-family: 'Poppins', sans-serif; 
    font-size: 0.9em;
}
@media screen and (max-width: 860px){
    .parent_search{
        display: none;
    }
}
@media screen and (max-width: 1000px){
    .parent_search{
        display: none;
    }
}
@media screen and (max-width: 1100px){
    .parent_search{
        width: 24%;
        
    }
}
