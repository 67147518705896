@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

.parentReviewDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets//secondReviwBack.jpg");
    background-repeat: no-repeat;
    background-color: #ECECEC;
    background-size: 50% 50%;
}
.customerReviewDiv{
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    margin-top: 5%;
    width: 70%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center ;
   
}

.reviewQus{
    /* border: 1px solid grey; */
    width: 65%;
    margin-bottom: 5%;
    /* padding: 2% 5% 2% 5%; */
    font-size: 0.8em;
    font-family: 'Poppins', sans-serif;
    background-color: whitesmoke;
    opacity: 0.8;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; 
}

.headerDivRev{
    background-image: url("../../assets//customerBckImg.jpg");
    background-repeat: no-repeat;
    /* background-color: #2055AB; */
    background-size: cover;
    color: white;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
    font-size: 1.7em;
    text-transform: uppercase;
    padding: 0 2% 0 2%;
}

.text{
    background-image: linear-gradient(to right, #1B1B1B 66.5%, whitesmoke 66.5%, white 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
  text-align: justify;
}

.mainQust{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    text-align: justify;
    margin-bottom: 5%;
}

.starDiv{
    /* background-color: bisque; */
    margin-top: 1%;
    width: 20%;
   text-align: center;
}

.radioLabel{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    /* background-color: aqua; */
    margin-top: 1%;
    /* margin-bottom: 1%; */
}

.radioLabel .selected{
    color: #4270AA;
    font-size: 2.5em;
    cursor: pointer;
}

.radioLabel .notSelected{
    color: grey;
    font-size: 1.8em;
    cursor: pointer;
}

.inputDivRev{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* width: 80%; */
    padding-left: 8%;
    margin-top: 2%;
    /* background-color: rebeccapurple; */
}

.inputDivRev label{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ECECEC;
    width: 30%;
    margin-bottom: 10px;
}

.inputDivRev label .addIcon{
    cursor: pointer;
    background-color: #1B1B1B;
    padding: 5px;
    color: white;
    transition: transform 0.3s;
}

.inputDivRev label .addIcon:hover{
    transform: scale(1.1);
    font-size: 1.3em;
}
.inputDivRev textarea{
    height: 100px;
    width: 85%;
    padding: 7px 15px 7px 15px;
    border-top: none;
    border-left: none;
    border-radius: 8px;
    transition: border 0.3s;
    background-color: transparent;
}

.inputDivRev textarea:focus{
    border-bottom: 3px solid #4D77AF;
    border-right: 3px solid #4D77AF;
    border-left: 3px solid #4D77AF;
    transition: border 0.3s;
}

.submitReview{
    /* background-color: bisque; */
    display: flex;
    justify-content: flex-end;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
    margin-bottom: 3%;
    padding-right: 30px;
}
.submitReview button{
    /* width: 22%; */
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    border: 1px solid #4D77AF;
    background-color: #4D77AF;
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.submitReview button .feedBackIcon{
color: white;
font-size: 1.6em;
}

ol{
    /* background-color: #4D77AF; */
    text-align: justify;
    padding: 0 10% 0 10% ;
}

@media screen and (max-width: 1650px) {
    .inputDivRev label{
        background-color: #ECECEC;
        width: 35%;
    }
}

@media screen and (max-width: 1550px) {
    .inputDivRev label{
        background-color: #ECECEC;
        width: 60%;
    }
}

@media screen and (max-width: 1200px) {
    .inputDivRev label{
        background-color: #ECECEC;
        width: 50%;
    }
}


@media screen and (max-width: 800px) {
    .parentReviewDiv{
        
        background-image: url("../../assets//secondReviwBack.jpg");
        background-repeat: no-repeat;
        background-color: #ECECEC;
        background-size: cover;
    }
    .customerReviewDiv{
        
        width: 95%;
    }

    .headerDivRev{
        height: 150px;
    }

    .reviewQus{
        margin-top: 18%; 
        width: 100%;
        border-radius: 5px;
    }

    .inputDivRev label{
        
        background-color: #ECECEC;
        width: 75%;
    }
}