
    .modalShow {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1500; /* Ensure it appears above other components */
        left: 0;
        top: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
      }
      
      .modal-content {
        background-color: #fff;
        padding: 20px;
        border: 1px solid #888;
        width: 95%;   
        max-width: 80%;
        position: relative;
      }
      
      .modalClose {
        position: absolute;
        
        top: 0.5px;
        right: 10px;
        cursor: pointer;
       
        color: red;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        font-size: 2em;
      }
      