.skeletonParent{
    /* display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-flow: 10px; */
    padding-left: 10px;
    padding-right: 10px;
    /* padding-bottom: 10px; */
    padding-top: 10px;
    /* border: 1px solid RGB(189,189,189); */
    margin-top: 10%;
    
}
.skeletonDescription{
    width: 100%;
}

.skeletonAddress{
    padding-left: 10px;
    padding-right: 10px;
    /* padding-bottom: 10px; */
    /* padding-top: 10px; */
    /* border: 1px solid RGB(189,189,189); */
    /* margin-top: 10%; */
    
}

.skeletonImage{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
}

.skeletonAmenityName{
    /* border: 1px solid RGB(189,189,189); */
    width: 60%;
}

.skeletonAmenity{
    /* border: 1px solid RGB(189,189,189); */
    width: 100%;
}

.skeletonAmenity .namediv{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-right: 10px;
    /* justify-content: center; */
   
}
.room_type{
    display: grid;
    grid-template-columns: 1fr 2fr;

    width: 100%;
   

}
.room_type h3{
   
   
    padding-left: 6px;
    
     font-size: 0.9rem;
    }

.book_img{
   
    height: 150px;
    width: 160px;
}
.room_type_price{
   
    display: flex;
    flex-direction: row;
}
.room_type_price p{
    font-size: 0.9rem;
    margin-left: 1.5%;
    font-weight: 400;
}

.room_type_price i{
    font-size: 0.7rem;
}

.price_span{
    font-size: 1.5em;
    font-weight: 800;
    color: #2E2D4D;
}

.img{
    width: 100%;
    height: 100%;
}

.skeletonBtn{
    width: 30%;
}

.parentSkeletonHotelList{
    /* background-color: #2E2D4D; */
    margin-top: 8%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh auto;
}

.skeletonHotelList{

    
}
.skeletonHotelList .namediv{
    display: flex;
    flex-direction: column;

}

.parentSkeletonHotelList .skeletonImage{
    /* background-color: rgb(64, 90, 112); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
