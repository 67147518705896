@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.app_section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* background-color: RGB(204,212,223); */
  height: 50vh auto;
  width: 95vw;
  /* background-color: red; */

}

.app_parent img {
  height: 100%;
  width: 350px;
  margin-left: 300px;
}

.app_text {
  /* background-color: #000000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.app_text h2 {
  font-size: 4em;

  color: #2E2D4D;
  width: 90%;
  /* background-color: #000000; */
  text-align: justify;
  text-transform: capitalize;
}

.app_text h4 {
  font-size: 2.1em;
  font-weight: 700;
  color: RGB(79, 79, 79);
  font-family: sans-serif;
  width: 90%;
  margin-top: -40px;
  /* margin-left: 100px; */
  text-align: justify;
  text-transform: capitalize;
  margin-bottom: 0;
  /* background-color: rebeccapurple; */
}

.app_text p {
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  /* margin-left: -550px; */
  width: 80%;
  text-align: justify;
  /* font-size: 2.5em; */
  /* margin-top: 2.5%; */

}



.app_section {
  font-family: 'Poppins', sans-serif;
  font-size: 0.8em;
}

.app_section {
  overflow: hidden;
}

.app_download {
  width: 60%;

  /* text-align: center; */
  margin-left: -15%;
  /* padding-right: 50%; */
  /* padding-left: -50%; */
  margin-top: 3.5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* background-color: rebeccapurple; */


}

.downloadopt {
  display: inline-block;

}

.download,
.android {
  width: 200px;
  height: 65px;
  background: #000000;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  /* border: 1px solid #fff; */
}

.android {
  margin-left: -5%;
}

.android img {
  width: 45%;
  height: 75%;
  margin-left: -10%;
}

.download>.fa {
  color: #fff;


}

.df,
.dfn {}

.df {

  font-size: .6em;
}

.dfn {

  font-size: 1.08em;
}

.download:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.appleStore {
  display: flex;
  flex-direction: column;
}

.andstore {
  margin-left: -7%;
  font-size: 1em;
}

.app_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1345px) {
  .app_section {
    width: 100vw;
  }

  .app_parent img {
    margin-left: 0;
  }

  .app_text h2 {

    width: 90%;
    margin-left: 1px;
  }

  .app_text h5 {

    width: 90%;
    margin-left: 1px;
  }

  .app_text p {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1000px) {
  .app_section {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    /* background-color: red; */
  }

  .app_text h2 {
    font-size: 2em;
    justify-content: center;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 50px;
  }

  .app_text h5 {
    font-size: 1.5em;
    text-align: center;
  }

  .app_parent img {}
}

@media screen and (max-width: 750px) {
  .app_download {
    width: 100%;
    padding-left: 0;
    justify-content: center;
    margin-left: 0%;
    /* background-color: rebeccapurple; */
  }

  .download,
  .android {
    width: 150px;
    height: 45px;
    /* background-color: red; */
  }

  .android {
    margin-left: -3%;
  }

  .app_parent {
    /* background-color: rebeccapurple; */
    width: 95vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app_parent img {
    margin-left: 0px;
  }

  .app_text h5 {
    margin-left: -5%;
  }
}