@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

nav {
    margin: 0;
    background: #2E2D4D;
    color: white;
    padding: 10px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: row;

    align-items: center;
    height: 80px;
}

.navBarLink {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    flex: 70%;
    /* flex: 80%; */
    width: 90%;
    /* background-color: rgb(143, 143, 44); */
    align-items: center;
    /* margin-right: 2%; */

}

.reservationFind {
    /* background-color: aqua; */
    /* width: 50%; */
    white-space: nowrap;
}

.currHomeBtn {
    /* background-color: aqua; */
    width: 100%;
    display: none;

}

nav .navBarLink li {
    margin-right: 10px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    font-weight: 400;
    font-size: 0.8em;
}

.logos {
    width: 40%;
    height: 100%;
    margin-bottom: 10px;
    margin-top: 10px;

    /* background-color: aqua; */
}

.logos img {
    height: 100%;
    width: 100%;
}

/* .logos h2{
    
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
    border: 2px solid whitesmoke;
    list-style: none;
    width: 100% auto ;
    height: 100% auto;
} */


.register {
    border: 1px solid #CED82A;
    background: #CED82A;
    color: white;
    padding: 10px;
    cursor: pointer;
    width: 100px;
    border-radius: 5px;

}

.login {
    border: 1px solid #CED82A;
    background: #CED82A;
    color: white;
    padding: 6px;
    cursor: pointer;
    width: 150px;
    border-radius: 5px;
}

.loginicon {
    /* color: black; */
}

.regLog {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    /* background-color: #000000; */
}

.parentregLog {
    /* background-color: rebeccapurple; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
}


.showbutton {
    display: none;
    position: relative;
}

nav .navBarLink #hidden {
    display: flex;


}

/* @media  screen and (max-width: 1650px){
        .logos{
            width: 40%;
            height: 100%;
           margin-bottom: 10px;
           margin-top: 10px;
           margin-left: 10%;
       
           padding-left: 45px;
        
    }
} */

/* @media  screen and (max-width: 1200px){
    .logos{
        width: 100%;
        height: 100%;
       margin-bottom: 10px;
       margin-top: 10px;
     
       padding-left: 10px;
    }
} */

@media screen and (max-width: 103.125em) {

    /* 1650px */
    nav .navBarLink li {
        font-size: 0.7em;
        width: 100px;
    }

    .login {
        background-color: #2E2D4D;
        padding: 6px;
        width: 120px;
    }

    .navBarLink {
        flex: 70%;
    }

    .regLog {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
    }

    .parentregLog {
        width: 25%;
    }

    .logos {
        padding-left: 0;
        width: 15em;
        height: 3em;
    }

    nav {
        width: 100vw;
    }

    .signout {
        margin-top: 8%;
    }

    nav .navBarLink #hidden {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 8%;
        left: 30px;
        z-index: 999;
        width: 100%;
    }
}

@media screen and (max-width: 100em) {

    /* 1600px */
    nav .navBarLink li {
        font-size: 0.7em;
        width: 100px;
    }

    .login {
        background-color: #2E2D4D;
        padding: 6px;
        width: 120px;
    }

    .navBarLink {
        flex: 70%;
    }

    .regLog {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
    }

    .logos {
        width: 15em;
        height: 3em;
        padding-left: 0;
    }

    nav {
        width: 100vw;
    }

    .signout {
        margin-top: 8%;
    }

    nav .navBarLink #hidden {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 8%;
        left: 30px;
        z-index: 999;
        width: 100%;
    }
}

@media screen and (max-width: 87.5em) {

    /* 1400px */
    .parentregLog {
        width: 30%;
    }
}

@media screen and (max-width: 75em) {

    /* 1200px */
    .navBarLink {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .parentregLog {
        width: 37%;
    }

    .regLog {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
    }
}

@media screen and (max-width: 62.5em) {

    /* 1000px */
    .parentregLog {
        width: 45%;
    }
}

@media screen and (max-width: 59.375em) {

    /* 950px */
    .regLog {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
    }

    .parentregLog {
        width: 50%;
    }
}

@media screen and (max-width: 53.125em) {

    /* 850px */
    .regLog {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
    }

    nav .navBarLink li {
        display: none;
    }

    nav .navBarLink #hidden {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 8%;
        left: 30px;
        z-index: 999;
        width: 100%;
    }
}

@media screen and (max-width: 23.125em) {

    /* 370px */
    nav {
        width: 100vw;
    }

    .logos h2 {
        display: none;
    }

    .logos {
        width: 100px;
        height: 100px;
        padding-left: 0;
    }
}

@media screen and (max-width: 40.625em) {

    /* 650px */
    .logos {
        width: 10em;
        height: 2em;
        padding-left: 0;
    }
}