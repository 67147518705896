.footer2 {
	width: 100vw;
	height: 50vh auto;
	background: #2E2D4D;
	display: block;
	position: relative;

}

.inner-footer {
	width: 95vw;
	height: 100% auto;
	margin: auto;
	/* padding: 90px 10px;  */
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	justify-content: center;
	/* background-color: #CED82A; */
}

.footer-items {
	width: 25%;
	padding: 10px 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background: #000000;
	text-align: justify;
	color: #fff;
	margin-top: 50px;
	margin-bottom: -250px;
}




.footer-items p {
	font-size: 16px;
	text-align: justify;
	line-height: 25px;
	color: #fff;
}

.footer-items h3 {
	color: #fff;
}


.border5 {
	height: 3px;
	width: 40px;
	background: #CED82A;
	color: #ff9800;

	border: 0px;
}


.footer_list {
	list-style: none;
	color: #fff;
	font-size: 14px;
	/* letter-spacing: 0.5px;	 */
}


ul a {
	text-decoration: none;
	outline: none;
	color: #fff;
	transition: 0.3s;
}

ul a:hover {
	color: #ff9800;
}

.footer_list ul li {
	margin: 10px 0;
	height: 25px;
}


.footer_list li {
	margin: 10px 0;
	height: 25px;


}

li i {
	margin-right: 10px;

}

.social-media {
	width: 100%;

	/* margin-left: -8%; */
	color: #fff;
	/* text-align: center; */
	font-size: 1em;



}

/* .social-media a {
	text-decoration: none;
} */

.social-media a i {
	/* height: 10px;
	width: 10px ; */

	margin: 2% 1%;
	padding: 4px;
	color: #fff;
	cursor: pointer;
	transition: all 0.5s;

}

.social-media a i:hover {
	transform: scale(1.5);
	color: #ff9800;
}

.footer-bottom {
	padding: 180px;
	padding-bottom: 10px;
	margin-top: 5%;
	background: #00121b;
	color: #fff;
	font-size: 12px;
	text-align: center;

}

/* Basic styles for the li */
.hover-container {
	position: relative;
	/* This is needed to position the card */
}

/*  */

/* Card styles */
.hover-card {
	display: none;
	/* Initially hidden */
	position: absolute;
	/* Position it relative to the parent */
	top: 30px;
	/* Distance from the parent element */
	left: 0;
	width: 200px;
	padding: 10px;
	background-color: white;
	color: #000000;
	border: 1px solid #ccc;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 100;
	/* Make sure it appears above other elements */
}

.hover-card ul {
	list-style: none;
	
	font-weight: 600;
	padding-left: 0;
	/* Remove default padding */
}

.hover-card ul li::before {
	content: '✓';
	/* Checkmark symbol */
	font-size: 18px;
	/* Adjust size if needed */
	color: green;
	/* Set color for the checkmark */
	font-weight: bold;
	margin-right: 10px;
	/* Add some space between the checkmark and the text */
}

/* Show the card on hover */
.hover-container:hover .hover-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	color: #000000;
	box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

}

.hover-container:hover .hover-card h4 {
	/* background-color: #ccc; */
	width: 100%;
	text-align: center;
	font-size: 1.3em;
	text-transform: capitalize;
	margin-bottom: 0px;
	margin-top: 0px;
}

@media screen and (max-width: 1000px) {
	.footer-items {

		width: 100%;
		height: 50vh auto;
		display: flex;
		flex-direction: column;
		margin-top: -1px;
		margin-bottom: 1px;
		background: #2E2D4D;

	}

	/* .footer{
		background: rebeccapurple;
	} */
	.inner-footer {
		width: 100%;
	}

	.footer-bottom {
		/* width: 22.5vw; */
		padding: 0;
	}
}