.checkInDIv{
    margin: 0%;
background-image: url("../../../../assets/Moon\ \(2\).svg");
background-repeat: no-repeat;
 /* background-position: ; */
background-size: cover;
height: 100vh auto;
background-attachment: fixed;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.checkInMainDiv{
    width: 53%;
    /* margin-left: 23%; */
    padding: 5%;
    color: white;
    background-color: #2E2D4D;
    opacity: 0.8;   
    margin-top: 7%;
    padding-top: 5%;
    border-radius: 10px;
    padding-bottom: 20px;
    margin-bottom: 2%;
}

.checkInMainDiv h1{
    color: white;
    font-family: monospace;
    text-transform: uppercase;
    border-bottom: 2px solid white;
    padding-bottom: 20px;
    margin-bottom:5% ;
}

.checkInMainDiv p{
    color: white;
    text-align: justify;
}

.tickMark{
    color: #CED82A;      
}

.checkInMainDiv li{
    list-style-type: none;
}

@media screen and (max-width: 800px) {
    .checkInDIv{
        margin-top: 10%;
        padding-top: 5%;
        }
        .checkInMainDiv{
            width: 75%;
            
        }
}