@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

input {
  caret-color: black;
}

.search_hotelparent {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  place-items: center;
  /* position: absolute;
  top: 12.5%; */
  /* background-color: #ecf0f3; */
  border-radius: 20px;
  /* height: 100%; */
  width: 100%;
  z-index: 208;

}

.locationInput {
  position: relative;
}

.searchIcon {
  position: absolute;
  margin-top: 3.8%;
  margin-left: -27%;
  color: grey;
}

.search_hotel {
  position: relative;
  width: 70%;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  /* background: #091016; */
  background: #2E2D4D;
  /* background: linear-gradient(180deg, RGB(30,83,169) 0%, #f5f5f5 100%); */
  /* opacity: 0.9; */
  /* box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white; */
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.location {
  margin-top: 4%;
  width: 40%;
  border: none;
  outline: none;
  box-sizing: border-box;
  padding-left: 35px;
}


.location::placeholder {
  color: gray;
}

.location2 {
  color: gray;
  text-align: center;
  font-weight: 700;
}

.location {
  background: #ecf0f3;

  margin-bottom: 30px;

  height: 50px;
  font-size: 14px;
  margin-left: 1%;
  /* scroll-behavior: smooth; */
  border-radius: 5px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
  cursor: pointer;
  z-index: 1000;
}

.location option {
  font-family: 'Poppins', sans-serif;
  outline: none;
  border: none;
  /* cursor: pointer; */
}

.submit {
  color: white;
  background: #CED82A;
  height: 40px;
  width: 15%;
  margin-left: 2%;
  margin-top: 1.3%;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.3rem;
  /* box-shadow: 6px 6px 6px #2E2D4D, -6px -6px 6px #7977c7; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transition: 0.5s;
  outline: none;
  border: none;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.checkDateSelection {
  color: white;

  background: #CED82A;
  height: 40px;
  width: 15%;
  margin-left: 2%;
  margin-top: 1.3%;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.3rem;
  /* box-shadow: 6px 6px 6px #2E2D4D, -6px -6px 6px #7977c7; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transition: 0.5s;
  outline: none;
  border: none;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.select-dates-label {
  color: rgb(238, 0, 0);
  z-index: 1000;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 5px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8em;
  background-color: aliceblue;
}

.disableStyle {
  color: white;

  background: grey;
  height: 40px;
  width: 15%;
  margin-left: 2%;
  border-radius: 10px;
  cursor: not-allowed;
  font-weight: 700;
  font-size: 1.3rem;
  margin-top: 1.3%;
  /* box-shadow: 6px 6px 6px #2E2D4D, -6px -6px 6px #7977c7; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transition: 0.5s;
  outline: none;
  border: none;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.submit:hover {
  transform: translateY(-10px);
}

.searchicons {
  margin-left: -20%;
  font-size: 1.4rem;
  position: absolute;
}


.closebtn {
  border: none;
  position: absolute;
  top: 44%;
  margin-left: -2.5%;

}

.closebtn_icon {
  font-size: 1.5rem;
  background: #ecf0f3;

}

.calendarElement {
  position: absolute;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.locationHandler {
  position: absolute;
  width: 50%;
  top: 90%;
  right: 0;
  left: 0;
  background-color: antiquewhite;

}

.countHandler {
  position: absolute;
  width: 100%;
}

.loading-spinner {
  /* background-image: url('../../assets/loading-spinner.gif'); */
  /* background-repeat: no-repeat;
  background-position: center;
   */
  height: 44px;
  width: 15%;
  outline: none;
  border: none;
  margin-top: 5%;

  border-radius: 10px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
  box-shadow: 6px 6px 6px #2E2D4D, -6px -6px 6px #7977c7;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

}

.ant-picker-input {
  /* color: red; */
}

.submit2 {
  width: 14%;
  height: 40px;
  background: #CED82A;
  border-radius: 10px;
  border: 1px solid #CED82A;
  margin-left: 2%;
  margin-top: 1.3%;
  text-align: justify;
  font-family: sans-serif;
  color: white;
  font-size: 1.2em;
  padding-left: 2%;
  padding-bottom: 5px;
}

.searchinputsForm {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background-color: aqua; */
}

.ant-picker-input>input::placeholder {
  color: #ee0000 !important;
  font-weight: 900;
  opacity: 1;
}



@media screen and (max-width: 1650px) {
  .search_hotel {
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .searchIcon {
    margin-top: 3.9%;
    margin-left: -27%;
    font-size: 1em;
  }

  .submit2 {
    font-size: 1em;
  }

}

@media screen and (max-width: 980px) {
  .searchIcon {
    display: none;
  }


}




@media screen and (max-width: 1000px) {
  .search_hotelparent {}

  .search_hotel {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;


  }

  .location {
    width: 36%;

    margin-bottom: 12px;
    margin-top: 2%;
  }

  .submit {
    width: 23%;
  }

  .submit2 {
    width: 28%;
  }

  .searchIcon {
    margin-top: 3.1%;
    margin-left: -20.5%;
    font-size: 1em;
  }
}

@media screen and (max-width: 1200px) {
  .search_hotel {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

  }

  .searchIcon {
    margin-top: 3.1%;
    margin-left: -23%;
    font-size: 1em;
  }

  .location {
    width: 40%;

    margin-bottom: 12px;
    margin-top: 2%;
  }

  .submit2 {
    font-size: 0.8em;
    padding-left: -2%;
    font-family: sans-serif;
    width: 16%;
  }
}

@media screen and (max-width: 850px) {
  .select-dates-label {
    padding: 0px;
  }

  .locationHandler {
    top: 40%;
    margin-left: 5%;
    z-index: 1500;
    width: 100%;
  }

  .searchinputsForm {
    flex-direction: column;
  }

  .location {
    width: 49%;

    margin-bottom: 12px;
    margin-top: 2%;
  }

  .checkDateSelection {
    width: 80%;
  }

}

@media screen and (max-width: 800px) {
  .submit2 {
    /* margin-right: -25%; */
    width: 30%;
    font-size: 0.4em;
  }
}

@media screen and (max-width: 650px) {
  .submit2 {
    /* margin-right: -25%; */
    width: 35%;
    font-size: 0.5em;
  }
}

@media screen and (max-width: 600px) {
  .location2 {
    /* display: none; */
    width: 90%;
  }



  .search_hotelparent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: antiquewhite; */
  }

  .searchIcon {
    display: none;
  }

  .submit {
    margin-right: -25%;
    width: 35%;
    height: 47px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    font-size: 1em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
  }

  .submit2 {
    margin-right: -25%;
    width: 35%;
    height: 47px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    font-size: 0, 4em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
  }

  .locationInput {
    width: 90%;
  }

  .search_hotel {
    width: 100%;
    /* margin-left: 5%; */
    /* background-color: aquamarine; */
  }

  .search_hotelparent {
    margin-top: -10%;
  }
}

@media screen and (max-width:450px) {
  .submit2 {
    margin-right: -25%;
    width: 45%;
    font-size: 0.5em;
    /* margin-top: -4%; */
  }
}

@media screen and (max-width: 350px) {
  .submit {
    /* background-color: aliceblue; */
    width: 22%;
    height: 50px;
    font-size: 0.8em;
  }

  .submit:hover {
    transform: translateY(0px);
  }

  .submit2 {

    margin-right: -25%;
    width: 22%;
    height: 47px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    font-size: 0, 4em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;

  }
}