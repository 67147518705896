@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap");
.bookingModalParent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1500; /* Ensure it appears above other components */
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.bookingModalDiv {
  background-color: #fff;
  padding: 1% 1% 1% 1%;
  border: 1px solid #888;
  width: 35%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.doneBtn {
  padding: 2% 4% 2% 4%;
  background-color: #4d77af;
  border: 1px solid #4d77af;
  border-radius: 3px;
  color: white;
  cursor: pointer;
}

.iconColor {
  color: #af9a21;
}

.modalText {
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 0.9em;
  color: #888;
  padding: 5% 5% 5% 5%;
}

.congratSecondText {
  font-family: "Poppins", sans-serif;
  font-size: 0.8em;
  color: #888;
}

.cancelBtnDiv {
  /* background-color: #4D77AF; */

  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.cancelBtnDiv button {
  color: red;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.cancelBtnDiv button:hover {
  border: 1px solid #4d77af;
  padding: 1% 1% 1% 1%;
}

.proceedDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.cancelproccedBtnDiv {
  background-color: red;
  padding: 10px 20px;
  color: white;
  border: none;
  cursor: pointer;
}

.proceedBtnDiv {
  background-color: green;
  padding: 10px 20px;
  color: white;
  border: none;
  cursor: pointer;
}

.selectContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  margin-bottom: 25px;
  /* background-color: #4d77af; */
  width: 100%;
}

.selectInput{
  width: 100%;
  padding: 15px;
  border-radius: 8px;
}
@media screen and (max-width: 800px) {
  .bookingModalDiv {
    width: 80%;
  }
}
