@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');
.parentCongrat{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1500; /* Ensure it appears above other components */
    left: 0;
    right: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.congratModalBody{
    background-color: #fff;
        padding: 5% 5% 5% 5%;
        border: 1px solid #888;
        width: 20%;   
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
}

.doneBtn{
    padding: 2% 4% 2% 4%;
    background-color: #4D77AF;
    border: 1px solid #4D77AF;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}

.iconColor{
    color: #AF9A21;
    
}

.congratText{
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 1.5em;
}

.congratSecondText{
    font-family: 'Poppins', sans-serif;
    font-size: 0.8em;
    color: #888;
}

@media screen and (max-width: 800px) {
   .congratModalBody{
    width: 80%;
   }
}