.popular_main_div{
    /* background-color: black; */
    /* margin-top: -3.5%; */
    padding: 5% ;
    background: linear-gradient(180deg, #dae2ec 0%, #ffffff 100%)
}
.city_main_parent{
    width: 70%;
    margin-left: 15%;
}
.city_main_parent hr{
    width: 100px;
    margin-bottom: 50px;
   
}
.city_main_parent h4{
    font-size: 1.5em;
}
.cityparent{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 350px;
    /* gap: 2rem; */
    margin: 4rem 5vw;
    place-items: center;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    
}
.city1{
    height: 350px;
    width: 300px;
    background-color: rebeccapurple;
    border-radius: 10px;
    position: relative;
}

.city1 img{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}
.city1 p{
position: absolute;
font-size: 1.3em;
background-color: black;
color: white;
text-transform: uppercase;
font-weight: 700;
opacity: 0.5;
line-height: 30px;
width: 100%;
top: 50%;
left: 0;

}


@media  screen and (max-width: 1650px) {
    .city_main_parent{
        width: 100%;
        /* background-color: red; */
        margin-left: 0;
    }
    .city1{
        width: 80%;
        height: 90%;
    }
    .popular_main_div{
        margin-top: -8%;
        padding-top: 0%;
        /* background-color: red; */
    }
   }

   @media  screen and (max-width: 750px){
    .popular_main_div{
        width: 85vw;
        /* background-color: rebeccapurple; */
        margin-top: -10%;
    }
   }
   @media  screen and (max-width: 600px){
    .popular_main_div{
        width: 85vw;
        overflow-x: hidden;
        /* background-color: rebeccapurple; */
        margin-top: -10%;
        margin-left: 0%;
        margin-right: 0%;
    }
    .city_main_parent{
        /* background-color: red; */
     
    }
   }

   @media  screen and (max-width: 500px){
    .popular_main_div{
        width: 90vw;
    }
   }