@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.containerSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
    /* background-position: ; */
    background-size: cover;
    margin-top: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.parent_summ {
    background-color: aliceblue;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 23px;
    text-align: center;
    width: 60%;
    opacity: 0.9;

}

.parent_summ .heardpart {
    border-bottom: 1px solid grey;
    /* padding-top: 20px; */
    /* margin-top: 10%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.parent_summ .detailPart {
    margin-top: 1%;
    text-align: justify;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.parent_summ .detailPart .NB {
    font-weight: 600;
    font-size: 1em;
}

.parent_summ .detailPart .textofNote {
    opacity: 1;
    font-size: 0.9em;
    color: gray;
    /* background-color: #2E2D90; */
    width: 95%;
    padding: 5px;
}

.parent_summ .detailPart .textofNote .email {
    color: rgb(153, 11, 11);
    font-weight: 400;
}

.parent_summ .detailPart h3 {
    color: #2E2D90;
}

.parent_summ .detailPart .locate {
    font-size: 1.2em;
    color: red;
}

.parent_summ .detailPart .imgDiv {
    background-color: rebeccapurple;
    width: 100%;
    height: 400px;
}

.parent_summ .detailPart .imgDiv img {
    width: 100%;
    height: 100%;
}

.parent_summ .detailPart .table th {
    font-weight: 200;
    padding: 8px;
    font-size: 1em;
    /* font-family: 'Poppins', sans-serif; */
    color: black;

}

.parent_summ .detailPart .table {
    font-weight: 200;
    /* background-color: antiquewhite; */
    width: 95%;
}

.parent_summ .detailPart .table tr {
    /* font-weight: 200; */
    background-color: #E4EBF2;

    width: 95%;
}

.parent_summ .detailPart .table tr td {
    padding: 8px;
    font-size: 1em;
    /* font-family: 'Poppins', sans-serif; */
    color: black;
    /* margin-bottom: 10px; */
}

.parent_summ span {
    font-size: 0.9em;
}

.tableDiv3 {
    margin-top: 2%;
    border-top: 2px solid grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* background-color: rebeccapurple; */
}


.table2 {
    border-spacing: 0;
    border-collapse: separate;
}

.tableDiv3 .table2 thead tr th {
    background-color: #2E2D4D;
    color: #E4EBF2;
    padding: 8px;

    /* margin: 0; */
}

.table2 {
    width: 100%;

    padding: 4px;
    /* background-color: bisque; */
}

.tableDiv3 .table2 .bookingDetailRow {
    font-weight: 200;
    background-color: #E4EBF2;

    /* width: 95%; */
}

.tableDiv3 .table2 .bookingDetailRow td {
    margin-top: 2px;
    padding: 10px;
}

.totBill {
    color: #765584;
    background-color: #7a7994;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.totBillDescrib {
    background-color: #E4EBF2;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.totalAmount {
    color: green;
    background-color: #7a7994;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.totalAmountDescrib {
    background-color: #E4EBF2;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.disAmount {
    color: red;
    margin-top: 7px;
    margin-bottom: 7px;
    background-color: #7a7994;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.disAmountDescrib {
    background-color: #E4EBF2;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.totPayment {
    color: #2E2D90;
    background-color: #7a7994;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.total_describ {
    background-color: #E4EBF2;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.amountStyleDiv {

    text-align: justify;
}


.tax-container_Summary {
    /* background-color: rebeccapurple; */
    padding: 2% 0 2% 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tax-item_summary {
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.taxLabel_summary {
    /* background-color: rgb(31, 95, 95); */
    width: 13%;
    text-align: justify;
}

.taxAmount_summary_summary {
    /* background-color: red; */
    width: 10%;
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: flex-end;
}


@media screen and (max-width: 1000px) {
    .containerSummary {
        margin-top: 10%;
        padding-top: 4%;
        padding-bottom: 4%;
    }

    .parent_summ {

        width: 80%;
        opacity: 0.9;

    }

    .parent_summ .detailPart {
        flex-direction: column;
        justify-content: space-between;
        /* background-color: #2E2D4D; */
        width: 100%;
    }

    .parent_summ .detailPart .table {
        width: 100%;
        margin-bottom: 2%;
    }

    .tableDiv3 {
        margin-top: 10%;
        /* background-color: white; */

        padding-bottom: 2%;
        padding-top: 7%;
    }

    .totBillDescrib,
    .disAmountDescrib,
    .totalAmountDescrib,
    .tax-item_summary,
    .total_describ {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .containerSummary {
        margin-top: 17%;
        padding-top: 4%;
        padding-bottom: 4%;
    }

    .parent_summ {

        width: 80%;
        opacity: 0.9;

    }

    .tableDiv3 {
        margin-top: 10%;
        /* background-color: white; */
        /* width: 600px ; */

        /* height: 75% ; */
        min-width: 100%;
        overflow-x: auto;
        padding-bottom: 2%;
        padding-top: 7%;
        position: relative;
        opacity: 0.8;

    }

    /* .table2{
        width: 600px;
    } */


}