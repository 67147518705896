@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.parentBlogList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: rgb(22, 125, 216); */
  width: 100%;
  height: 93vh;
  margin-top: 5%;
  font-family: "Poppins", sans-serif;
  padding: 40px 0;
  background-image: url("../../../assets/Moon\ \(2\).svg");
  background-repeat: no-repeat;
  /* background-position: ; */
  background-size: cover;
}

.blogListDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  /* margin-top: 10%; */
  /* background-color: antiquewhite; */
  height: 100%;
}

.blogTable {
  width: 100%;
  border-collapse: collapse;
}

.blogTable th,
.blogTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.blogTable th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.editBtn,
.deleteBtn {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
}

.editBtn {
  background-color: #4caf50;
  color: white;
}

.deleteBtn {
  background-color: #f44336;
  color: white;
}

.actionBtn {
  position: relative;
  background-color: green;
  cursor: pointer;
  color: white;
  padding: 10px;
  width: 100%;
  border: none;
}

.optionspan {
  padding: 5px;
  border-bottom: 1px solid silver;
  width: 100%;
  cursor: pointer;
}

.optionspan:hover{
    background-color: #2E2D4D;
    color: white;
}

.actionMenu {
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20;
  width: 6% auto;
  height: 50px auto;
  background-color: #f0f8ff;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media screen and (max-width: 750px) {
  .parentBlogList{
    /* width: 80%; */
    background-color: #ddd;
    overflow-x: scroll;
  }
  .blogListDiv{
    /* width: 100%; */
    width: 80%;
    
  }
}
