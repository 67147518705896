@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.history__parent{
    background-image: url("../../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
    /* background-position: ; */
    background-size: cover;
    width: 100%;
    height: 100vh auto;
}

.history__p{
    position: relative;
    height: 100vh;
    margin-top: -5%;
    /* overflow-y: scroll; */
    /* background-color: red; */
}

.history__header{
    margin-left: -40%;
    text-transform: capitalize;
    position: relative;
    font-family: sans-serif;
    /* background-color: #2b2626; */
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    /* width: 50%; */
   
}
.offLine{
    background-color: rgb(243, 65, 65);
    color: white;
    width: 50%;
    margin-top: 2%;
    margin-left: 5%;
    font-family: 'Poppins', sans-serif;
    font-size: 0.7em;
    opacity: 0.9;
}

.__header2{
    font-size: 1em;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 10px;
    width: 50%;
    height: 40vh;
    margin-left: 5%;
    /* border: 1px solid #96a6b6; */
    background-color: whitesmoke;
    position: relative;
    border-radius: 5px;
    /* border: 2px solid black;
    padding-left: 20px; */
    /* padding-left: 4px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.__header2:before{
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
    border: 3px solid #ddd;
    position: absolute;
    top: 10px;
    left: -74px;
    
}

.__header2:after{
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent #2b2626 transparent transparent ;
    border-width: 8px;
    position: absolute;
    left: -17px;
    top:10px
}

.createdDate{   
    /* background-color: rebeccapurple; */
    margin-left: -75%;
    font-family: sans-serif;
    font-size: 0.8em;
    font-weight: 600;
}

.history__parent2{
    margin-top: 10%;
    background-color: white;
    width: 70% auto;
    margin-left: 30%;
    height: 75% ;
    overflow-y: scroll;
    padding-bottom: 2%;
    padding-top: 7%;
    position: relative;
    opacity: 0.8;
    border-left: 2px solid black;
}

.history__parent2 table:before{
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
    border: 3px solid #ddd;
    position: absolute;
    top: 10px;
    left: -74px;
}

.history__parent2 table:after{
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent #2b2626 transparent transparent ;
    border-width: 8px;
    position: absolute;
    left: -17px;
    top:10px
}

.history__parent2 table{
    margin-top: 2%;
    margin-bottom: 2%;
    width: 50% ;
    margin-left: 5%;
    /* border: 1px solid #96a6b6; */
    background-color: whitesmoke;
    position: relative;
    border-radius: 5px;
    /* border: 2px solid black;
    padding-left: 20px; */
    /* padding-left: 4px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.tableHeader{
    width: 96% ;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #96a6b6;

    padding: 10px;
    /* margin-bottom: 3%; */
}
.tableHeader span{
    font-size: 0.6em;
    color: rgb(42, 58, 58);
    
}



.history__header:before{
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
    border: 3px solid #ddd;
    position: absolute;
    top: 0.5%;
    left: 28.1%;
}

.hotelNameDivLoc{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.tableData{
    width: 96% ;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #96a6b6;
    /* background-color: red; */
    /* margin-bottom: 3%; */
    /* padding-bottom: 10px;
    padding-top: 10px; */
    
    padding: 10px;
    /* padding-left: 5px; */
    
}

.tableData2{
    background-color: #96a6b6;

}

.tabledata3{
    border-bottom: none;
    /* text-align: center; */
}

.roomtyp{
    /* background-color: rgb(0, 0, 0); */
    color: white;
    /* padding-left: 0; */
    text-align: justify;
    width: 15%;
}



.tableData td{
    font-size: 0.8em;
    font-family: sans-serif;
    color: #4A4C56;
}

.tableData .reserv{
    color: green;
}

.cancelReserve{
    background-color: red;
    border: 1px solid red;
    color: white;
    cursor: pointer;
    padding: 3px 10px;
    border-radius: 2px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.7em;
}

@media screen and (max-width: 1650px) {
    .history__header:before{
        /* left: 440px; */
        left: 28%;
        top: 0%;
    }
    .history__parent2 table:before{
        left: -11.6%;
    }
}

@media screen and (max-width: 1000px){
    .history__parent2{
        margin-top: 15%;
    }
    .tableData{
        width: 90%;
    }
    .tableHeader{
        width: 90%;
    }
    .createdDate{
        margin-left: -40%;
    } 
    .history__header:before{
        /* left: 440px; */
        left: 27.3%;
        top: 0%;
    }
    .history__parent2 table:before{
        left: -13.5%;
    }
}

@media screen and (max-width: 600px){
    .history__parent2{
        margin-top: 30%;
        width: 98%;
        margin-left: 1%;
        /* background-color: red; */

    }

    .history__header{
        font-weight: 500;
        font-size: 1em;
    }
    .sideBar__div{
        display: none;
    }
    .history__parent2 table{
        width: 96%;
        margin-left: 2%;
    }
    .createdDate{
        margin-left: -24%;
    } 
    .history__parent2 table:before{
        left: -4.5%;
    }
    .history__header:before{
        /* left: 440px; */
        left: 27%;
        top: 0%;
    }
    .tableHeader{
        width: 90%;
    }
    .tableData{
        width: 90%;
    }
}