@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.parent_login {

  height: 100vh;
  font-family: "Montserrat", sans-serif;
}

.containerLogin2 {
  /* position: absolute; */
  /* transform: translate(-50%, -25%); */
  /* top: 40%;
    left: 50%; */
  /* background-color: red; */
}

.login_form {
  background: #2E2D4D;
  opacity: 0.8;
  margin-left: 20px;
  padding: 3em;
  height: 465px;
  border-radius: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  /* -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2); */
  text-align: center;
  position: relative;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login_form button {
  background: #CED82A;
  width: 200px;
  height: 35px;
  cursor: pointer;
  padding: 1em;
  margin-bottom: 1em;
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5000px;
  /* margin-left: 8%; */
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);

  color: #fff;
  font-family: Montserrat, sans-serif;

  transition: all 0.2s ease-in-out;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.load {
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
}

.login_form p {
  font-weight: 500;
  font-family: monospace;
  color: #fff;
  opacity: 0.7;
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 20%;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
}



.login_form .error {
  color: red;
  font-size: 1em;
  margin-left: -10%;
  margin-top: -10%;
  margin-bottom: 0%;
}

.login_form a {
  text-decoration: none;
  color: #ddd;
  font-size: 12px;
  margin-top: -5%;
}

.login_form a:hover {
  text-shadow: 2px 2px 6px #00000040;
}

.login_form a:active {
  text-shadow: none;
}

.login_form input {
  /* background: #E7EFFD; */
  width: 250px;
  padding: 0.8em;
  /* margin-bottom: 1em; */
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5000px;
  /* -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2); */
  color: #000000;
  font-family: monospace;
  font-weight: 500;
  font-size: 1.3em;
  transition: all 0.2s ease-in-out;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
}

.login_form label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  color: whitesmoke;
}

.required-field::after {
  content: "*";
  color: red;
  margin-left: 5px;
}

.login_form .passwordInput {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.forgetInput {
  display: none;
}

.login_form .togglePass2 {
  position: absolute;
  margin-left: 90%;
  margin-top: 5.6%;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.login_form .togglePass {
  position: absolute;
  margin-left: 90%;
  margin-top: 5.6%;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.login_form input:hover {
  /* background: rgba(255, 255, 255, 0.1); */
  box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
}

.login_form input[type=email]:focus,
form input[type=password]:focus {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
}

.login_form input[type=button] {
  margin-top: 10px;
  width: 150px;
  font-size: 1.5em;

}

.login_form input[type=button]:hover {
  cursor: pointer;
}

.login_form input[type=button]:active {
  background: rgba(255, 255, 255, 0.2);
}

.login_form:hover {
  margin: 4px;
}



.signinIcon {
  margin-right: 10%;
  font-size: 1.5em;
}

.drop {
  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 10px 10px 60px -8px rgba(0, 0, 0, 0.2);
  position: absolute;
  transition: all 0.2s ease;
}

.drop-1 {
  height: 80px;
  width: 80px;
  top: -20px;
  left: -40px;
  z-index: -1;
}

.drop-2 {
  height: 80px;
  width: 80px;
  bottom: -30px;
  right: -10px;
}

.drop-3 {
  height: 100px;
  width: 100px;
  bottom: 120px;
  right: -50px;
  z-index: -1;
}

.drop-4 {
  height: 120px;
  width: 120px;
  top: -60px;
  right: -60px;
}

.drop-5 {
  height: 60px;
  width: 60px;
  bottom: 170px;
  left: 90px;
  z-index: -1;
}

a,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.regHere {

  width: 80%;
  /* margin-bottom: 10%; */
  position: absolute;
  top: 85%;
  color: white;
  padding: 5px;
  font-size: 0.8em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.regHere a {
  text-decoration: none;
  font-family: monospace;
  /* background: white; */
  border-bottom: 2px solid white;
  padding: 2px;
  border-radius: 3px;
  color: red;
  font-size: 1.3em;

}

.loginSwapLog {
  /* background-color: red; */
  background-image: url("../../assets/Moon\ \(2\).svg");
  background-repeat: no-repeat;
  /* background-position: ; */
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* width: 100%; */
}

.loginIm {
  border-radius: 10px;
  padding-top: 10px;
  width: 20%;
  height: 50%;
  background-color: transparent;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.loginIm img {
  width: 100%;
  margin-top: 15%;
}

.regtxt {
  /* background-color: white; */
  color: red;
}

.indicateError {
  background-color: red;
  margin-top: -5%;
  text-transform: capitalize;
  width: 50%;
  border-radius: 5px;
  color: white;
  font-size: 0.7em;
  padding: 5px;
  opacity: 0.5;
  transition: all 0.2s ease-in-out
}

@media screen and (max-width: 1650px) {
  .loginIm {
    height: 77%;
    width: 30%;
  }

  /* .login_form {
      margin-top: 30%;
    } */
  .loginSwapLog {
    margin-top: 5%;
  }
}

@media screen and (max-width: 600px) {
  .containerLogin2 {
    width: 100%;
    margin-left: 0;
    padding: 0;
  }

  .parent_login {
    overflow-x: hidden;
  }

  .loginSwapLog {
    width: 100%;
    margin-left: -2.8%;
    padding: 0;
  }

  .loginIm {

    display: none;
  }

  .login_form input {
    width: 200px;
  }
}