@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');
.parentCongrat{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1500; /* Ensure it appears above other components */
    left: 0;
    right: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.congratModalBody{
    background-color: #fff;
        padding: 2% 5% 5% 5%;
        border: 1px solid #888;
        width: 20%;   
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        
}

.crossBtb{
    /* background-color: #4D77AF; */
    width: 100%;
    position: absolute;
    top: 0%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.crossBtb button{
    background-color: red;
    border: 1px solid red;
    padding: 1% 3% 1% 3%;
    color: white;
    cursor: pointer;
    font-size: 1.3em;
}

.warningNote{
    font-family: 'Poppins', sans-serif;
    font-size: 0.7em;
    width: 100%;
    text-align: justify;
   color: #96a6b6;
}

.cancel_form{
    border: 1px solid #fcfcfc;
    background:#fcfcfc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 14px; 
}

.cancel_form input{
    margin-bottom: 15px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid grey;
    width: 90%;
    padding-left: 10px;
}

.cancel_form select{
    margin-bottom: 15px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid grey;
    width: 94%;
    padding-left: 10px;
}

.cancel_form input::placeholder{
    color: #2E2D4D;
    font-family: 'Times New Roman', Times, serif;
}

.cancel_form select::placeholder{
    color: #2E2D4D;
    font-family: 'Times New Roman', Times, serif;
}

.cancel_form textarea{
    margin-bottom: 15px;
    padding-left: 10px;
    padding-top: 10px;
    border-radius: 5px;
    border: 1px solid grey;
    width: 90%;
}

.cancel_form textarea::placeholder{
    color: #2E2D4D;
    font-family: 'Times New Roman', Times, serif;
}
.cancel_form button {
    width: 90%;
    padding: 10px;
    color: white;
    background: #2E2D4D;
    border: none;
    border-radius: 5px;
}

.cancel_form button:hover{
    cursor: pointer;
    background: #7471c0;
}

.cancel_form .error{
    color: red;
    font-size: 0.7em;
    margin-left: -30%;
    margin-top: -4%;
  }

.doneBtn{
    padding: 2% 4% 2% 4%;
    background-color: #4D77AF;
    border: 1px solid #4D77AF;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}

.iconColor{
    color: #AF9A21;
    
}

.congratText{
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 1.5em;
}

.congratSecondText{
    font-family: 'Poppins', sans-serif;
    font-size: 0.8em;
    color: #888;
}

@media screen and (max-width: 800px) {
   .congratModalBody{
    width: 80%;
   }
}