@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
.parentBlog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.blogherosection {
  /* height: 80vh; */
  width: 100%;
}

.blogimgtext {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
}

.blogimgtext h3 {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 4em;
  margin-bottom: 1%;
  text-transform: capitalize;
}

.blogimgtext .blogimgtextparadiv {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1%;
}

.blogimgtext .blogimgtextparadiv p {
  width: 50%;
  /* display: flex;
    align-items: flex-start;
    justify-content: flex-start; */
  text-align: left;
}

.blogimgtext .blogpostauthor {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  margin-bottom: 1%;
}

.blogimgtext .blogpostauthor .postauthorDetails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  gap: 20px;
}

.blogimgtext .blogpostauthor .authorimg {
  background-color: antiquewhite;
  border-radius: 100%;
  height: 75px;
  width: 10%;
}

.blogimgtext .blogpostauthor .authorimg img {
  border-radius: 100%;
  height: 100%;
  width: 100%;
}

.blogheroimage {
  height: 700px;
  width: 80%;
  object-fit: contain;
  overflow: hidden; /* Ensures no overflow of the image */
}

.blogheroimage img {
  /* object-fit: contain; */
  height: 100%;
  width: 100%;
  /* Ensures the whole image is visible while maintaining aspect ratio */
  object-position: center; /* Centers the image in the container */
  background-color: #ffffff; /* Optional: Adds a background color for any empty space */
}

.firstparagraph {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* gap: 20px; */
  /* background-color: antiquewhite; */
}

.postcontent {
  width: 55%;
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  color: #1a1b65;
  /* background-color: rgb(136, 123, 106); */
}

.postcontent p {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.postcontent .paragraphImg {
  width: 100%;
  height: 370px;
}

.postcontent .paragraphImg img {
  width: 100%;
  height: 100%;
}

.trendingComdiv {
  width: 42%;
  background-color: aliceblue;
  /* border: 2px solid green; */
  height: auto;
  padding: 15px;
}

.newpostDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: aliceblue;
  /* padding: 25px; */
}

.newpostDiv h2 {
  width: 100%;
  text-align: justify;
  font-size: 1.7em;
}

.parentTrendingPost {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.someTrendingPost {
  width: 100%;
  height: 180px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid grey;
  gap: 20px;
}

.trendingpostimg {
  height: 100%;
  width: 35%;
}

.trendingpostimg img {
  height: 100%;
  width: 100%;
}

.trendingdetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 60%;
}

.trendingdetails .blogDate {
  font-family: "Poppins", sans-serif;
  font-size: 0.8em;
  margin-bottom: 2px;
}
.trendingdetails .trendingBlogTitle {
  font-family: "Poppins", sans-serif;
  font-size: 1.2em;
  text-transform: capitalize;
  font-weight: 600;
}

.trendingdetails .trendingContent {
  font-family: "Poppins", sans-serif;
  font-size: 0.8em;
  color: #7a7a7a;
}

.trendingdetails p {
  text-align: justify;
  font-size: 0.8em;
}

.commentSection {
  width: 100%;
  /* background-color: antiquewhite; */
  margin-top: 10%;
  border-top: 1px solid gray;
  /* padding: 20px; */
}

.commentSection h3 {
  /* background-color: #1A1B65; */
  text-align: justify;
}

.commentListSec {
  /* border-bottom: 1px solid grey; */
  padding-bottom: 25px;
}

.commentNamedate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}

.blogPostDate {
  font-size: 0.8em;
}

.eachComment {
  border-bottom: 1px solid gray;
  margin-bottom: 2%;
}
.commentBioparentdiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
}

.mainComment {
  padding-left: 30px;
  color: #5f677b;
  font-size: 0.9em;
}

.commentiinputdiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  /* background-color: beige; */
}

.commentiinputdiv input {
  width: 95%;
  height: 39px;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 0 10px 0 10px;
}

.commentiinputdiv label {
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
  font-size: 0.9em;
}

.commentparentdiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  /* background-color: #7374ac; */
}

.textareadiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.textareadiv label {
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
  font-size: 0.9em;
}

.textareadiv textarea {
  width: 97.6%;
  height: 160px;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 10px 10px 10px 10px;
}

.commentsubmitbtn {
  /* background-color: azure; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.commentsubmitbtn button {
  background-color: #1a1b65;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.blogsubscribemailparent {
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  width: 100%;
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subSectionTitle {
  /* background-color: #5a5a5b; */
  text-transform: capitalize;
  text-align: start;
}

.blogsubscribemail {
  width: 90%;

  padding: 10px 20px;
}

.blogsubscribemail h3 {
  font-size: 3em;
  text-align: left;
  margin-bottom: 2%;
  margin-top: 2%;
}

.blogsubscribemail p {
  font-size: 1em;
  font-weight: 600;
  width: 85%;
  text-align: left;
  color: #5a5a5b;
}

.blogsubscribemail .email {
  background-color: aliceblue;
  padding: 30px 10px;
}

.blogsubscribemail .email button {
  background-color: #1a1b65;
  color: white;
  width: 28%;
  height: 44px;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid gray;
  cursor: pointer;
}

.blogsubscribemail .email input {
  height: 40px;
  width: 60%;
  padding: 0px 10px 0px 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid gray;
}

.trendingblogpostfooter {
  position: relative;
  display: block;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin: 10px; */
  /* margin-bottom: 100%; */
}

.trendingblogpostfooter a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 10px; */
}

.trendingblogpostfooter .trendblogreadMore {
  /* position: absolute; */
  /* margin-bottom: 100px; */
  /* top: 670px; */
  background-color: #3f3e5c;
  /* left: 10px; */
  color: white;
  padding: 6px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.trendingblogpostfooter .trendblogreadMore:active {
  box-shadow: 0 2px 6px #717ad9;
}

@media screen and (max-width: 750px) {
  .blogimgtext h3 {
    font-size: 2em;
    margin-top: 22%;
  }

  .blogimgtext .blogimgtextparadiv p {
    width: 100%;
  }
  .authorimg {
    display: none;
  }
  .firstparagraph {
    flex-direction: column;
  }
  .postcontent {
    width: 100%;
  }
  .trendingComdiv {
    width: 100%;
    margin-top: 4%;
  }

  .someTrendingPost {
    flex-direction: column;
    height: auto;
  }

  .trendingpostimg {
    height: 100%;
    width: 100%;
  }

  .trendingdetails {
    /* background-color: rebeccapurple; */
    width: 95%;
    padding: 10px;
  }

  .blogheroimage {
    height: 350px;
  }

  .postcontent .paragraphImg {
    height: 200px;
  }
}
