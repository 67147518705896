@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
.addendumDiv{
    font-family: 'Poppins', sans-serif;
}
.annexure{
    background-color: #F7F7F7;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    font-size: 1.1em;
    padding: 10px 0 10px 0;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: black;
}

.listNum{
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.addendumDiv ol li{
    font-size: 0.9em;
    
}

