.parentSummary{
    width: 100%;

    height: auto 100vh ;
    position: relative;
    margin-top: 10%;
   margin-bottom: 5%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-image: url("../../assets/Moon\ \(2\).svg");
   background-repeat: no-repeat;
   /* background-position: ; */
   background-size: cover;
}

.invoice-box2 {
    width: 50%;    

    background-color: white;
    opacity: 0.9;
    padding: 3%;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
}

.hotName2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: justify;
    background: #2E2D4D;
    color: white;
    font-weight: 600;
}

.receiptDiv{
    background-color: #E4EBF2;
    display: flex;
    justify-content: flex-end;
    margin-top: 2%;
}

.receiptDiv .downloadRep{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #674277;
    color: white;
   
    border: none;
    border-radius: 3px;
    padding: 1.3%;
    cursor: pointer;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-between;
}

.hotelplace{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 100%;
    width: 80%;
    background-color: #2E2D4D;
    padding: 10%;
}

.hotelplace img{
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.invoice-box2 table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box2 table td {
    padding: 5px;
    vertical-align: top;
}

.invoice-box2 table tr td:nth-child(2) {
    text-align: right;
}

.invoice-box2 table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box2 table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box2 table tr.top table td.title2{
    /* background-color: rebeccapurple; */
    width: 50%;
    font-size: 0.9em;
    

}

.invoice-box2 table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box2 table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    text-align: center;
}

.invoice-box2 table tr.details td {
    padding-bottom: 20px;
}

.invoice-box2 table tr.item td {
    border-bottom: 1px solid #eee;
    text-align: center;
}

.invoice-box2 table tr.item.last td {
    border-bottom: none;
}

.invoice-box2 table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

.invoice-box2 table tr.total .totBill{
    color: green;
}

.invoice-box2 table tr.total .disAmount{
    color: red;
}

.invoice-box2 table tr.total .totPayment{
    color: #2E2D4D;
}

@media only screen and (max-width: 1650px){
    .parentSummary{
        /* margin-top: 20%; */
    }
}


/** RTL **/
.invoice-box2.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box2.rtl table {
    text-align: right;
}

.invoice-box2.rtl table tr td:nth-child(2) {
    text-align: left;
}

@media screen and (max-width: 1000px) {
    .total{
       
        width: 100vw;
    }
    .totBill{
        /* background-color: aliceblue; */
        width: 100%;
    }

    .invoice-box2{
        overflow: scroll;
    }
}

@media screen and (max-width: 600px) {
    .parentSummary{
        margin-top: 35%;
        /* background-color: rebeccapurple; */
    }
    .invoice-box2{
        width: 85vw;
    }
    .invoice-box2 table tr.top table td.title2{
        width: 100%;
    }
    .invoice-box2 table tr.total td:nth-child(2){
        width: 100%;
        /* background-color: rebeccapurple; */
        font-size: 0.6em;
        color: red;
    }
    .invoice-box2 table tr.heading td{
        font-size: 0.8em;
        font-family: monospace;
    }
    .invoice-box2 table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box2 table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
   
}