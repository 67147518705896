.parent_register {
    
    height: 100vh;
    font-family: "Montserrat", sans-serif;
  
  }
  
  .container {
    margin-top: 10%;
 
    margin-bottom: 10%;
    /* background-color: red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container2{
    /* margin-left: 2%; */
    margin-bottom: 10%;
    display: none;
  }

  .bckBtn{
    font-size: 2em;
    cursor: pointer;
    color: white;
  }

  .parent_form_contain{
    display: flex;
    flex-direction: row;
  
    background-image: url("../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
    /* background-position: ; */
    background-size: cover;
   
    width: 100%;
    height: 100vh auto;
    /* position: relative; */
  }

  .registerbtn{
    background: gainsboro;
    cursor: not-allowed;
  }
  
  .register_form {
    position: relative;
    background: #2E2D4D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.9;
    padding: 3em;
    height: 320px auto;
    width: 17%;
    border-radius: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    /* -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2); */

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    position: relative;
    transition: all 0.2s ease-in-out;
  }

  .register_form img{
    width: 100%;
    margin-bottom: 10%;
  }

  .selectGender{
    border-radius: 5px;
  }

  .register_form .error{
    color: red;
    font-size: 0.7em;
    margin-left: -50%;
    margin-top: -7%;
  }
  .register_form p h1{
    font-weight: 700;
    color: #fff;
    font-size: 2rem;
    margin-top: 0;
  
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  .register_form a {
    text-decoration: none;
    color: #ddd;
    font-size: 12px;
  }
  .register_form a:hover {
    text-shadow: 2px 2px 6px #00000040;    
  }
  .register_form a:active {
    text-shadow: none;
  }
  .register_form input {
    /* background: #EAF1FE; */
    width: 250px;
    height: 40%;
    padding: 1em;
    /* margin-bottom: 1em; */
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5000px;
    /* -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2); */
    color: black;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 0.8em;
    transition: all 0.2s ease-in-out;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
  }

  .register_form textarea{
    /* background: #EAF1FE; */
    width: 250px;
    height: 40%;
    padding: 1em;
    margin-bottom: 1em;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    /* border-radius: 5000px; */
    /* -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2); */
    color: black;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 0.8em;
    transition: all 0.2s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .title_gender{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
    width: 270px;
  }

  .required-field__::after {
    content: "*";
    color: red;
    margin-left: 5px;
}

.register_form label{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.7em;
  color: whitesmoke;
  /* background-color: rebeccapurple; */
  width: 100%;
}

  .register_form select {
    /* background: #EAF1FE; */
    width: 120px;
    height: 40%;
    padding: 1em;
    margin-bottom: 2em;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    /* border-radius: 5000px; */
    /* -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2); */
    color: #000;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 0.8em;
    transition: all 0.2s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    margin-right: 5%;
    margin-left: 5%;
  }
  .register_form .passwordInput{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 270px;
    height: 40%;
    /* margin-left: 20%; */
  }

  .register_form .togglePass{
    position: absolute;
   margin-left: 20%;
   margin-top: -7%;
    color: black;
    cursor: pointer;
  }

  .register_form select option{
    /* background-color:#EAF1FE; */
    color: #000;
    
  }

  .register_form button{
    background: #CED82A;
    width: 200px;
    height: 40px;
    cursor: pointer;
    padding: 1em;     
    margin-bottom: 1em;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5000px;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  .register_form input:hover {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
  }

  .register_form select:hover{
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
  }
  .register_form input[type=email]:focus, form input[type=password]:focus {
    /* background: rgba(255, 255, 255, 0.1); */
    box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
  }
  .register_form input[type=button] {
    margin-top: 10px;
    width: 150px;
    font-size: 1rem;
  }
  .register_form input[type=button]:hover {
    cursor: pointer;
  }
  .register_form input[type=button]:active {
    background: rgba(255, 255, 255, 0.2);
  }
  .register_form:hover {
    margin: 4px;
  }
  
  /* ::-moz-placeholder {
    font-family: monospace;
    font-weight: 200;
    color: #000;
   
  }
  
  :-ms-input-placeholder {
    font-family: monospace;
    font-weight: 200;
    color: #000;
    
  }
  
  ::placeholder {
    font-family: monospace;
    font-weight: 200;
    color: #000;
   
  } */
  
  .drop {
    background:#245ab1;
    opacity: 0.3;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 10px 10px 60px -8px rgba(0, 0, 0, 0.2);
    position: absolute;
    transition: all 0.2s ease;
  }
  .drop-1 {
    height: 80px;
    width: 80px;
    top: 2%;
    left: -8%;
    z-index: -1;
  }
  .drop-2 {
    height: 80px;
    width: 80px;
    bottom: -30px;
    right: -10%;
  }
  .drop-3 {
    height: 100px;
    width: 100px;
    bottom: 120px;
    right: -10%;
    z-index: -1;
  }
  .drop-4 {
    height: 120px;
    width: 120px;
    top: -60px;
    right: -10%;
  }
  .drop-5 {
    height: 60px;
    width: 60px;
    bottom: 170px;
    left: -8%;
    z-index: -1;
  }
  
  a,
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
  .RegImg img{
    height: 100%;
    width: 100%;
  }

  .RegImg{
    background-color: red;
    width: 50%;
    height: 80%;
    margin-top: 10%;
    margin-left: 20%;

  }

  .regplace{
    /* height: 100px; */
    /* height: 320px */
  }
  @media screen and (max-width: 1650px){
    .register_form {
      width: 25%;
    }
  }


  @media screen and (max-width: 750px){
    .parent_form_contain{
      width: 100%;
      margin-right: 0;
      margin-left: 0%;
      margin-top: 40%;
      /* background-color: red; */
    }
    .register_form{
      margin-top: -15%;
      width: 70%;
    }
    .loginImg2{
      display: none;
    }
    .drop-1, .drop-2, .drop-3, .drop-4, .drop-5{
      display: none;
    }
  }

  @media screen and (max-width: 860px){
.loginImg2{
  width: 50%;
  margin-left: -2%;
}
.register_form{
  width: 60%;
}
  }