@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Source+Sans+Pro:wght@200&display=swap");

.parent_hotel_book {
  height: 100vh auto;
  width: 80% auto;
}

.parent_hotel_book h2 {
  color: #2e2d4d;
  /* background-color: rebeccapurple; */
}

.form {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  width: 100% auto;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.form1 {
  /* display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-flow: 10px; */
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 1px solid RGB(189, 189, 189);
  /* background-color: #CED82A; */
}

.form1:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}

.enter_num_button {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;

  border: 1px solid RGB(189, 189, 189);
}

.pickDate {
  margin-top: 4%;
  width: 70%;
  height: 50px;

  box-sizing: border-box;
  padding-left: 20px;
}

.pickDate2 {
  /* color: gray; */
  text-align: center;
  font-weight: 700;
}

.book_img {
  height: 150px;
  width: 160px;
  border-radius: 10px;
  /* background-color: red; */
}

.book_img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.room_type {
  display: grid;
  grid-template-columns: 1fr 2fr;

  width: 100%;
}

.namediv {
  width: 22rem;
  height: 50% auto;
}

.room_type .roomNamePlc {
  padding-left: 6px;
  font-size: 0.9rem;
  font-weight: 800;
  color: #2e2d4d;
  text-transform: capitalize;
}

.room_type .roomNamePlc .nameQty {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.room_type .roomNamePlc .nameQty .availableQty {
  font-size: 0.5rem;
  font-weight: 400;
  color: #ffffff;
  background-color: #e24c5e;
  padding: 5px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: "Poppins", sans-serif;
}

.room_type .roomNamePlc {
  padding-left: 6px;
  font-size: 0.9rem;
  font-weight: 800;
  color: #2e2d4d;
  text-transform: capitalize;
}

.room_type .roomNamePlc .nameQty {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.room_type .roomNamePlc .nameQty .availableQty {
  font-size: 0.5rem;
  font-weight: 400;
  color: #ffffff;
  background-color: #e24c5e;
  padding: 5px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: "Poppins", sans-serif;
}

.room_type_price {
  /* background-color: #5d5f41; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-start; */
}

/* .roomNamePlc{
    
    font-weight: 800;
    color: #2E2D4D;
    text-transform: capitalize;
} */
/* 
.horizon{
    height: 100%;
    background: url(/src/assets/horizontalLine.png)   ;
    border: none;
    width: 100%;
    
} */
.horizon {
  width: 100%;
}

.room_type_price p {
  font-size: 0.9rem;
  margin-left: 1.5%;
  font-weight: 400;
}

.room_type_price p {
  font-size: 0.9rem;
  margin-left: 1.5%;
  font-weight: 400;
}

.room_type_price i {
  font-size: 0.7rem;
}

.price_span {
  font-size: 1.1em;
  font-weight: 600;
  color: #2e2d4d;
  font-family: "Poppins", sans-serif;
}

.total {
  width: 100%;
}

.total .totalPrice {
  font-size: 1.1em;
  font-weight: 600;
  color: #222136;
  font-family: "Poppins", sans-serif;
}

.Book_now {
  width: 100%;
  background-color: #2e2d4d;
  border: none;
  color: RGB(255, 255, 255);
  padding: 20px;
  font-family: "Times New Roman", Times, serif;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.Book_now2 {
  width: 100%;
  background-color: #2e2d4d;
  border: none;
  color: RGB(255, 255, 255);
  padding: 20px;
  font-family: "Times New Roman", Times, serif;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;

  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.cant__bok {
  width: 100% auto;
  background-color: #2e2d4d;
  border: none;
  color: red;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  cursor: not-allowed;

  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.cant__bok2 {
  width: 100% auto;
  background-color: #2e2d4d;
  border: none;
  color: red;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  cursor: not-allowed;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.disableBtn {
  padding: 5px;
  width: 11em;
  outline: none;
  border: 1px solid #2e2d4d;
  border-radius: 7px;
  /* color: #2E2D4D; */
  color: #fff;
  background-color: grey;
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 600;
  font-family: "Times New Roman", Times, serif;
  /* margin-left: 50%; */
  cursor: not-allowed;
  /* margin-left: 45%; */
}

.addRoomDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.monyDiv {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  width: 43%;
  /* background-color: rebeccapurple; */
}

.payOpt2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: justify;
}

.payOpt2 .textOnline {
  font-size: 0.8em;
  font-weight: 600;
  color: red;
  font-family: "Poppins", sans-serif;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

.payOpt2 .textOnline {
  animation: bounce 2s infinite;
}

.payOpt2 i {
  font-size: 0.6em;
  font-weight: 400;
  color: #7a78bd;
  font-family: "Poppins", sans-serif;
}

.payOpt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  text-align: justify;
}

.payOpt .textOpt {
  font-size: 0.8em;
  font-weight: 600;
  color: #2e2d4d;
  font-family: "Poppins", sans-serif;
}

.payOpt i {
  font-size: 0.6em;
  font-weight: 400;
  color: #7a78bd;
  font-family: "Poppins", sans-serif;
}

.flatRatePrice {
  font-size: 1em;
  font-weight: 600;
  /* font-style: italic; */
  color: #2e2d4d;
  font-family: "Poppins", sans-serif;
}

.roomCheck {
  padding: 5px;
  width: 20%;
  margin-left: 2%;
  height: 45px;
  outline: none;
  border: 1px solid #2e2d4d;
  border-radius: 7px;
  color: #2e2d4d;
  background-color: RGB(255, 255, 255);
  font-size: 0.8em;
  text-transform: capitalize;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.roomCheck:hover {
  cursor: pointer;
  background-color: #2e2d4d;
  color: white;
}

.truncated-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  /* display: block; */
  font-size: 0.6em;
  padding: 0px 5px 0px 5px;
  font-family: "Poppins", sans-serif;
}

.full-text {
  white-space: normal;
  overflow: visible;
  font-size: 0.6em;
  padding: 0px 5px 0px 5px;
  text-align: justify;
  font-family: "Poppins", sans-serif;
}

.read-more {
  color: rgb(164, 164, 238);
  cursor: pointer;
  font-size: 0.6em;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  /* text-decoration: underline; */
  /* margin-top: 4px; */
  /* display: inline-block; */
}

.descriptionDiv {
  /* background-color: #222136; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 3%;
}

@media screen and (max-width: 1000px) {
  .hote_info_sec_parent {
    display: flex;
    flex-direction: column-reverse;
    /* background-color: rgb(77, 29, 29); */
    width: 95vw;
  }

  .parent_hotel_book {
    width: 95vw;
    margin-left: 0;

    /* background-color: red; */
  }
}

@media screen and (max-width: 500px) {
  .parent_hotel_book {
    width: 100vw;
    margin-left: 0;

    /* background-color: red; */
  }
}

@media screen and (max-width: 750px) {
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .monyDiv {
    flex-direction: column-reverse;
    /* background-color: rebeccapurple; */

    width: 50%;
  }

  .roomNamePlc {
    margin-left: 1%;
    /* background-color: red; */
    width: 58%;
  }

  .book_img {
    width: 125px;
    height: 160px;
  }

  .addRoomDiv {
    /* background-color: red; */
    width: 50%;
  }

  .price_span {
    font-size: 1.2em;
  }

  .room_type_price {
    width: 62%;
  }

  .roomCheck {
    height: 40px;
    font-size: 0.69em;
    width: 5rem;
    padding: 0%;
  }

  .Book_now {
    width: 95vw;
    font-size: 1rem;
  }
}
