.menu2 a {
    display: none;
    font-size: 1.5em;
    width: 300px;
    /* height: 30px; */
    text-decoration: none;
    color: white;
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
    /* top: 30px; */
    /* background-color: aquamarine; */

}

.disp {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: 45px;
    width: 78em;
}



.menu2 .Hamburger {
    display: none;
}

@media screen and (max-width: 850px) {
    .menu2 a {
        display: flex;
        position: relative;
        left: 0px;
        font-size: small;
        color: black;
        top: 0;
        z-index: 1;
    }

    .menu2 .Hamburger {
        display: flex;
        position: absolute;
        left: 90%;
        top: 20%;
        cursor: pointer;
        color: white;

    }

    .Hamburger2 {
        color: white;
    }

    .disp {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        border-top: 1px solid #CED82A;
        width: 100%;
        right: 0;

        height: auto;
        background-color: whitesmoke;

    }

    .disp a {
        padding-top: 2px;
        /* position: absolute; */
        /* margin-left: -30%; */

    }

    .disp a li {
        list-style: none;
    }

    .regLogMobile {
        display: flex;
        flex-direction: column;
        /* background-color: red; */
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
    }

    .signout {
        margin-left: 17em;
        margin-top: -10%;
    }

    .acc {
        color: black;
        /* margin-left: 1.8em; */
        /* margin-top: -10%; */
    }


}


@media screen and (max-width: 750px) {
    .menu2 a {
        display: flex;
        position: relative;
        left: 50px;
        font-size: small;
        color: black;
        top: 0;
        z-index: 1;
    }

    .menu2 .Hamburger {
        display: flex;
        position: absolute;
        left: 85%;
        top: 20%;
        cursor: pointer;
        color: white;

    }

    .Hamburger2 {
        color: white;
    }

    .disp {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        border-top: 1px solid #CED82A;
        width: 100%;
        right: 0;

        height: auto;
        background-color: whitesmoke;

    }

    .disp a {
        padding-top: 2px;

        margin-left: -30%;

    }

    .disp a li {
        list-style: none;
    }

    .regLogMobile {
        display: flex;
        flex-direction: column;
        /* background-color: red; */
        align-items: flex-start;

        width: 100%;
        margin-top: 0;
    }

    .register {
        margin-left: -20%;

    }


    .login {
        margin-left: -15%;

    }

    .signout {
        margin-left: 40%;
        margin-top: -10%;

    }

    .regSignIn {
        margin-left: -10%;
        margin-top: -10%;

    }

}