@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
/* *{
    margin:0;
    padding:0;
    text-decoration: none;
} */
.containerDashBoard{
    display:flex;
    margin:0;
    padding:0;
    /* background-color: #000; */
    position: relative;
    text-decoration: none;
}
main{
    width: 100%;
    padding: 20px;
}
.sidebar{
    margin-top: 5%;
    background: #2E2D4D;
    color: #CED82A;
    height: 100vh;
    width: 200px;
    transition: all 0.5s;
    position: absolute;
    z-index: 99;
}
.top_section{
    display: flex;
    align-items:center;
    padding:20px 15px;
}
.logo{
    font-size: 30px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}
.link{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    text-decoration: none;
}
.link:hover{
    background:whitesmoke;
    color: #000;
    transition: all 0.5s;
}
.active{
    background: whitesmoke;
    color: #000;
}
.icon, .link_text{
    font-size: 1em;
}

@media screen and (max-width: 1200px) {
    .containerDashBoard{
        margin-top: 2%;
        padding-top: 3%;
        
        }
}

@media screen and (max-width: 600px) {
    .containerDashBoard{
    margin-top: 5%;
    padding-top: 5%;
    
    }
}

@media screen and (max-width: 450px) {
    .containerDashBoard{
        margin-top: 15%;
        padding-top: 5%;
        
        }
}
