.hotelTC{
margin-top: 10%;
  background-image: url("../../../../assets/Moon\ \(2\).svg");
  background-repeat: no-repeat;
  /* background-position: ; */
  background-size: cover;
  height: 100vh auto;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tandc{
    width: 53%;
    /* margin-left: 23%; */
    padding: 5%;
    color: white;
    background-color: #2E2D4D;
    opacity: 0.8;   
    /* margin-top: 7%; */
    padding-top: 5%;
    border-radius: 10px;
    padding-bottom: 20px;
    margin-bottom: 2%;
}

.tandc p{
    color: white;
}

.tandc h1{
    /* color: rgb(37, 27, 27); */
    color: white;
    font-family: monospace;
    text-transform: uppercase;
    border-bottom: 2px solid white;
    padding-bottom: 20px;
    margin-bottom:5% ;
}

.tandc p{
    text-align: justify;
}


.acceptanceTerms{
    color: #CED82A;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
}

.ulItems{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.9em;
    list-style-type: none;
}

@media screen and (max-width: 800px) {
    .hotelTC{
        margin-top: 10%;
        padding-top: 5%;
        }
        .tandc{
            width: 75%;
            
        }
}