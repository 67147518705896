@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.supportNum {
    /* background-color: black; */
    color: white;
    justify-content: center;
    padding: 5px;
    z-index: 999;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 0.7em;
    /* position: fixed; */
    /* top: 0;
    left: 0;
    right: 0; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* align-items: center; */
    text-align: justify;
    text-transform: capitalize;

}

.mobileSupportNum{
    display: none;
}

@media screen and (max-width: 850px) {
    .supportNum{
        display: none;
    }

    .mobileSupportNum{
    background-color: black;
        color: white;
        justify-content: center;
        padding: 2px;
        z-index: 1000;
        margin: 0;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 0.6em;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: justify;
        text-transform: capitalize;
        
    }

    .mobileSupportNum a{
        text-decoration: none;
        color: white;
        list-style: none;
    }
}