
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.parent_form_add{
    /* background: #2E2D4D; */
    height: 50vh auto;
    /* margin-top: 10%; */
    padding-bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toppart{
    margin-top: 10%;
    /* background-color: rebeccapurple; */
    color: #2E2D4D;
    width: 50%;
    font-size: 0.9em;
    margin-left: 15%;
    text-align: justify;
    margin-bottom: 5%;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.hide{
    display: none;
}
.selectGender2{
    display: block;
}

.sideImg{
    margin-top: 0%;
    /* padding: 10px; */
/* background-color: rebeccapurple; */
    height: 52em;
    width: 35%
  
  
}
.sideImg img{
    height: 100%;
    width: 100%
}

.add_div{
    /* background: #2E2D4D; */
    width: 36%;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border-left: #2E2D4D 2px solid;
   
}

.share{
    display: grid;
    grid-template-columns: repeat(2, minmax(10px, 2fr));
    /* gap:1rem; */
    /* background-color: red; */
    width: 85%;
    /* margin-left: 4%; */
    margin-left: 10%; 
}


.form_input{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;

    
}

.form_input2{
    width: 100%;
    display: flex;
    /* justify-content: flex-end; */
    /* align-items: flex-end; */
}

.form_input3{
    width: 100%;
   display: flex;
   /* justify-content: flex-start; */
   /* align-items: flex-start; */
    
}

.form_input input{
    width: 90%;
    /* margin-left: 19%; */
    border-radius: 5px;
    height: 37px;
    border: 1px solid #2E2D4D;
   
}

.form_input textarea{
    width: 80%;
    margin-left: 10%;
    border-radius: 5px;
    height: 37px;
    border: 1px solid #2E2D4D;
    height: 100px;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
}
.textarea label{
    margin-left: 10%;
}
.form_input select{
    width: 92%;
    /* margin-left: 25%; */
    border-radius: 5px;
    height: 42px;
    border: 1px solid #2E2D4D;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
}

.form_input label{
    /* margin-right: 30%; */
    /* margin-left: 10%; */
    margin-bottom: 4px;
    width: 50%;
    color: rgb(10, 10, 10);
    font-size: 0.8em;
    font-family: sans-serif;
    text-align: justify
}

.form_input .numberRoomLab{

    width: 70%;
}

.addhotel_location{
    display: grid;
    grid-template-columns: repeat(2, minmax(10px, 2fr));
    /* gap:1rem; */
    /* background-color: red; */
    width: 85%;
    margin-left: 10%;
}
.personal_info{
    background-color: #2E2D4D;
    color:  rgb(255, 255, 255);
    text-align: justify;
    padding: 10px;
    font-size: 1em;
    font-family: sans-serif;
    /* display: grid;
    grid-template-columns: repeat(2, minmax(10px, 2fr));
    gap: 1rem; */
}
.first_part_form{}
.add_hotelbtn{
    background: #2E2D4D;
    border-radius: 5px;
    width: 90%;
    color: white;
    font-size: 1em;
    cursor: pointer;
    padding: 10px;
    margin-top: 25px;
    margin-bottom: 20px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}
.icon{
    color: #02B397;
    font-size: 1.3em;
}
.add_div .error{
    color: red;
    font-size: 0.6em;
 font-family: 'Poppins', sans-serif;
 margin-left: 20%;
 text-align: left;
 /* background-color: #2E2D4D; */
}

@media  screen and (max-width: 1600px){
    .sideImg{
        margin-top: 0%;
        /* padding: 10px; */
    background-color: rebeccapurple;
    height: 52em;
        width: 43%
      
    }
    .add_div{
        width: 40%;
    }
}

@media  screen and (max-width: 1000px){
    .sideImg{
        display: none;
    } 
    .add_div{
        width: 70%;
    }
    .toppart{
        margin-top: 10%;
        width: 70%;
        margin-left: 12%;
        padding: 10px;
        text-align: center;
    }
}

@media  screen and (max-width: 750px){
    .toppart{
        margin-top: 14%;
        width: 70%;
        margin-left: 12%;
        padding: 10px;
        text-align: center;
    }
}

@media  screen and (max-width: 600px){
    .sideImg{
        display: none;
    }

    .add_div{
        width: 100%;
    }
    .toppart{
        margin-top: 30%;
        width: 80%;
        margin-left: 5%;
        padding: 10px;
        text-align: center;
    }
    .form_input .numberRoomLab{

        width: 100%;
    }
    .form_input label{
        /* margin-right: 30%; */
        /* margin-left: 14%; */
        margin-bottom: 4px;
        width: 100%;
        
    }
    .form_input input{
        width: 90%;
        /* margin-left: 10%;  */
    }
    .form_input textarea{
        width: 75%;
        /* margin-left: 14%;  */
        height: 30px;
    }
    .form_input select{
        width: 90%;
        /* margin-left: 10%; */
        border-radius: 5px;
        height: 42px;
        border: 1px solid #2E2D4D;
        padding: 10px;
        font-family: 'Poppins', sans-serif;
    }
    .add_hotelbtn{
        font-size: 0.7em;
    }
}