.parent_receipt {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    width: 50%;
    margin-left: 25%;
position: relative;
margin-top: 5%;     

  }
  .first_parent_receipt{
    background-image: url("../../assets/Moon\ \(2\).svg");
    background-repeat: no-repeat;
    /* background-position: ; */
    background-size: cover;
    height: 100vh;
  }

  .receipt_head{
    background: #2E2D4D;
    color: white;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.receipt_head img{
    height: 50%;
    margin-right: 10%;
}

.receipt_head2 h2{
    font-size: xx-large;
}

.receipt_head2 img{
    width: 40%;
    height: 80%;
}

.receipt_body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10%;
    
}

.receipt_body p{
    font-family: monospace;
    font-size: 0.9em;
    font-weight: 100;
}

.footer{
    background: #2E2D4D;
    height: 60%;
    color: white;
    text-transform: uppercase;
    text-align: center;
}

.hotName{
    background: #2E2D4D;
    color: white;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
   
}

.hotName span {
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.hotelplace{
    width: 50%;
    height: 50%;
}

.hotelplace img{
    width: 100%;
    height: 100%;
}



.invoice-box {
        width: 80%;
        
        padding: 3%;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        font-size: 16px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
    }

    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }

    .invoice-box table td {
        padding: 5px;
        vertical-align: top;
    }

    .invoice-box table tr td:nth-child(2) {
        text-align: right;
    }

    .invoice-box table tr.top table td {
        padding-bottom: 20px;
    }

    .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
    }

    .invoice-box table tr.top table td.title2{
        /* background-color: rebeccapurple; */
        width: 50%;

    }

    .invoice-box table tr.information table td {
        padding-bottom: 40px;
    }

    .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
        text-align: center;
    }

    .invoice-box table tr.details td {
        padding-bottom: 20px;
    }

    .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
        text-align: center;
    }

    .invoice-box table tr.item.last td {
        border-bottom: none;
    }

    .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
        color: green;
    }
    .invoice-box table tr.total2 td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
        color: red;
    }
    @media only screen and (max-width: 1650px){
        .parent_receipt {
            margin-top: 8%;
        }
    }

    @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
        }

        .invoice-box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
        }
        .parent_receipt{
            background-color: white;
            margin-top: 35%;
            /* background-color: red; */
            width: 90%;
            margin-left: 5%;
        }
        .hotName span{
            font-size:1em;
            font-family: m;
            padding-left: 5px;
            padding-right: 5px;
        }
        .invoice-box{
            width: 100%;
            background-color: white;
            margin-left: -8%;
        }
        .invoice-box table tr.top table td.title2{
            width: 100%;
            /* font-size: 0.8em; */
        }
        .invoice-box table tr.total td:nth-child(2){
            width: 100%;
            /* background-color: rebeccapurple; */
            font-size: 0.6em;
            color: red;
        }
        .invoice-box table tr.heading td{
            font-size: 0.8em;
            font-family: monospace;
        }
    }

    /** RTL **/
    .invoice-box.rtl {
        direction: rtl;
        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    }

    .invoice-box.rtl table {
        text-align: right;
    }

    .invoice-box.rtl table tr td:nth-child(2) {
        text-align: left;
    }

    @media screen and (max-width: 600px) {
        
    }