.parent_freq{

    height: 50vh auto;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    border-top: 1px solid gray;
    margin-top: 3%;
    border-bottom: 1px solid gray;
    padding-bottom: 3%;
    
}
.freq_header{
 text-transform: capitalize;
    text-align: justify;
    font-size: 2rem;
    font-weight: 200;
}

.question_form{
    border: 1px solid grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28%;
    padding: 10px;
}
.question_form input{
    margin-bottom: 15px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid grey;
    width: 90%;
}

.question_form textarea{
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid grey; 
    width: 90%;
}

.question_form button {
    width: 90%;
    padding: 10px;
    color: white;
    background: #2E2D4D;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.main_question_form{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.main_question_form .FQAImG img{
    height: 100%;
   
}

.freq_ques {
    
    width: 96%;
    height: 10% auto;
    margin-left: 20px;
    margin-bottom: 15px;
    border-radius: 5px;
    text-align: justify;
    padding: 7px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    transition: transform 0.3s ease-in-out;
}
.freq_ques:hover{
    border: 1px solid grey;
    transform: translateY(-5px);
}
.freq_ques h4{
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: -12px;
    color:RGB(35,52,82);
  
}

.freq_ques_parent{
    
    width: 70%;
}

.question_form .error{
    color: red;
    margin-top: -3%;
    font-family: sans-serif;
    font-size: 0.6em;
}    

.question_form textarea::placeholder{
    color: #2E2D4D;
    font-family: 'Times New Roman', Times, serif;
}

.question_form input::placeholder{
    color: #2E2D4D;
    font-family: 'Times New Roman', Times, serif;
}

@media screen and (max-width: 1600px) {
    .question_form{
        width: 45%;
    }
}

@media screen and (max-width: 850px){
    .parent_freq{
        width: 95%;
        margin-left: 5%;
    }
}
@media screen and (max-width: 750px){
    .parent_freq{
        /* background-color: rebeccapurple; */
        width: 95vw;
         margin-left: 0%;
           /* margin-right: 0; */
    }
    .main_question_form{
        display: flex;
        flex-direction: column;
        /* background-color: red; */
        align-items: center;
        justify-content: center;
        padding-left: 0%;
        padding-right: 5%;

    }
    .question_form{
        width: 80%;
        /* padding-left: -2%; */
    }
    .freq_ques{
        margin-left: 0;
        width: 100%;
    }
    .freq_ques_parent{
        margin-top: 5%;
        width: 90vw;
    }    
    .freq_header {
        /* background-color: red; */
        font-size: 1.4rem;
        font-family: 'Times New Roman', Times, serif;
        padding: 8px;
        width: 90vw;
    }
    .freq_ques h4{
        font-size: 0.9rem;
        font-family: m;
        margin-bottom: 1%;
    }
    .freq_ques p{
        font-family: m;
        font-size: 0.8rem;
    }
}   

