 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Source+Sans+Pro:wght@200&display=swap');

/*@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');
.main_parent_hotel_info{
    overflow-x: hidden;
}

.searchBar{
  position: absolute;
  margin-top: 5%;
  height: 6%;
  width: 100%;
  /* top: 13%; */
  margin-bottom: 14%;
  background-color: #FAFAFA;
  z-index: 400;
}
.parent_hotel_name{
    width: 70%;
    margin-top: 14.5%;
    margin-left: 15%;
    /* margin-right: 15%; */
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-flow: column;

    
}
.hotel_name_sec h1{
    font-size: 2rem; 
    text-align: justify;
    width: 100%;
    color: #2E2D4D;
    margin-top: 7%;
   
}

.hotel_name_sec .pAddress{
  /* background-color: aqua; */
  text-align: justify;
  padding: 5px;
}
.hotel_name_sec p{
    margin-top: -1%;
    /* text-align: justify; */
    margin-left: -14px;
    padding-left: 17px;
    width: 100%; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: #2E2D4D; */
    font-family: 'Poppins', sans-serif;

}

.hotel_name_sec .locateIcon{
  /* background-color: aquamarine; */
  /* padding: 5px; */
  font-size: 1.5em;
  color: red;
}

.review_hotel_rate span{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-left: 10%;
    margin-top: 10%;
    
}
.review_hotel_rate span p{
    margin-left: 2%;
    
    text-align: justify;
    color:  #2E2D4D;
}

.review_hotel_rate span p a{
    color: #2E2D4D;
}

.review_hotel_rate span h3{
background-color: #CED82A;
width: 10%;
padding: 10px;
color: white;
border-radius: 7px;
}



.hotel_info_text{
  text-align: justify;
    width: 40vw;
    margin-right: 30px;
   /* background-color: rgb(205, 235, 255); */
   padding: 5px;
}
.hotel_info_text p{
    line-height: 2rem;
  
   font-size: 1rem;
   font-family: 'Source Sans Pro', sans-serif;
   lighting-color: black;
    
}

.hote_info_sec_parent{
    width: 70% ;
    margin-left: 15%;
    /* margin-right: 15%; */
/* margin-top: 5%; */
    display: flex;
    flex-direction: row;
    justify-content: center;  
}

/* .parentdisplayFlex{
    display: flex;
    justify-content: space-evenly;
}
.grid-item2{
    background-color: #363691;
    width: 50%;
} */


.grid-container {
    display: grid;
    grid-template-columns: 3fr 3fr 8fr; 
    grid-gap: 10px; 
  }
  
  .grid-item {
    background-color: #f7f3f3; 
    /* padding: 2px;  */
    display: flex;
  justify-content: center; 
  align-items: center; 
  /* width: 300px; /* Set the width of your container */
  /* height: 200px; Set the height of your container */
  overflow: hidden; 
  position: relative;
  }
  
 
  .grid-item:nth-child(1) {
    background-color: #f7f3f3; 
  }
  
  .grid-item:nth-child(2) {
    background-color: #f7f3f3;
  }
  
  .grid-item:nth-child(3) {
    background-color: #f7f3f3;
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 3;
    grid-column-end: 4;

  }

  .grid-item  .zoom-image{
    width: 100%;
    height: 500px;
    object-fit: cover;
    max-width: 100%; /* Ensure the image does not exceed the container's width */
    max-height: 100%;
  }

  .grid-item  .zoom-image2{
    width: 100%;
    height: 170px;
    object-fit: cover;
    max-width: 100%; /* Ensure the image does not exceed the container's width */
    max-height: 100%;
    /* position: absolute; Position the image absolutely */
  top: 50%;
  left: 50%;
  transform: translate(0%, 0%);
  }

  .zoom-image {
    transition: transform 0.2s;
  }

  .zoom-image:hover {
    transform: scale(1.1); 
    cursor: pointer;
  }

 
  .slide-animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9998;
    pointer-events: none;
  }
  
  .slide-prev,
  .slide-next {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
  }
  
  .slide-prev {
    left: 0;
    right: 50%;
  }
  
  .slide-next {
    right: 0;
    left: 50%;
  }
  
  .slide-animation.slide-prev::after,
  .slide-animation.slide-next::after {
    content: '';
    width: 30px;
    height: 30px;
    border-left: 3px solid #fff;
    border-top: 3px solid #fff;
    position: absolute;
    transform: rotate(45deg);
  }
  
  .slide-prev::after {
    top: 50%;
    left: 20px;
    transform: translate(0, -50%) rotate(-135deg);
  }
  
  .slide-next::after {
    top: 50%;
    right: 20px;
    transform: translate(0, -50%) rotate(45deg);
  }
  
  @keyframes slideOutRight {
    0% {
      transform: translateX(10);
      opacity: 1;
    }
    100% {
      transform: translateX(50%);
      opacity: 0;
    }
  }
  
  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  
  .slide-prev.animate {
    animation: slideOutLeft 0.3s ease-in-out;
  }
  
  .slide-next.animate {
    animation: slideOutRight 0.3s ease-in-out;
  }

  @keyframes fas {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
  .fas{
    animation: bounce 2s ease infinite;
  }
  
  .bounce {
   
    width: 100%;
    padding: 3%;
    margin-top: 1%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    background-color: #2E2D4D;
    opacity: 0.3;
    color: white;
    cursor: pointer;
  }

  @media screen and (max-width: 1620px){
    .searchBar{
      position: absolute;
      margin-top: 6%;
      height: 5.5%;
      width: 100%;
      /* top: 13%; */
    
    }
    
    .parent_hotel_name{
      margin-top: 18%;
      margin-left: 11%;
      /* width: 100%; */
      /* background-color: #2E2D4D; */
    }
    .pAddress{
      /* background-color: #CED82A; */
      width: 90%;
      text-align: justify;
      margin-left: 1%;
    }
  }
 
  
@media screen and (max-width: 1000px){
   .hote_info_sec_parent{
    display: flex;
    flex-direction: column-reverse;
    margin-left: 0;
    margin-right: 0;
    /* background-color: rebeccapurple; */

    width: 100%;
   }
   .hotel_info_text{
    width: 89.5vw;
    padding: 5%;
   }
   .main_parent_hotel_info{
    /* background-color: red; */
    width: 95vw;
    overflow-x: hidden;
}
.review_hotel_rate{
    display: none;
}
.hotel_name_sec{
    width: 100%;
    /* background-color: rebeccapurple; */
}
.searchBar{
  position: absolute;
  margin-top: 10%;
  height: 3.5%;
  width: 100%;
  /* top: 13%; */

}

.parent_hotel_name{
  margin-top: 32.5%;
  margin-left: 1%;
  /* width: 100%; */
}
.pAddress{
  /* background-color: #CED82A; */
  width: 90%;
  text-align: justify;
  margin-left: 1%;
}
   
}

@media screen and (max-width: 850px){
    .hotel_name_sec {
        margin-left: 0%;
        padding-left: 5px;
        padding-right: 5px;
        width: 97vw;
       
        /* background-color: rebeccapurple; */
    }
    .hotel_name_sec h1{
        font-size: 1.2em;
    }
   
    .hotel_name_sec p{
        /* background-color: red; */
        width: 100%; 
        font-size: 0.85em;
        font-family: 'Poppins', sans-serif;
       
    }
}
@media screen and (max-width: 750px){
    .parent_hotel_name{
        width: 95vw;
        /* background-color: rebeccapurple; */
        margin-left: 0;
        margin-top: 10%;
    }
    .parent_hotel_name p{
        /* background-color: red; */
        width: 97vw;
        font-family: 'Poppins', sans-serif;
        font-size: 0.6em;
    }
    .review_hotel_rate span{
        /* background-color: rebeccapurple; */
        margin-top: 50%;
        width: 100%;
        margin-left: -10%;
    }
    .hotel_name_sec{
        margin-top: 17%;
    }
    .review_hotel_rate span h3{
        width: 10%;
        padding: 10px;
        font-size: 0.6em;
    }
    .review_hotel_rate span p{
        margin-left: 2%;
        /* background-color: red; */
        text-align: justify;
        color:  #2E2D4D;
        font-size: 0.6em;
    }

    .searchBar{
      position: absolute;
      margin-top: 28%;
      height: 1%;
      width: 100%;
      /* top: 13%; */
    
    }
    
    .parent_hotel_name{
      margin-top: 20%;
      margin-left: 1%;
      /* width: 100%; */
    }
    .pAddress{
      /* background-color: #CED82A; */
      width: 90%;
      text-align: justify;
      margin-left: 1%;
    }

    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr; 
      grid-gap: 10px; 
    }

    .grid-item:nth-child(3) {
      background-color: #f7f3f3;
      grid-row-start: 4;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 3;
  
    }

    .grid-item  .zoom-image{
      width: 100%;
      height: 300px;
      object-fit: cover;
      max-width: 100%; 
      max-height: 100%;
    }

    .grid-item  .zoom-image2{
      width: 100%;
      height: 170px;
      object-fit: cover;
      max-width: 100%; 
      max-height: 100%;
      /* position: absolute; Position the image absolutely */
    }

}
@media screen and (max-width: 500px) {
    .main_parent_hotel_info{
        /* background-color: red; */
        width: 100vw;
        overflow-x: hidden;
    }
    .hote_info_sec_parent{
       
    }

    .searchBar{
      position: absolute;
      margin-top: 36%;
      height: 1%;
      width: 100%;
      /* top: 13%; */
    
    }
    
    .parent_hotel_name{
      margin-top: 42%;
      margin-left: 1%;
      /* width: 100%; */
    }
    
    .pAddress{
      /* background-color: #CED82A; */
      width: 90%;
      text-align: justify;
      margin-left: 1%;
    }

    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr; 
      grid-gap: 10px; 
    }

    .grid-item:nth-child(3) {
      background-color: #f7f3f3;
      grid-row-start: 4;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 3;
  
    }

    .grid-item  .zoom-image{
      width: 100%;
      height: 300px;
      object-fit: cover;
      max-width: 100%; 
      max-height: 100%;
    }

    .grid-item  .zoom-image2{
      width: 100%;
      height: 170px;
      object-fit: cover;
      max-width: 100%; 
      max-height: 100%;
      /* position: absolute; Position the image absolutely */
    }
}

