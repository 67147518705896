@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
.newsLetter-main{
    /* background: #000000; */
    background:  RGB(250,250,250);
    width: 100vw;
    height: 200px;
    /* margin-top: 20px; */
    position: relative;
    
    
}

.newLetter_card{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}
.subscribe::placeholder{
    color: #2E2D4D;
    font-family: 'Times New Roman', Times, serif;
}   

.newLetter_card h2{
    font-size: 1.5rem;
    color: #2E2D4D;
    
    font-family: 'Times New Roman', Times, serif;
}

.newLetter_card .email{
    height: 50px;
    position: relative;
}
    
.newLetter_card .email input{
    height: 100%;
    width: 450px;
    padding-left: 20px;
    font-size: 18px;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 50px;
    border: 2px solid whitesmoke;
    background: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.newLetter_card .email .icon{
  
    
    transform: translate(-50%, -50%);
    font-size: 25px;
}

.newLetter_card .email button{
    position: absolute;
    top: 63%;
    right: -30px;
    transform: translate(-50%, -50%);
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 50px;
    border: none;
    outline: none;
    background: #2E2D4D;
    color: white;
    transition: 0.3s;
}

.newLetter_card .email button:hover{
    background: black;
}
.newLetter_card .error{
    color: red;
  font-size: 0.8em;
  margin-left: -10%;
  font-family: sans-serif;
  margin-top: -8%;
  margin-bottom: 0%;
}
.noticeMSG{
    font-family: 'Poppins', sans-serif;
    font-size: 0.8em;
    color: #2E2D4D;
}

@media  screen and (max-width: 1000px){
    .newLetter_card .email input{
        width: 100%;
        margin-left: 0;
            
    }
    .newLetter_card .email button{
        right: -30%;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
}

@media screen and (max-width: 600px) {
    .newsLetter-main{
        /* background-color: rebeccapurple; */
        width: 100vw;
    }
    .newLetter_card .email input{
        width: 90%;
        height: 70%;
        margin-left: 0;
    }
    .newLetter_card .email button{
        right: -20%;
        top: 40%;
        width: 25%;
        font-size: 0.6em;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
}
